import React from 'react'
import css from './GuildPromotionFloatinCta.module.css'
import { LinkButton } from '@/atoms/Button'
import { GuildIcon } from '@/atoms/Icons/GuildIcon'
import { Image } from '@/atoms/Image'
import { AsLabel, TitleXS } from '@/atoms/Text'
import { ProjectMeta } from '@/services/ProjectsService'
import { useSafeAnalytics } from '@/utils/analytics'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'
import { ChooseCtaInput, chooseTheater } from '../ctas/rules'

interface GuildPromotionFloatingCtaProps extends ChooseCtaInput {
  project: ProjectMeta
  isGuildMember: boolean
}

/**
 * Runs on client. Depends on TheatricalRegionContext for user region.
 *
 * Usage on the TheatricalWatchView (last used for The Shift):
 *    <GuildPromotionFloatingCta
 *      userRegionCode={userCountry}
 *      project={projectMeta}
 *      filmDetails={filmDetails as FilmDetailsType}
 *      allTheatricalReleaseRegions={allTheatricalReleaseRegions}
 *      experimentOn={experimentOn}
 *      hasEarlyAccess={hasEarlyAccess}
 *    />
 */
export function GuildPromotionFloatingCta(props: GuildPromotionFloatingCtaProps) {
  const { t } = useTranslate('theatrical-presales')
  const { track } = useSafeAnalytics()

  const handleClick = React.useCallback(() => {
    track('On Tap', {
      element_name: 'cta_guild_join_floating',
      value: props.project,
    })
  }, [track, props.project])

  if (!isTicketingProject(props)) return null

  const childProps = formatCtaProps(t, props.project, props.isGuildMember)
  return (
    <FloatingCallToAction
      buttonHref={childProps.buttonHref}
      buttonLabel={childProps.buttonLabel}
      image={childProps.image}
      onClick={handleClick}
    />
  )
}

function isTicketingProject(input: ChooseCtaInput): boolean {
  return !!chooseTheater(input)
}

interface FloatingCallToActionProps {
  buttonHref: string
  buttonLabel: string
  image: {
    alt: string
    src: string
    width: number
    height: number
  }
  onClick: () => void
}

function FloatingCallToAction(props: FloatingCallToActionProps) {
  const { t } = useTranslate('theatrical-presales')

  return (
    <aside
      className={`animate-slideup md:top-[calc(65px + 2.5rem)] absolute right-10 top-[65px] z-40 flex translate-y-12 items-center gap-6 rounded-xl bg-[rgba(0,0,0,0.2)] px-6 py-4 opacity-0 delay-100 duration-500 ${css.slideIn}`}
    >
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <Image className="hidden md:inline-block" {...props.image} />
      <div className="flex flex-col gap-4 text-center">
        <TitleXS as={AsLabel} className="md:photon-title-md" color="white" weight="bold">
          {t('getTwoMovieTickets', 'Get 2 Movie Tickets')}
        </TitleXS>
        <LinkButton
          className="flex items-center justify-center gap-2"
          href={props.buttonHref}
          onClick={props.onClick}
          variant="white"
        >
          <GuildIcon color1="current" color2="current" />
          <span className="whitespace-nowrap">{props.buttonLabel}</span>
        </LinkButton>
      </div>
    </aside>
  )
}

function formatCtaProps(
  t: TranslateFunction,
  project: ProjectMeta,
  isGuildMember: boolean,
): Pick<FloatingCallToActionProps, 'buttonHref' | 'buttonLabel' | 'image'> {
  return {
    image: {
      alt: project.name ?? '',
      src: project.logoCloudinaryPath,
      // TODO: make height-only constraint, rely on aspect ratio
      width: 142,
      height: 61,
    },
    ...(isGuildMember
      ? {
          buttonHref: `/guild/tickets/claim/${project.slug}`,
          buttonLabel: t('guildPromotionClaimTickets', 'Claim Your Tickets'),
        }
      : {
          buttonHref: `/guild/join/${project.slug}`,
          buttonLabel: t('guildPromotionJoinGuild', 'Join The Angel Guild'),
        }),
  }
}
