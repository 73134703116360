import React from 'react'
import { When } from 'react-if'
import { AsQ, ParagraphSM, TitleXS } from '@/atoms/Text'
import { HubpageSingleItemSlider } from '@/molecules/HubpageSingleItemSlider'
import { MovieRating } from '@/molecules/MovieRating'
import { MovieRatingsContainer } from '@/organisms/MovieRatingsContainer'
import { useTranslate } from '@/utils/translate/translate-client'
import { useWatchTheatricalContext } from '../WatchTheatricalContext'

export const Reviews: React.FC = () => {
  const { t } = useTranslate('watch')
  const { rottenTomatoes, audience, cinema, critics } = useWatchTheatricalContext()
  const rottenTomatoesRating = rottenTomatoes?.items[0]
  const audienceRating = audience?.items[0]
  const cinemaRating = cinema?.items[0]

  return (
    <div className="grid bg-black text-white md:grid-cols-[250px_1fr]">
      <HubpageSingleItemSlider
        title={t('movieReviews', 'Movie Reviews')}
        className="grid"
        sliderStyles="overflow-hidden w-full mx-auto"
        id="review-section-title"
      >
        {critics?.items?.map((review, index: number) => (
          <div
            key={index}
            className="my-2 !flex min-h-[120px] max-w-full place-content-center items-center rounded-lg !bg-core-gray-950 text-center md:min-h-[300px]"
          >
            <article className="px-8 py-4">
              <TitleXS
                as={AsQ}
                className="md:photon-title-sm lg:photon-heading-xs my-2 text-pretty"
                weight="bold"
              >{`${review?.quote}`}</TitleXS>
              <ParagraphSM>
                {review?.author}
                {review?.company && `- ${review.company}`}
              </ParagraphSM>
            </article>
          </div>
        ))}
      </HubpageSingleItemSlider>
      <MovieRatingsContainer>
        <When condition={!!rottenTomatoesRating}>
          <MovieRating
            icon={rottenTomatoesRating?.icon?.[0]}
            rating={rottenTomatoesRating?.rating}
            author={rottenTomatoesRating?.author}
          />
        </When>
        <When condition={!!audienceRating}>
          <MovieRating
            icon={audienceRating?.icon?.[0]}
            rating={audienceRating?.rating}
            author={audienceRating?.author}
          />
        </When>
        <When condition={!!cinemaRating}>
          <MovieRating icon={cinemaRating?.icon?.[0]} rating={cinemaRating?.rating} author={cinemaRating?.author} />
        </When>
      </MovieRatingsContainer>
    </div>
  )
}
