import React from 'react'
import { AsH4, ParagraphSM, TitleMD } from '@/atoms/Text'

interface DescriptorSectionProps {
  title: string
  description?: string | number
  synopsisJson?: string | null
  isNumber?: boolean
}

export const DescriptorSection: React.FC<DescriptorSectionProps> = ({ title, description, isNumber = false }) => {
  return (
    <div className="text-white">
      <TitleMD as={AsH4} color="white" weight="semibold" className="mb-2 uppercase">
        {title}
      </TitleMD>
      <ParagraphSM color="core-gray-200">{isNumber ? description?.toLocaleString() : description}</ParagraphSM>
    </div>
  )
}
