import React from 'react'
import classNames from 'classnames'
import { Maybe } from 'graphql/jsutils/Maybe'
import { LinkButton } from '@/atoms/Button'
import { AsH3, CaptionXS, TitleMD } from '@/atoms/Text'
import { ProjectTheme } from '@/types/codegen-contentful'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'

interface PressLinksSectionProps {
  projectName: Maybe<string>
  projectSlug: Maybe<string>
  contentfulProjectTheme: Maybe<ProjectTheme>
}

function formatAffiliateKitUrl(props: PressLinksSectionProps) {
  return props.contentfulProjectTheme?.affiliateKitUrl
}

function formatExhibitorKitUrl(props: PressLinksSectionProps) {
  return props.contentfulProjectTheme?.exhibitorKitUrl
}

function formatPartnershipKitUrl(props: PressLinksSectionProps) {
  return props.contentfulProjectTheme?.partnershipKitUrl
}

// TODO: if experiment sticks, move this content to Contentful
const links = [
  {
    key: 'partnership-kit',
    title(t: TranslateFunction) {
      return t('partnershipKit', 'Partnership Kit')
    },
    description(t: TranslateFunction, props: PressLinksSectionProps) {
      return t(
        'partnershipKitDescriptionWithName',
        'Our Partnership kit has resources designed to provide social influencers all the necessary materials to help promote {{title}}.',
        { title: props.projectName },
      )
    },
    href: formatPartnershipKitUrl,
    link(t: TranslateFunction) {
      return t('viewPartnershipKit', 'View Partnership Kit')
    },
  },
  {
    key: 'press-kit',
    title(t: TranslateFunction) {
      return t('pressKit', 'Press Kit')
    },
    description(t: TranslateFunction, props: PressLinksSectionProps) {
      return t(
        'pressKitDescriptionWithName',
        'Our press kit has resources designed to provide journalists and media outlets with all the necessary materials for {{title}}.',
        { title: props.projectName },
      )
    },
    href(props: PressLinksSectionProps) {
      return `/press/${props.projectSlug}`
    },
    link(t: TranslateFunction) {
      return t('viewPressKit', 'View Press Kit')
    },
  },
  {
    key: 'exhibitor-kit',
    title(t: TranslateFunction) {
      return t('exhibitorKit', 'Exhibitor Kit')
    },
    description(t: TranslateFunction, props: PressLinksSectionProps) {
      return t(
        'exhibitorKitDescriptionWithName',
        'Our exhibitor kit has resources designed to provide theaters all the necessary materials to help promote {{title}}.',
        { title: props.projectName },
      )
    },
    href: formatExhibitorKitUrl,
    link(t: TranslateFunction) {
      return t('viewExhibitKit', "View Exhibitor's Kit")
    },
  },
  {
    key: 'affiliate-kit',
    title(t: TranslateFunction) {
      return t('affiliateKit', 'Affiliate Kit')
    },
    description(t: TranslateFunction, props: PressLinksSectionProps) {
      return t(
        'affiliateKitDescriptionWithName',
        'Our Affiliate kit has resources designed to provide social influencers all the necessary materials to help promote {{title}}.',
        { title: props.projectName },
      )
    },
    href: formatAffiliateKitUrl,
    link(t: TranslateFunction) {
      return t('viewAffiliateKit', 'View Affiliate Kit')
    },
  },
]

export function PressLinksSection(props: PressLinksSectionProps): React.ReactElement {
  const { t } = useTranslate('theatrical-presales')

  return (
    <div className="mx-auto grid max-w-[1420px] justify-center text-white md:flex">
      {links.map((link, i) => {
        const href = link.href(props)
        return (
          href && (
            <div
              key={link.key}
              className={classNames(
                i % 2 === 0 ? 'bg-core-gray-950' : 'bg-core-gray-900',
                'flex text-center items-center gap-y-1 flex-col justify-between px-10 py-16 lg:px-20',
              )}
            >
              <TitleMD as={AsH3} weight="bold" className="mb-1 text-center">
                {link.title(t)}
              </TitleMD>
              <CaptionXS className="mb-3 text-base lg:max-w-[350px]" color="core-gray-300">
                {link.description(t, props)}
              </CaptionXS>
              <LinkButton variant="plain" href={href} className="whitespace-nowrap" external={/^http/.test(href)}>
                {link.link(t)}
              </LinkButton>
            </div>
          )
        )
      })}
    </div>
  )
}
