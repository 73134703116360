import React from 'react'
import { useTranslate } from '@/utils/translate/translate-client'
import { scaleDimensions, getHexColor } from '../utils'
import { OneColorLogoProps } from './LogoTypes'

export const AppleStoreLogo: React.FC<OneColorLogoProps> = ({ height = 32, color = 'white', className }) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(height, 74, 24)
  const hex = getHexColor(color)
  const { t } = useTranslate('common')

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={scaledWidth}
      height={scaledHeight}
      viewBox="0 0 75 25"
      fill="none"
      aria-label={t('downloadOnAppleAppStore', 'Download on Apple App Store')}
    >
      <g clipPath="url(#clip0_807_2574)">
        <path
          d="M68.4097 -0.0155443H5.98843C5.7609 -0.0155443 5.53609 -0.0155443 5.30916 -0.0143034C5.1192 -0.0130624 4.93075 -0.00945734 4.73896 -0.00642315C4.32231 -0.00152016 3.90664 0.0351349 3.49557 0.103223C3.08507 0.172794 2.68742 0.303955 2.31609 0.49227C1.94521 0.682178 1.60633 0.928944 1.31174 1.22362C1.0156 1.51746 0.768742 1.8571 0.580663 2.22948C0.392066 2.60113 0.261292 2.99937 0.192858 3.41047C0.123773 3.82105 0.0865979 4.23637 0.081666 4.65269C0.0759079 4.84293 0.0752998 5.03381 0.0722656 5.22408V19.5662C0.0752998 19.7589 0.0759079 19.9455 0.081666 20.1383C0.0865994 20.5546 0.123775 20.9698 0.192858 21.3804C0.261103 21.7917 0.391885 22.1902 0.580663 22.562C0.768656 22.9332 1.01555 23.2715 1.31174 23.5637C1.60521 23.8596 1.94431 24.1066 2.31609 24.295C2.68742 24.4838 3.08502 24.6158 3.49557 24.6864C3.90671 24.754 4.32234 24.7906 4.73896 24.7961C4.93075 24.8004 5.1192 24.8028 5.30916 24.8028C5.53609 24.804 5.76091 24.804 5.98843 24.804H68.4097C68.6327 24.804 68.8593 24.804 69.0823 24.8028C69.2714 24.8028 69.4653 24.8004 69.6543 24.7961C70.0702 24.7909 70.485 24.7543 70.8953 24.6864C71.3072 24.6153 71.7062 24.4834 72.0793 24.295C72.4508 24.1065 72.7896 23.8595 73.0828 23.5637C73.3782 23.2703 73.6256 22.9323 73.8159 22.562C74.0033 22.19 74.1328 21.7915 74.2001 21.3804C74.2693 20.9698 74.3078 20.5546 74.3152 20.1383C74.3177 19.9455 74.3177 19.7589 74.3177 19.5662C74.3225 19.3408 74.3225 19.1166 74.3225 18.8875V5.90152C74.3225 5.67429 74.3225 5.44888 74.3177 5.22408C74.3177 5.03381 74.3177 4.84293 74.3152 4.65267C74.3078 4.23631 74.2693 3.82109 74.2001 3.41045C74.1327 2.99959 74.0031 2.60137 73.8159 2.22946C73.4329 1.48306 72.8256 0.875494 72.0793 0.492214C71.7062 0.30436 71.3071 0.173234 70.8953 0.103168C70.4851 0.0347791 70.0702 -0.00188876 69.6543 -0.00651002C69.4653 -0.009538 69.2714 -0.0131741 69.0823 -0.014384C68.8593 -0.015625 68.6327 -0.0155443 68.4097 -0.0155443Z"
          fill="#A6A6A6"
        />
        <path
          d="M5.31151 24.2571C5.12246 24.2571 4.93798 24.2547 4.75041 24.2505C4.36185 24.2454 3.9742 24.2116 3.59063 24.1493C3.23297 24.0877 2.8865 23.9731 2.56264 23.8093C2.24175 23.6469 1.94907 23.4339 1.69582 23.1785C1.4389 22.9262 1.225 22.6335 1.0626 22.312C0.898414 21.9885 0.784786 21.6417 0.725677 21.2838C0.661843 20.8991 0.627307 20.5102 0.622365 20.1203C0.618431 19.9895 0.613281 19.5538 0.613281 19.5538V5.22013C0.613281 5.22013 0.618766 4.79112 0.622396 4.66508C0.627128 4.27583 0.661464 3.88751 0.725106 3.50348C0.784325 3.14454 0.89804 2.79675 1.06232 2.47216C1.22412 2.15095 1.43683 1.85802 1.6922 1.60475C1.94729 1.34907 2.2409 1.13499 2.56234 0.97032C2.88545 0.807059 3.23127 0.693286 3.5882 0.632811C3.97303 0.569873 4.36203 0.535845 4.75194 0.531014L5.31182 0.523438H69.0786L69.6451 0.531318C70.0315 0.535909 70.417 0.569633 70.7983 0.632203C71.1588 0.693436 71.5082 0.808003 71.835 0.972138C72.479 1.304 73.003 1.82905 73.3335 2.47368C73.4952 2.79603 73.6071 3.14097 73.6656 3.49681C73.73 3.884 73.7661 4.27537 73.7735 4.66781C73.7753 4.84353 73.7753 5.03228 73.7753 5.22013C73.7802 5.45281 73.7802 5.67428 73.7802 5.89757V18.8836C73.7802 19.109 73.7802 19.329 73.7753 19.5507C73.7753 19.7525 73.7753 19.9374 73.7728 20.1276C73.7656 20.513 73.7302 20.8974 73.6668 21.2777C73.6089 21.6382 73.4959 21.9877 73.3317 22.3139C73.1682 22.6318 72.9555 22.922 72.7016 23.1737C72.4481 23.4305 72.1549 23.6447 71.8333 23.8082C71.5073 23.9732 71.1584 24.0882 70.7983 24.1493C70.4147 24.2119 70.027 24.2458 69.6385 24.2505C69.4567 24.2547 69.2664 24.2571 69.0816 24.2571L68.409 24.2584L5.31151 24.2571Z"
          fill="black"
        />
        <path
          d="M15.4413 12.5669C15.448 12.049 15.5856 11.5412 15.8412 11.0907C16.0969 10.6403 16.4623 10.2618 16.9036 9.99057C16.6233 9.59025 16.2535 9.26081 15.8236 9.02841C15.3937 8.796 14.9155 8.66703 14.4271 8.65175C13.3851 8.54239 12.375 9.27524 11.8441 9.27524C11.3028 9.27524 10.4853 8.66261 9.60502 8.68072C9.03561 8.69912 8.48068 8.8647 7.99429 9.16133C7.5079 9.45796 7.10665 9.87552 6.82962 10.3733C5.62959 12.451 6.5247 15.5045 7.67423 17.1839C8.24936 18.0062 8.92152 18.9248 9.80103 18.8923C10.6617 18.8566 10.9831 18.3435 12.022 18.3435C13.0513 18.3435 13.3528 18.8923 14.2502 18.8716C15.1737 18.8566 15.7556 18.0455 16.3105 17.2154C16.7238 16.6294 17.0417 15.9818 17.2527 15.2965C16.7162 15.0696 16.2583 14.6897 15.9362 14.2043C15.6141 13.7189 15.442 13.1494 15.4413 12.5669Z"
          fill={hex}
        />
        <path
          d="M13.7469 7.5487C14.2504 6.94422 14.4985 6.16725 14.4384 5.38281C13.6691 5.46361 12.9585 5.83129 12.4482 6.41258C12.1986 6.69656 12.0075 7.02693 11.8858 7.38481C11.764 7.74269 11.7139 8.12106 11.7385 8.49829C12.1233 8.50225 12.504 8.41885 12.8518 8.25436C13.1997 8.08988 13.5058 7.8486 13.7469 7.5487Z"
          fill={hex}
        />
        <path
          d="M26.3199 16.8296H23.3829L22.6776 18.9123H21.4336L24.2155 11.207H25.508L28.2899 18.9123H27.0247L26.3199 16.8296ZM23.6871 15.8686H26.0152L24.8675 12.4886H24.8354L23.6871 15.8686Z"
          fill={hex}
        />
        <path
          d="M34.2976 16.0995C34.2976 17.8452 33.3632 18.9668 31.9532 18.9668C31.596 18.9855 31.2408 18.9032 30.9282 18.7294C30.6156 18.5556 30.3583 18.2972 30.1857 17.9839H30.159V20.7664H29.0059V13.2903H30.122V14.2247H30.1432C30.3238 13.9129 30.5855 13.6559 30.9004 13.481C31.2154 13.3061 31.5719 13.2199 31.932 13.2315C33.3578 13.2315 34.2976 14.3586 34.2976 16.0995ZM33.1124 16.0995C33.1124 14.9621 32.5246 14.2144 31.6278 14.2144C30.7468 14.2144 30.1541 14.9778 30.1541 16.0995C30.1541 17.2314 30.7468 17.9894 31.6278 17.9894C32.5246 17.9894 33.1124 17.2471 33.1124 16.0995Z"
          fill={hex}
        />
        <path
          d="M40.4812 16.0994C40.4812 17.8452 39.5468 18.9668 38.1368 18.9668C37.7796 18.9855 37.4244 18.9032 37.1118 18.7294C36.7992 18.5555 36.5419 18.2972 36.3693 17.9839H36.3426V20.7664H35.1895V13.2903H36.3056V14.2246H36.3268C36.5073 13.9129 36.769 13.6559 37.084 13.481C37.399 13.3061 37.7555 13.2199 38.1156 13.2315C39.5414 13.2315 40.4812 14.3586 40.4812 16.0994ZM39.296 16.0994C39.296 14.9621 38.7082 14.2143 37.8114 14.2143C36.9304 14.2143 36.3377 14.9778 36.3377 16.0994C36.3377 17.2314 36.9304 17.9894 37.8114 17.9894C38.7082 17.9894 39.296 17.2471 39.296 16.0994Z"
          fill={hex}
        />
        <path
          d="M44.5671 16.7611C44.6526 17.5252 45.3949 18.0269 46.4092 18.0269C47.3811 18.0269 48.0804 17.5251 48.0804 16.8362C48.0804 16.2381 47.6587 15.88 46.6601 15.6346L45.6615 15.394C44.2466 15.0523 43.5897 14.3906 43.5897 13.3168C43.5897 11.9874 44.7483 11.0742 46.3934 11.0742C48.0216 11.0742 49.1378 11.9874 49.1753 13.3168H48.0113C47.9416 12.5479 47.306 12.0837 46.3771 12.0837C45.4482 12.0837 44.8125 12.5533 44.8125 13.2368C44.8125 13.7816 45.2185 14.1021 46.2116 14.3475L47.0606 14.556C48.6415 14.9298 49.2983 15.5649 49.2983 16.6919C49.2983 18.1335 48.1501 19.0363 46.3237 19.0363C44.615 19.0363 43.4613 18.1547 43.3867 16.761L44.5671 16.7611Z"
          fill={hex}
        />
        <path
          d="M51.7879 11.9648V13.2943H52.8562V14.2075H51.7879V17.3044C51.7879 17.7856 52.0018 18.0098 52.4714 18.0098C52.5982 18.0076 52.7248 17.9986 52.8507 17.9831V18.8908C52.6396 18.9302 52.425 18.9481 52.2102 18.9441C51.0729 18.9441 50.6293 18.5169 50.6293 17.4274V14.2075H49.8125V13.2943H50.6293V11.9648H51.7879Z"
          fill={hex}
        />
        <path
          d="M53.4746 16.1009C53.4746 14.3334 54.5156 13.2227 56.139 13.2227C57.7677 13.2227 58.8039 14.3333 58.8039 16.1009C58.8039 17.8733 57.7732 18.9791 56.139 18.9791C54.5053 18.9791 53.4746 17.8733 53.4746 16.1009ZM57.629 16.1009C57.629 14.8884 57.0733 14.1728 56.139 14.1728C55.2046 14.1728 54.6495 14.8939 54.6495 16.1009C54.6495 17.3182 55.2046 18.0284 56.139 18.0284C57.0733 18.0284 57.629 17.3182 57.629 16.1009Z"
          fill={hex}
        />
        <path
          d="M59.7539 13.2902H60.8537V14.2464H60.8804C60.9548 13.9478 61.1297 13.6839 61.3758 13.499C61.6219 13.3142 61.9241 13.2197 62.2316 13.2314C62.3645 13.231 62.4971 13.2454 62.6267 13.2745V14.3531C62.459 14.3018 62.284 14.2783 62.1086 14.2834C61.9411 14.2766 61.7741 14.3061 61.6191 14.37C61.464 14.4338 61.3247 14.5304 61.2105 14.6532C61.0964 14.776 61.0101 14.922 60.9577 15.0813C60.9054 15.2406 60.8881 15.4093 60.907 15.5759V18.908H59.7539L59.7539 13.2902Z"
          fill={hex}
        />
        <path
          d="M67.9425 17.2595C67.7874 18.2793 66.7943 18.9791 65.5236 18.9791C63.8894 18.9791 62.875 17.8842 62.875 16.1276C62.875 14.3655 63.8948 13.2227 65.4751 13.2227C67.0294 13.2227 68.0068 14.2903 68.0068 15.9936V16.3887H64.039V16.4584C64.0207 16.6652 64.0466 16.8734 64.115 17.0694C64.1833 17.2654 64.2926 17.4446 64.4355 17.5951C64.5785 17.7456 64.7518 17.8639 64.944 17.9423C65.1362 18.0207 65.3428 18.0573 65.5503 18.0496C65.8228 18.0752 66.0962 18.012 66.33 17.8697C66.5637 17.7273 66.7452 17.5133 66.8476 17.2595L67.9425 17.2595ZM64.0445 15.5828H66.853C66.8634 15.3969 66.8352 15.2109 66.7703 15.0364C66.7053 14.8619 66.605 14.7027 66.4756 14.5689C66.3462 14.435 66.1906 14.3293 66.0184 14.2584C65.8462 14.1875 65.6613 14.153 65.4751 14.157C65.2873 14.1559 65.1012 14.192 64.9274 14.2632C64.7537 14.3344 64.5957 14.4393 64.4627 14.5718C64.3297 14.7044 64.2243 14.862 64.1525 15.0355C64.0807 15.209 64.044 15.395 64.0445 15.5828Z"
          fill={hex}
        />
        <path
          d="M23.5424 5.40264C23.7842 5.38529 24.0267 5.42181 24.2527 5.50957C24.4786 5.59733 24.6822 5.73413 24.8489 5.91012C25.0155 6.08611 25.1411 6.29688 25.2164 6.52725C25.2917 6.75762 25.315 7.00184 25.2845 7.24229C25.2845 8.4251 24.6452 9.105 23.5424 9.105H22.2051V5.40264H23.5424ZM22.7801 8.5814H23.4782C23.6509 8.59173 23.8238 8.56341 23.9842 8.49849C24.1446 8.43358 24.2886 8.33372 24.4055 8.20616C24.5225 8.07861 24.6095 7.92659 24.6603 7.76115C24.7111 7.59572 24.7244 7.42104 24.6991 7.24983C24.7225 7.0793 24.7079 6.90571 24.6564 6.74149C24.6048 6.57727 24.5175 6.42649 24.4008 6.29995C24.2841 6.17341 24.1409 6.07425 23.9814 6.00958C23.8219 5.9449 23.65 5.9163 23.4782 5.92584H22.7801V8.5814Z"
          fill={hex}
        />
        <path
          d="M25.934 7.70751C25.9164 7.5239 25.9374 7.33864 25.9956 7.16362C26.0539 6.9886 26.148 6.82768 26.2721 6.69118C26.3961 6.55468 26.5474 6.44562 26.716 6.37099C26.8847 6.29636 27.0671 6.25781 27.2516 6.25781C27.436 6.25781 27.6185 6.29636 27.7871 6.37099C27.9558 6.44562 28.107 6.55468 28.2311 6.69118C28.3552 6.82768 28.4493 6.9886 28.5076 7.16362C28.5658 7.33864 28.5868 7.5239 28.5692 7.70751C28.5871 7.89131 28.5664 8.07683 28.5083 8.25213C28.4502 8.42743 28.3561 8.58865 28.232 8.72541C28.1079 8.86218 27.9566 8.97147 27.7877 9.04626C27.6189 9.12105 27.4363 9.15968 27.2516 9.15968C27.0669 9.15968 26.8843 9.12105 26.7154 9.04626C26.5466 8.97147 26.3953 8.86218 26.2712 8.72541C26.1471 8.58865 26.053 8.42743 25.9949 8.25213C25.9368 8.07683 25.9161 7.89131 25.934 7.70751ZM28.0021 7.70751C28.0021 7.10187 27.73 6.74769 27.2525 6.74769C26.7732 6.74769 26.5036 7.10187 26.5036 7.70752C26.5036 8.31801 26.7732 8.66945 27.2525 8.66945C27.73 8.66945 28.0021 8.31558 28.0021 7.70751Z"
          fill={hex}
        />
        <path
          d="M32.0717 9.10652H31.4997L30.9222 7.04872H30.8786L30.3035 9.10652H29.737L28.9668 6.3125H29.5261L30.0266 8.4445H30.0678L30.6422 6.3125H31.1712L31.7457 8.4445H31.7893L32.2874 6.3125H32.8388L32.0717 9.10652Z"
          fill={hex}
        />
        <path
          d="M33.4883 6.30668H34.0191V6.75054H34.0603C34.1302 6.59112 34.2481 6.45749 34.3976 6.36826C34.547 6.27903 34.7206 6.23865 34.8941 6.25275C35.03 6.24253 35.1665 6.26303 35.2935 6.31273C35.4204 6.36242 35.5346 6.44005 35.6275 6.53985C35.7203 6.63965 35.7896 6.75907 35.83 6.88927C35.8705 7.01946 35.8811 7.15708 35.8612 7.29195V9.10067H35.3098V7.43041C35.3098 6.98141 35.1146 6.75811 34.7068 6.75811C34.6145 6.75382 34.5224 6.76952 34.4367 6.80417C34.3511 6.83881 34.2739 6.89156 34.2105 6.95882C34.1472 7.02607 34.0991 7.10623 34.0696 7.1938C34.0401 7.28137 34.0299 7.37428 34.0397 7.46616V9.1007H33.4883L33.4883 6.30668Z"
          fill={hex}
        />
        <path d="M36.7383 5.21875H37.2897V9.10347H36.7383V5.21875Z" fill={hex} />
        <path
          d="M38.057 7.70756C38.0394 7.52394 38.0605 7.33867 38.1187 7.16364C38.1769 6.98861 38.2711 6.82769 38.3952 6.69119C38.5193 6.55469 38.6705 6.44563 38.8392 6.37099C39.0079 6.29636 39.1903 6.25781 39.3748 6.25781C39.5592 6.25781 39.7417 6.29636 39.9104 6.37099C40.0791 6.44563 40.2303 6.55469 40.3544 6.69119C40.4784 6.82769 40.5726 6.98861 40.6309 7.16364C40.6891 7.33867 40.7101 7.52394 40.6926 7.70756C40.7104 7.89137 40.6897 8.07689 40.6316 8.25219C40.5735 8.42749 40.4794 8.58871 40.3553 8.72547C40.2311 8.86223 40.0798 8.97151 39.9109 9.0463C39.7421 9.12109 39.5595 9.15972 39.3748 9.15972C39.1901 9.15972 39.0075 9.12109 38.8386 9.0463C38.6698 8.97151 38.5184 8.86223 38.3943 8.72547C38.2702 8.58871 38.1761 8.42749 38.118 8.25219C38.0599 8.07689 38.0391 7.89137 38.057 7.70756ZM40.1251 7.70756C40.1251 7.10192 39.853 6.74775 39.3755 6.74775C38.8962 6.74775 38.6266 7.10192 38.6266 7.70757C38.6266 8.31806 38.8963 8.66951 39.3755 8.66951C39.853 8.6695 40.1251 8.31563 40.1251 7.70756Z"
          fill={hex}
        />
        <path
          d="M41.2734 8.3156C41.2734 7.81267 41.6479 7.52272 42.3126 7.48152L43.0695 7.43789V7.19672C43.0695 6.90163 42.8743 6.73499 42.4974 6.73499C42.1896 6.73499 41.9763 6.848 41.9151 7.04554H41.3813C41.4376 6.56563 41.8891 6.25781 42.5229 6.25781C43.2234 6.25781 43.6184 6.60653 43.6184 7.19672V9.10576H43.0876V8.71311H43.044C42.9555 8.85395 42.8311 8.96877 42.6837 9.04585C42.5362 9.12292 42.371 9.15948 42.2048 9.15179C42.0875 9.16399 41.9689 9.15148 41.8568 9.11506C41.7446 9.07864 41.6413 9.01911 41.5536 8.94033C41.4658 8.86155 41.3956 8.76525 41.3473 8.65765C41.2991 8.55005 41.2739 8.43353 41.2734 8.3156ZM43.0695 8.07686V7.84327L42.3872 7.88689C42.0024 7.91264 41.8279 8.04353 41.8279 8.28985C41.8279 8.54131 42.046 8.68765 42.346 8.68765C42.4339 8.69654 42.5227 8.68767 42.607 8.66155C42.6914 8.63544 42.7697 8.59261 42.8372 8.53563C42.9047 8.47864 42.9601 8.40866 43 8.32984C43.0399 8.25103 43.0635 8.16499 43.0695 8.07686Z"
          fill={hex}
        />
        <path
          d="M44.3418 7.70526C44.3418 6.8224 44.7957 6.26311 45.5016 6.26311C45.6762 6.25506 45.8495 6.29688 46.0012 6.38369C46.1529 6.47049 46.2768 6.59869 46.3584 6.75329H46.3996V5.21875H46.951V9.10347H46.4226V8.66204H46.379C46.2911 8.8156 46.1629 8.9422 46.0083 9.02813C45.8536 9.11406 45.6784 9.15603 45.5016 9.14952C44.7908 9.14955 44.3418 8.59025 44.3418 7.70526ZM44.9114 7.70526C44.9114 8.29788 45.1908 8.65448 45.6579 8.65448C46.1227 8.65448 46.4099 8.29273 46.4099 7.70769C46.4099 7.12537 46.1197 6.75847 45.6579 6.75847C45.1938 6.75847 44.9114 7.11749 44.9114 7.70526Z"
          fill={hex}
        />
        <path
          d="M49.2328 7.70751C49.2152 7.5239 49.2362 7.33864 49.2945 7.16362C49.3527 6.9886 49.4469 6.82768 49.5709 6.69118C49.695 6.55468 49.8462 6.44562 50.0149 6.37099C50.1836 6.29636 50.366 6.25781 50.5504 6.25781C50.7349 6.25781 50.9173 6.29636 51.086 6.37099C51.2547 6.44562 51.4059 6.55468 51.5299 6.69118C51.654 6.82768 51.7482 6.9886 51.8064 7.16362C51.8646 7.33864 51.8856 7.5239 51.8681 7.70751C51.886 7.89131 51.8652 8.07683 51.8071 8.25213C51.7491 8.42743 51.6549 8.58865 51.5309 8.72541C51.4068 8.86218 51.2554 8.97147 51.0866 9.04626C50.9177 9.12105 50.7351 9.15968 50.5504 9.15968C50.3658 9.15968 50.1831 9.12105 50.0143 9.04626C49.8454 8.97147 49.6941 8.86218 49.57 8.72541C49.4459 8.58865 49.3518 8.42743 49.2937 8.25213C49.2356 8.07683 49.2149 7.89131 49.2328 7.70751ZM51.3009 7.70751C51.3009 7.10187 51.0288 6.74769 50.5513 6.74769C50.072 6.74769 49.8024 7.10187 49.8024 7.70752C49.8024 8.31801 50.0721 8.66945 50.5513 8.66945C51.0288 8.66945 51.3009 8.31558 51.3009 7.70751Z"
          fill={hex}
        />
        <path
          d="M52.6074 6.30668H53.1382V6.75054H53.1794C53.2493 6.59112 53.3672 6.45749 53.5167 6.36826C53.6661 6.27903 53.8397 6.23865 54.0132 6.25275C54.1492 6.24253 54.2857 6.26303 54.4126 6.31273C54.5396 6.36242 54.6537 6.44005 54.7466 6.53985C54.8395 6.63965 54.9087 6.75907 54.9492 6.88927C54.9896 7.01946 55.0003 7.15708 54.9803 7.29195V9.10067H54.4289V7.43041C54.4289 6.98141 54.2338 6.75811 53.826 6.75811C53.7337 6.75382 53.6415 6.76952 53.5559 6.80417C53.4702 6.83881 53.3931 6.89156 53.3297 6.95882C53.2663 7.02607 53.2182 7.10623 53.1888 7.1938C53.1593 7.28137 53.1491 7.37428 53.1588 7.46616V9.1007H52.6074V6.30668Z"
          fill={hex}
        />
        <path
          d="M58.0971 5.61719V6.32554H58.7025V6.79H58.0971V8.2267C58.0971 8.51937 58.2177 8.64753 58.4922 8.64753C58.5625 8.6473 58.6327 8.64305 58.7025 8.6348V9.0941C58.6035 9.11182 58.5031 9.12124 58.4025 9.12228C57.7893 9.12228 57.5451 8.90656 57.5451 8.36788V6.78997H57.1016V6.32551H57.5451V5.61719H58.0971Z"
          fill={hex}
        />
        <path
          d="M59.4551 5.21875H60.0016V6.75846H60.0453C60.1186 6.59756 60.2397 6.46316 60.3922 6.3736C60.5446 6.28403 60.721 6.24365 60.8972 6.25795C61.0324 6.25059 61.1676 6.27317 61.2932 6.32408C61.4187 6.37498 61.5314 6.45296 61.6233 6.55245C61.7152 6.65194 61.784 6.77048 61.8248 6.89963C61.8657 7.02877 61.8775 7.16534 61.8594 7.29957V9.10348H61.3075V7.4356C61.3075 6.98932 61.0996 6.7633 60.71 6.7633C60.6152 6.75553 60.5199 6.76856 60.4307 6.80148C60.3415 6.8344 60.2606 6.88641 60.1935 6.95388C60.1265 7.02135 60.0751 7.10265 60.0428 7.19208C60.0105 7.28152 59.9981 7.37693 60.0065 7.47165V9.10346H59.4551L59.4551 5.21875Z"
          fill={hex}
        />
        <path
          d="M65.0758 8.3514C65.0008 8.60676 64.8385 8.82764 64.6172 8.97541C64.3959 9.12318 64.1296 9.18843 63.8651 9.15973C63.681 9.16459 63.4981 9.12933 63.329 9.05641C63.1599 8.98348 63.0087 8.87464 62.8859 8.73742C62.7631 8.60021 62.6716 8.43792 62.6179 8.26181C62.5641 8.0857 62.5492 7.9 62.5744 7.71758C62.5499 7.53461 62.565 7.34851 62.6186 7.17187C62.6723 6.99523 62.7633 6.83219 62.8855 6.69378C63.0076 6.55536 63.1581 6.44481 63.3267 6.3696C63.4953 6.29439 63.6781 6.25629 63.8627 6.25786C64.6401 6.25786 65.1091 6.789 65.1091 7.66637V7.85878H63.1361V7.88968C63.1275 7.99221 63.1404 8.09542 63.174 8.19268C63.2075 8.28994 63.2611 8.37911 63.3311 8.45447C63.4012 8.52983 63.4862 8.58972 63.5808 8.6303C63.6753 8.67088 63.7773 8.69125 63.8802 8.69011C64.0121 8.70594 64.1458 8.68219 64.2641 8.62188C64.3825 8.56156 64.4802 8.46741 64.5449 8.35138L65.0758 8.3514ZM63.1361 7.45097H64.5474C64.5543 7.35719 64.5416 7.263 64.5099 7.17445C64.4783 7.08589 64.4285 7.00493 64.3638 6.93677C64.299 6.8686 64.2207 6.81474 64.1339 6.77864C64.047 6.74255 63.9536 6.72501 63.8596 6.72717C63.7642 6.72597 63.6696 6.74387 63.5813 6.77983C63.4929 6.81578 63.4127 6.86906 63.3452 6.93651C63.2778 7.00397 63.2246 7.08424 63.1887 7.17259C63.1527 7.26095 63.1349 7.3556 63.1361 7.45097Z"
          fill={hex}
        />
      </g>
      <defs>
        <clipPath id="clip0_807_2574">
          <rect width="74.2502" height="24.8196" fill={hex} transform="translate(0.0722656)" />
        </clipPath>
      </defs>
    </svg>
  )
}
