import React from 'react'
import classNames from 'classnames'
import { ReactFCC } from '@/types/react'

interface MovieRatingsContainerProps {
  className?: string
}

export const MovieRatingsContainer: ReactFCC<MovieRatingsContainerProps> = ({ className, children }) => {
  return (
    <div
      className={classNames('flex justify-around w-4/5 mx-auto md:order-first md:row-start-2 md:flex-col', className)}
    >
      {children}
    </div>
  )
}
