import React from 'react'
import classNames from 'classnames'
import { Case, Switch } from 'react-if'
import Sticky from 'react-stickynode'
import { Button } from '@/atoms/Button'
import { PlayIcon } from '@/atoms/Icons/PlayIcon'
import { Image } from '@/atoms/Image'
import { scaleDimensions } from '@/atoms/utils'
import { CatalogImage, formatDimensions } from '@/services/ContentCatalog'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'

interface DigitalStickyCtaProps {
  titleLogo: CatalogImage | undefined
  projectName: string
  logoImageUrl?: string | null | undefined
  handleModalClick?: () => void
}

export const DigitalStickyCta: React.FC<DigitalStickyCtaProps> = ({
  titleLogo,
  projectName,
  logoImageUrl,
  handleModalClick,
}) => {
  const { t } = useTranslate('theatrical-presales')
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(65, 83, 24)
  const { width: mobileScaledWidth, height: mobileScaledHeight } = scaleDimensions(40, 83, 24)

  return (
    <>
      <Sticky className="hidden max-h-0 overflow-hidden md:block" activeClass="relative z-[98] !max-h-[100px]">
        <div
          className={classNames(
            'bg-black/80 backdrop-blur-md w-full flex justify-between align-center z-[99] relative overflow-hidden p-6 items-center',
          )}
        >
          <Switch>
            <Case condition={logoImageUrl}>
              {logoImageUrl && (
                <div className="w-full max-w-[500px]">
                  <Image
                    alt={projectName}
                    src={getCloudinaryImageUrl({
                      path: logoImageUrl,
                      transforms: 'e_trim,q_auto:best',
                    })}
                    width={scaledWidth}
                    height={scaledHeight}
                  />
                </div>
              )}
            </Case>
            <Case condition={!logoImageUrl}>
              {titleLogo && (
                <div className="max-w-[500px]">
                  <Image
                    alt={projectName}
                    src={getCloudinaryImageUrl({
                      path: titleLogo.cloudinaryPath,
                      transforms: 'e_trim',
                      ...formatDimensions([110, 'auto'], titleLogo),
                    })}
                    {...formatDimensions([110, 'auto'], titleLogo)}
                  />
                </div>
              )}{' '}
            </Case>
          </Switch>
          <Button
            variant="white"
            className="flex min-w-[190px] flex-row gap-1.5 text-core-gray-950"
            onClick={handleModalClick}
          >
            <PlayIcon size={24} color="core-gray-950" />
            {t('watchTrailer', 'Watch trailer')}
          </Button>
        </div>
      </Sticky>
      <Sticky className="max-h-0 overflow-hidden md:hidden" activeClass="relative z-[98] !max-h-[100px]">
        <div
          className={classNames(
            'bg-black/80 backdrop-blur-md gap-4 w-full flex justify-between align-center z-[99] relative overflow-hidden py-6 px-4 items-center',
          )}
        >
          <Switch>
            <Case condition={logoImageUrl}>
              {logoImageUrl && (
                <div className="w-full max-w-[500px]">
                  <Image
                    alt={projectName}
                    src={getCloudinaryImageUrl({
                      path: logoImageUrl,
                      transforms: 'e_trim,q_auto:best',
                    })}
                    width={mobileScaledWidth}
                    height={mobileScaledHeight}
                  />
                </div>
              )}
            </Case>
            <Case condition={!logoImageUrl}>
              {titleLogo && (
                <div className="max-w-[500px]">
                  <Image
                    alt={projectName}
                    src={getCloudinaryImageUrl({
                      path: titleLogo.cloudinaryPath,
                      transforms: 'e_trim',
                      ...formatDimensions([110, 'auto'], titleLogo),
                    })}
                    {...formatDimensions([110, 'auto'], titleLogo)}
                  />
                </div>
              )}{' '}
            </Case>
          </Switch>
          <Button
            variant="white"
            className="flex min-w-[190px] flex-row gap-1.5 text-core-gray-950"
            onClick={handleModalClick}
          >
            <PlayIcon size={24} color="core-gray-950" />
            {t('watchTrailer', 'Watch trailer')}
          </Button>
        </div>
      </Sticky>
    </>
  )
}
