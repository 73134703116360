import React from 'react'
import classNames from 'classnames'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { AsFigCaption, AsH3, HeadingXS, ParagraphSM } from '@/atoms/Text'
import { ContentfulCloudinaryImageType } from '@/services/EmailCapturePageService/EmailCapturePageService'
import { buildCloudinaryImagePathFromContentfulObject } from '@/utils/Cloudinary/cloudinary'

interface MovieRatingProps {
  icon: ContentfulCloudinaryImageType | ContentfulCloudinaryImageType[]
  rating: string | undefined | null
  author: string | undefined | null
  size?: number
  className?: string
}

export const MovieRating: React.FC<MovieRatingProps> = ({ icon, rating, author, className, size = 56 }) => {
  return (
    <figure className={classNames('flex flex-col items-center md:justify-around md:flex-row m-4', className)}>
      <div className="mx-auto w-[56px]">
        <Image
          src={buildCloudinaryImagePathFromContentfulObject(icon)}
          width={size}
          height={size}
          alt={`${author} Rating`}
        />
      </div>
      <div className="text-center md:mx-auto md:ml-2 md:w-[60%]">
        <HeadingXS as={AsFigCaption} className="mb-1 mt-2">
          {rating}
        </HeadingXS>
        <ParagraphSM as={AsH3}>{author}</ParagraphSM>
      </div>
    </figure>
  )
}
