import React, { useEffect, useMemo, useState } from 'react'
import { useExperiment } from '@optimizely/react-sdk'
import { useRouter } from 'next/router'
import { Case, Default, Switch, When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { LinkButton } from '@/atoms/Button'
import { UpArrowCircleIcon } from '@/atoms/Icons/UpArrowCircleIcon'
import { Image } from '@/atoms/Image'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsH2, CaptionSM, HeadingXL, HeadingXS } from '@/atoms/Text'
import { paths, slugs } from '@/constants'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { useTheatricalRegionContext } from '@/contexts/TheatricalRegionContext'
import { HorizontalShopifyMerchScroller } from '@/organisms/HorizontalShopifyMerchScroller'
import { InvestmentBanner } from '@/organisms/InvestmentBanner'
import { ProjectJourney } from '@/organisms/ProjectJourney'
import { ShareSection } from '@/organisms/ShareSection'
import { getWebClient } from '@/services/ApolloClient'
import {
  CatalogMovie,
  CatalogSeries,
  formatDimensions,
  formatNonTitleLikeLandscapeImagePath,
  formatNonTitleLikeVerticalImagePath,
  formatTitleLogo,
} from '@/services/ContentCatalog'
import { useGuildUser, useUserGuildTickets } from '@/services/GuildUserService'
import { PlayableLivestream } from '@/services/LivestreamService'
import { WhereToWatch } from '@/services/WhereToWatchService'
import {
  FilmDetails as FilmDetailsType,
  FilmDetailsAdditionalVideosCollection,
  ProjectTheme,
  InvestmentBanner as InvestmentBannerContent,
} from '@/types/codegen-contentful'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { FaqSection } from '@/views/WatchTabView/FaqSection'
import { FilmDetails } from '@/views/WatchTheatricalView/FilmDetails'
import { StickyCta } from '@/views/WatchTheatricalView/StickyCta'
import { VideoGallery } from '@/views/WatchTheatricalView/VideoGallery'
import { useWatchTheatricalContext } from '@/views/WatchTheatricalView/WatchTheatricalContext'
import { WhereToWatchSection } from '@/views/WatchTheatricalView/WhereToWatch'
import { CtaSquareCollection } from './CtaSquareCollection'
import { GuildPromotionFloatingCta } from './GuildPromotionFloatingCta'
import { ImageGallery } from './ImageGallery'
import { LivestreamLink } from './LivestreamLink'
import { PressLinksSection } from './PressLinksSection'
import { Reviews } from './Reviews'
import { Ctas, CtaStatusLine } from './ctas'
import { GetUpdatesCta } from './getUpdatesCta'

interface WatchTheatricalViewProps {
  livestreams: PlayableLivestream[] | null
  catalogTitle?: CatalogMovie | CatalogSeries
  whereToWatch: WhereToWatch
  contentfulProjectTheme: ProjectTheme
  canShowTickets?: boolean
}

export const WatchTheatricalView: React.FC<WatchTheatricalViewProps> = (props) => {
  const [joinTheMovementBannerVisited, setJoinTheMovementBannerVisited] = useState(false)
  const { canShowTickets = false } = props
  const { isGuildMember, loading } = useGuildUser()
  const projectMeta = useProjectMeta()
  const client = getWebClient()
  const { asPath } = useRouter()
  const { t } = useTranslate('theatrical-presales')
  const {
    critics,
    filmDetails,
    friendlyTheatricalReleaseDate,
    hasPressPage,
    heroCta,
    images,
    investmentBanner,
    logo,
    name,
    shopifyStore,
    slug,
  } = useWatchTheatricalContext()
  const { heroBackgroundVideo, heroBackgroundImage, trailerSourceUrl, additionalVideosCollection, shareOverrideUrl } =
    filmDetails || {}
  const { hubPageHeroBackgroundVideo, isInTheaters, userCountry, allTheatricalReleaseRegions } =
    useTheatricalRegionContext()

  const { numberAvailable: guildTicketsCount = 0 } = useUserGuildTickets({
    theatricalSlug: slug,
    client,
    skip: !isGuildMember,
  })
  const hasEarlyAccess = useMemo(() => {
    return !loading && isGuildMember
  }, [isGuildMember, loading])

  const experiment = useExperiment('guild_membership_tiers_experiment')
  const experimentOn = experiment[0] === 'on'

  const projectName = name as string

  const merchAnalytics = useMemo(() => {
    return { context: 'watch-theatrical', program: slug }
  }, [slug])
  const bgVideo = hubPageHeroBackgroundVideo || heroBackgroundVideo
  const heroBgImagePath = props.catalogTitle
    ? formatNonTitleLikeLandscapeImagePath(props.catalogTitle)
    : Array.isArray(heroBackgroundImage)
    ? heroBackgroundImage[0].url
    : null
  const mobileHeroBgImagePath = props.catalogTitle
    ? formatNonTitleLikeVerticalImagePath(props.catalogTitle)
    : Array.isArray(heroBackgroundImage)
    ? heroBackgroundImage[0].url
    : null
  const titleLogo = formatTitleLogo(props.catalogTitle)
  const canShowTitleLogo = Boolean(props.catalogTitle && titleLogo)

  const totalVideos = (props.livestreams?.length || 0) + (additionalVideosCollection?.items?.length || 0)
  const canShowVideoGallery = trailerSourceUrl || totalVideos > 0

  const bonhoefferDesktopBackground =
    'https://images.angelstudios.com/image/upload/c_fill,g_face,e_brightness_hsb:-10/v1723581778/studio-app/catalog/59b5abe4-7fb1-4d98-8d92-26621b40dc79'
  const bonhoefferMobileBackground =
    'https://images.angelstudios.com/image/upload/c_fill,g_face,e_brightness_hsb:-25/v1723581778/studio-app/catalog/18b839dd-3bf4-4cd3-a1d6-c30e7f77617b'

  useEffect(() => {
    switch (window?.location?.hash?.split('?')[0]) {
      case '#join-the-movement':
        if (joinTheMovementBannerVisited) return
        setJoinTheMovementBannerVisited(true)
        document.getElementById('join-the-movement')?.scrollIntoView({ behavior: 'smooth' })
        break
    }
  }, [joinTheMovementBannerVisited])

  return (
    <main>
      <section aria-labelledby="cta-status-line" id="hero" className="h-screen w-full bg-cover">
        <Switch>
          <Case condition={bgVideo}>
            <video
              className="relative left-1/2 top-1/2 h-full min-w-full -translate-x-1/2 -translate-y-1/2 bg-cover object-cover"
              autoPlay
              playsInline
              muted
              loop
              src={bgVideo as string}
            />
          </Case>
          <Case condition={heroBgImagePath}>
            <div className="relative left-1/2 top-1/2 h-full min-w-full -translate-x-1/2 -translate-y-1/2 bg-cover object-cover">
              <ResponsiveBackgroundImage
                src={getCloudinaryImageUrl({
                  path: mobileHeroBgImagePath,
                })}
                className="!block lg:!hidden"
              />
              <ResponsiveBackgroundImage
                src={getCloudinaryImageUrl({
                  path: heroBgImagePath,
                })}
                className="!hidden lg:!block"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-core-gray-950" />
            </div>
          </Case>
        </Switch>
        <div className="absolute bottom-[40px] left-1/2 flex max-h-screen w-full -translate-x-1/2 flex-col items-center justify-center gap-6">
          <Switch>
            <Case condition={canShowTitleLogo}>
              {titleLogo && (
                <div className="max-h-[500px] max-w-full p-[25px] md:p-0 shortDesktop:max-h-[350px] shortDesktop:max-w-[350px] se:max-w-full">
                  <Image
                    alt={projectName}
                    src={getCloudinaryImageUrl({
                      path: titleLogo.cloudinaryPath,
                      transforms: 'e_trim,q_auto:best',
                      ...formatDimensions([1000, 1000], titleLogo),
                    })}
                    className="max-h-[500px] max-w-full shortDesktop:max-h-[350px] shortDesktop:max-w-[350px] se:max-w-full"
                    {...formatDimensions([500, 'auto'], titleLogo)}
                  />
                </div>
              )}{' '}
            </Case>
            <Case condition={!canShowTitleLogo && Array.isArray(logo)}>
              <div className="max-w-[500px] p-[25px] md:p-0">
                {Array.isArray(logo) && (
                  <Image
                    alt={projectName}
                    src={getCloudinaryImageUrl({
                      path: logo[0]?.url ?? '',
                      transforms: 'e_trim',
                    })}
                    width={logo[0]?.width}
                    height={logo[0]?.height}
                  />
                )}
              </div>
            </Case>
          </Switch>
          <CtaStatusLine
            heroCta={heroCta}
            isInTheaters={isInTheaters}
            friendlyTheatricalReleaseDate={friendlyTheatricalReleaseDate}
            filmDetails={filmDetails as FilmDetailsType}
            userRegionCode={userCountry}
            project={projectMeta}
            allTheatricalReleaseRegions={allTheatricalReleaseRegions}
            experimentOn={experimentOn}
            hasEarlyAccess={hasEarlyAccess}
            projectName={projectName}
          />
          <Ctas
            heroCta={heroCta}
            userRegionCode={userCountry}
            project={projectMeta}
            filmDetails={filmDetails as FilmDetailsType}
            allTheatricalReleaseRegions={allTheatricalReleaseRegions}
            experimentOn={experimentOn}
            hasEarlyAccess={hasEarlyAccess}
            isGuildMember={isGuildMember}
            numberAvailable={guildTicketsCount}
            canShowTickets={canShowTickets}
          />
          <a
            aria-label={t('learnMoreAboutShow', 'Learn More About {{projectName}}', { projectName })}
            href="#get-involved"
            className="flex flex-col items-center justify-center"
          >
            <UpArrowCircleIcon className="rotate-180" color="white" />
          </a>
        </div>
      </section>
      <nav id="sticky-cta" aria-labelledby="sticky-cta-title">
        <h2 className="sr-only" id="sticky-cta-title">
          <Translate i18nKey="joinTheGuildForTickets" t={t}>
            Join the Guild To Get Two Tickets
          </Translate>
        </h2>
        <StickyCta
          heroCta={heroCta}
          allTheatricalReleaseRegions={allTheatricalReleaseRegions}
          experimentOn={experimentOn}
          hasEarlyAccess={hasEarlyAccess}
          filmDetails={filmDetails as FilmDetailsType}
          projectName={projectName}
          userCountry={userCountry}
          titleLogo={titleLogo}
          projectMeta={projectMeta}
          logo={logo}
        />
      </nav>
      <section id="cta-collection" aria-labelledby="get-involved-section">
        <h2 id="get-involved-section" className="sr-only">
          <Translate i18nKey="getInvolved" t={t}>
            Get Involved
          </Translate>
        </h2>
        <PaddedContainer className="!px-0">
          <CtaSquareCollection />
        </PaddedContainer>
      </section>
      <When condition={Boolean(props.whereToWatch)}>
        <WhereToWatchSection
          projectName={projectName}
          projectSlug={projectMeta.slug}
          whereToWatch={props.whereToWatch}
        />
      </When>

      {investmentBanner && (
        <InvestmentBanner {...(investmentBanner as InvestmentBannerContent)} className="py-8 lg:mt-12" hideTrailer />
      )}

      <When condition={slug === slugs.bonhoeffer}>
        <section className="bg-black px-4 pt-4 lg:mt-20 lg:px-0 lg:pt-0" id="join-the-movement">
          <div className="relative flex min-h-[224px] items-center overflow-hidden rounded-2xl px-4 py-8 lg:min-h-[394px] lg:rounded-none lg:px-[120px] lg:py-20 2xl:min-h-[540px] 2xl:px-[160px] 3xl:min-h-[980px]">
            <ResponsiveBackgroundImage
              src={bonhoefferDesktopBackground}
              srcUrls={{
                xs: bonhoefferMobileBackground,
                sm: bonhoefferMobileBackground,
                md: bonhoefferMobileBackground,
                lg: bonhoefferDesktopBackground,
                xl: bonhoefferDesktopBackground,
                '2xl': bonhoefferDesktopBackground,
                '3xl': bonhoefferDesktopBackground,
              }}
              className="h-full w-full bg-black bg-cover bg-center object-cover"
            />
            <div className="relative flex flex-col items-start">
              <HeadingXS weight="bold" color="white" className="lg:photon-heading-large mb-1 lg:mb-3">
                {t('joinTheMovement', 'Join The Movement')}
              </HeadingXS>
              <CaptionSM
                weight="medium"
                color="core-gray-100"
                className="lg:photon-paragraph-xl max-w-[196px] lg:max-w-[333px]"
              >
                {t(
                  'signBonhoefferDeclaration',
                  'Sign the Bonhoeffer Declaration and declare your commitment to reject all forms of racism and antisemitism.',
                )}
              </CaptionSM>
              <LinkButton variant="white" href={paths.external.bonhoefferDeclaration.index} className="mt-4 w-fit">
                {t('signTheDeclaration', 'Sign the Declaration')}
              </LinkButton>
            </div>
          </div>
        </section>
      </When>

      <When condition={Boolean(critics?.items?.length)}>
        <section aria-labelledby="review-section-title" key="reviews" className="bg-black">
          <PaddedContainer>
            <div className="w-full bg-black py-5 lg:py-[80px]">
              <Reviews />
            </div>
          </PaddedContainer>
        </section>
      </When>
      <section aria-labelledby="livestream-section-title" className="bg-black">
        <LivestreamLink
          filmDetails={filmDetails as FilmDetailsType}
          projectName={projectName}
          projectId={projectMeta.id}
        />
      </section>

      <When condition={canShowVideoGallery}>
        <section
          aria-labelledby="video-section-title"
          key="video-gallery"
          id="video-gallery"
          className="w-full bg-black py-5 lg:py-[80px]"
        >
          <PaddedContainer>
            <VideoGallery
              trailerUrl={trailerSourceUrl}
              trailerTitle={t('filmTrailer', '{{projectName}} Trailer', { projectName })}
              additionalVideos={additionalVideosCollection as FilmDetailsAdditionalVideosCollection}
              livestreams={
                props.livestreams &&
                props.livestreams.slice().sort((a, b) => (a?.url ?? '').localeCompare(b?.url ?? ''))
              }
            />
          </PaddedContainer>
        </section>
      </When>

      {props.catalogTitle && (
        <section aria-labelledby="about-the-show" id="film-details" key="film-details">
          <h2 id="about-the-show" className="sr-only">
            <Switch>
              <Case condition={projectMeta.projectType === 'movie'}>
                <Translate i18nKey="aboutTheFilm" t={t}>
                  About the Film
                </Translate>
              </Case>
              <Case condition={projectMeta.projectType === 'series'}>
                <Translate i18nKey="aboutTheSeries" t={t}>
                  About the Series
                </Translate>
              </Case>
              <Default>
                <Translate i18nKey="aboutTheShow" t={t}>
                  About the Show
                </Translate>
              </Default>
            </Switch>
          </h2>
          <PaddedContainer>
            <FilmDetails filmDetails={filmDetails as FilmDetailsType} catalogTitle={props.catalogTitle} />
          </PaddedContainer>
        </section>
      )}

      <div className="bg-black">
        <ProjectJourney
          projectSlug={projectMeta.slug}
          className="mx-auto max-w-[1292px] px-6 py-16 text-white sm:px-12 2xl:px-0"
        />
      </div>

      {filmDetails?.willHaveTheatricalRelease && hasPressPage && (
        <section aria-labelledby="media-kits-title" className="bg-black lg:py-16">
          <h2 id="media-kits-title" className="sr-only">
            <Translate i18nKey="mediaKits" t={t}>
              Media Kits
            </Translate>
          </h2>
          <PressLinksSection
            projectName={projectName}
            projectSlug={slug}
            contentfulProjectTheme={props.contentfulProjectTheme}
          />
        </section>
      )}
      <section aria-label="get-updates-title">
        <GetUpdatesCta slug={slug} />
      </section>
      {Boolean(images?.length) && (
        <section aria-labelledby="image-gallery-title" className="mt-5 w-full bg-black py-5">
          <PaddedContainer className="bg-black !px-0">
            <div key="image-gallery overflow-x-hidden">
              <div className="bg-black py-10 lg:pb-20">
                <div className="mb-10 text-center">
                  <HeadingXL id="image-gallery-title" as={AsH2} weight="bold" color="white">
                    {t('imageGallery', 'Image Gallery')}
                  </HeadingXL>
                </div>
                <LazyHydrate whenVisible>
                  <ImageGallery images={images} />
                </LazyHydrate>
              </div>
            </div>
          </PaddedContainer>
        </section>
      )}
      {shopifyStore && shopifyStore.storeUrl && shopifyStore.accessToken && shopifyStore.handle && (
        <section
          aria-labelledby="merch-gallery-title"
          key="merch-gallery"
          className="w-full overflow-x-hidden bg-black pt-20"
        >
          <LazyHydrate whenVisible>
            <div className="bg-black">
              <div className="mx-auto scroll-mt-[73px] text-white">
                <HorizontalShopifyMerchScroller
                  storeUrl={shopifyStore.storeUrl}
                  accessToken={shopifyStore.accessToken}
                  handle={shopifyStore.handle}
                  storeHref={shopifyStore?.storeHref}
                  analytics={merchAnalytics}
                  slug={slug}
                  padding
                />
              </div>
            </div>
          </LazyHydrate>
        </section>
      )}
      <section aria-labelledby="share-section-title" className="overflow-hidden bg-black pt-20">
        <LazyHydrate whenVisible>
          <ShareSection
            projectName={projectName}
            theatricalSlug={slug}
            showYourImpact={canShowTickets}
            shareUrl={shareOverrideUrl ?? asPath}
            projectType={projectMeta.projectType}
            className="text-center"
          />
        </LazyHydrate>
      </section>
      <section aria-labelledby="faq-section-title" key="faqs" className="overflow-x-hidden bg-black pb-40 pt-20">
        <div id="faqs" className="mx-auto max-w-[860px] scroll-mt-[73px] px-8 text-white">
          <LazyHydrate whenVisible>
            <FaqSection />
          </LazyHydrate>
        </div>
      </section>

      <When condition={slug === slugs.bonhoeffer && !isGuildMember}>
        <GuildPromotionFloatingCta
          userRegionCode={userCountry}
          project={projectMeta}
          filmDetails={filmDetails as FilmDetailsType}
          allTheatricalReleaseRegions={allTheatricalReleaseRegions}
          experimentOn={experimentOn}
          hasEarlyAccess={hasEarlyAccess}
          isGuildMember={isGuildMember}
        />
      </When>
    </main>
  )
}
