import React from 'react'
import { useTranslate } from '@/utils/translate/translate-client'
import { getHexColor, scaleDimensions } from '../utils'
import { OneColorLogoProps } from './LogoTypes'

export const BluRayDiscLogo: React.FC<OneColorLogoProps> = ({ height = 24, color = 'white', className }) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(height, 39, 21)
  const hex = getHexColor(color)
  const { t } = useTranslate('common')

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={scaledWidth}
      height={scaledHeight}
      viewBox="0 0 41 22"
      fill="none"
      aria-label={t('bluray', 'Blu-ray')}
    >
      <path
        d="M8.1258 16.1699C8.04037 16.1699 7.74945 16.2555 7.49279 16.6323C7.23626 17.0092 6.07292 18.9279 5.86767 19.2876C5.64516 19.6473 5.7137 19.8016 5.9531 19.8016C6.15835 19.8016 6.20975 19.8016 6.46629 19.8016C6.70581 19.8016 6.92819 19.5275 7.01375 19.339C7.13357 19.1677 8.50203 16.9407 8.72442 16.6154C8.91254 16.2898 8.79296 16.1702 8.63887 16.1702C8.50228 16.1699 8.1258 16.1699 8.1258 16.1699Z"
        fill={hex}
      />
      <path
        d="M3.45612 19.8015C4.32865 19.7673 4.91026 19.7327 5.59456 18.4824C5.88536 17.917 5.06423 17.8997 5.06423 17.8997C4.94441 17.8997 5.81694 17.917 6.27885 16.9404C6.70661 16.0495 5.95378 16.1183 5.81707 16.1011C5.16692 16.0153 3.9523 16.0495 3.02861 16.1011C2.84037 16.1011 2.61798 16.3579 2.49816 16.5122C2.41273 16.6834 0.992734 18.9787 0.787482 19.3044C0.564972 19.6468 0.787482 19.7842 1.00987 19.8011C1.65989 19.8357 2.48102 19.8357 3.45612 19.8015ZM4.20883 18.6022C3.96943 19.0991 3.52454 19.1162 3.268 19.1162C3.02861 19.1162 2.66926 19.1162 2.58371 19.1162C2.49816 19.1162 2.4126 19.1162 2.49816 18.9277C2.61798 18.7737 2.78896 18.4995 2.8575 18.3966C2.92592 18.2767 2.99421 18.2425 3.14818 18.2425C3.14818 18.2425 3.72991 18.2425 3.88388 18.2425C4.02072 18.2425 4.37994 18.2253 4.20883 18.6022ZM4.84172 17.129C4.60232 17.6255 4.14041 17.6255 3.90089 17.6255C3.64436 17.6255 3.59295 17.6255 3.49039 17.6255C3.42185 17.6255 3.30215 17.6255 3.42185 17.4715C3.54167 17.3 3.67838 17.0088 3.76394 16.9061C3.84936 16.8034 3.91791 16.7518 4.03773 16.7518C4.03773 16.7518 4.39695 16.7518 4.53378 16.7518C4.67074 16.752 5.03008 16.752 4.84172 17.129Z"
        fill={hex}
      />
      <path
        d="M31.939 17.5422L30.81 19.3407C30.5706 19.7176 30.776 19.8034 30.8955 19.8034C31.0154 19.8034 31.1863 19.8034 31.3916 19.8034C31.5796 19.8034 31.7167 19.7863 32.0075 19.3407L33.1365 17.5079C33.4444 17.011 33.0679 17.0454 32.8285 17.0454C32.401 17.0625 32.2984 17.0456 31.939 17.5422Z"
        fill={hex}
      />
      <path
        d="M36.5935 17.6605H35.2418C35.1051 17.6605 34.9167 17.7806 34.8142 17.9175C34.7288 18.0545 34.78 18.1916 34.9167 18.1916C34.9167 18.1745 35.4641 18.1916 35.8747 18.1916C36.3025 18.1745 36.4566 18.4658 35.9948 19.0996C35.9091 19.2363 35.7209 19.4593 35.5668 19.5447C35.5668 19.5447 35.1904 19.8531 33.9759 19.8531C32.8639 19.8531 32.8126 19.7329 32.8126 19.7329C32.71 19.6474 32.676 19.4933 32.71 19.4073C32.7613 19.3388 32.9326 19.2705 33.0692 19.2705H34.472C34.6087 19.2705 34.797 19.1507 34.8825 18.9964C34.9853 18.8595 34.9338 18.6882 34.7971 18.6882C34.7971 18.6882 34.2497 18.6882 33.7537 18.6882C33.2576 18.6882 33.3259 18.3624 33.7024 17.8145C33.8049 17.6602 33.9931 17.472 34.13 17.369C34.13 17.369 34.5405 17.0606 35.7381 17.0606C36.2173 17.0606 37.021 17.0093 36.9699 17.4206C36.9527 17.6091 36.679 17.6605 36.5935 17.6605Z"
        fill={hex}
      />
      <path
        d="M40.1334 17.6792H39.0385C38.9017 17.6792 38.7134 17.7993 38.6281 17.9704L37.9781 18.9812C37.8924 19.1183 37.9439 19.2724 38.0465 19.2724C38.0465 19.2724 39.1586 19.2724 39.3467 19.2724C39.5347 19.2724 39.6546 19.2895 39.6375 19.3409C39.6203 19.5294 39.3467 19.8549 37.824 19.8549C36.4554 19.8549 36.541 19.6664 36.558 19.4611C36.5922 19.2382 37.2592 18.1763 37.4645 17.8507C37.6699 17.5083 37.9607 17.0625 39.3122 17.0625C40.5782 17.0625 40.5095 17.3537 40.4924 17.4225C40.4926 17.4908 40.4243 17.6792 40.1334 17.6792Z"
        fill={hex}
      />
      <path
        d="M33.7863 16.0176C33.8888 16.0176 34.0088 16.1205 33.923 16.2917C33.8204 16.4289 33.7863 16.4974 33.735 16.6001C33.6666 16.7028 33.5125 16.7542 33.3245 16.7542C33.1362 16.7542 32.6915 16.7542 32.6232 16.7542C32.4863 16.7542 32.4863 16.6343 32.5545 16.5147C32.6058 16.412 32.6399 16.3604 32.6915 16.2917C32.7427 16.2063 32.8282 16.0176 33.102 16.0176H33.7863Z"
        fill={hex}
      />
      <path
        d="M13.3268 17.9866C13.0703 17.9866 12.9676 18.158 12.9163 18.2436C12.882 18.3121 12.8307 18.3634 12.7623 18.4834C12.7109 18.6033 12.728 18.7229 12.7965 18.7229C12.8819 18.7229 13.3438 18.7229 13.532 18.7229C13.7546 18.7229 13.8741 18.6544 13.9425 18.5517C14.0111 18.4318 14.0281 18.3974 14.1308 18.2262C14.2505 18.0548 14.0965 17.9863 14.011 17.9863L13.3268 17.9866Z"
        fill={hex}
      />
      <path
        d="M30.3323 18.6882C30.7769 17.9685 30.5033 18.4139 31.1361 17.4034C31.752 16.3753 30.7769 16.1699 29.8188 16.1699C28.3476 16.1699 27.9027 16.2384 27.9027 16.2384C27.7489 16.2384 27.5436 16.3753 27.4581 16.5468L25.6788 19.4248C25.5762 19.596 25.6103 19.7329 25.7475 19.7672C25.7475 19.7672 26.1751 19.8014 27.6461 19.8014C29.6651 19.8016 29.8531 19.4248 30.3323 18.6882ZM29.7334 17.4034C29.7334 17.4034 28.9637 18.6195 28.9122 18.6882C28.8437 18.7909 28.6042 19.1164 28.245 19.1164C28.0227 19.1164 27.7148 19.1164 27.4924 19.1164C27.3555 19.1164 27.287 19.0992 27.3727 18.9621L28.6042 16.9921C28.6558 16.9235 28.7412 16.8722 28.8437 16.8722C29.0491 16.8722 29.4255 16.8722 29.5793 16.8722C29.819 16.8722 29.8873 17.1292 29.7334 17.4034Z"
        fill={hex}
      />
      <path
        d="M18.5456 18.1241C18.1179 18.1241 17.9811 18.1581 17.6047 18.1754C17.4508 18.1754 17.2455 18.2953 17.16 18.4665L16.8008 19.0832C16.356 19.7855 17.3139 19.8368 18.0667 19.8368C19.2814 19.8368 19.8629 19.4943 19.8629 19.4943C20.0169 19.4258 20.2051 19.2373 20.2906 19.0832L21.0776 17.7984C21.1632 17.6614 21.1976 17.4215 21.1116 17.2845C21.1116 17.2845 21.0091 17.0449 19.7945 17.0449C18.597 17.0449 18.1179 17.2332 18.1179 17.2332C17.9811 17.3017 17.81 17.4215 17.7586 17.4902C17.7246 17.5928 17.7929 17.6614 17.9298 17.6614H19.7089C19.8459 17.6614 19.8972 17.7815 19.8287 17.9011C19.6748 18.1068 19.4182 18.1241 19.4182 18.1241H18.5456ZM19.2299 18.9634V18.9805C19.11 19.1521 18.8705 19.3062 18.7338 19.3062H18.3746H18.0495C17.9127 19.3062 17.8613 19.1521 17.981 18.9805V18.9634C18.1008 18.7751 18.3402 18.6208 18.477 18.6208H19.1613C19.2983 18.6208 19.3496 18.7751 19.2299 18.9634Z"
        fill={hex}
      />
      <path
        d="M16.1844 17.9195C16.3041 17.7482 16.4923 17.6283 16.6291 17.6283H17.1764C17.262 17.6283 17.5356 17.6112 17.5529 17.4402C17.57 17.1659 16.9883 17.0977 16.7659 17.0977C15.9619 17.0977 15.517 17.1319 15.0551 17.8171C14.6959 18.3649 14.3367 18.9305 14.0972 19.3416C13.8919 19.7186 14.0802 19.8043 14.1998 19.8043C14.3197 19.8043 14.5078 19.8043 14.6959 19.8043C14.884 19.8043 15.021 19.7871 15.3118 19.3416L16.1844 17.9195Z"
        fill={hex}
      />
      <path
        d="M12.608 17.5596L11.8382 18.8444C11.7355 18.9812 11.8382 19.0498 11.9236 19.0498C12.0263 19.0498 12.1118 19.0498 12.1974 19.0498C12.2829 19.0498 12.4026 19.1528 12.3 19.3068C12.2145 19.4783 12.1974 19.5466 12.112 19.6493C12.0434 19.7694 11.9237 19.8036 11.7185 19.8036C11.5818 19.8036 11.4791 19.8036 11.3934 19.8036C11.171 19.8036 11.2907 19.478 11.2907 19.478C10.9658 19.8378 10.213 19.8378 9.42607 19.8378C8.67348 19.8378 7.69826 19.8378 8.14315 19.0841L9.10112 17.5423C9.25509 17.2853 9.37479 17.1827 9.52875 17.1141C9.52875 17.1141 9.5629 17.0625 9.9734 17.0625C10.2472 17.0625 10.6064 17.0625 10.2986 17.5423L9.42607 18.9643C9.3235 19.1014 9.37479 19.2384 9.52875 19.2384H10.1274C10.2813 19.2384 10.4696 19.1017 10.5893 18.9643C10.5893 18.9643 11.0341 18.1935 11.4277 17.5596C11.5302 17.4055 11.7869 17.0627 12.0434 17.0627C12.0434 17.0627 12.2829 17.0627 12.4712 17.0627C12.6594 17.0627 12.8817 17.1143 12.608 17.5596Z"
        fill={hex}
      />
      <path
        d="M20.393 20.523H21.813L22.1038 20.5401C22.2576 20.5401 22.4459 20.4033 22.5314 20.2318L22.8222 19.8035C22.634 19.8377 22.4459 19.8377 22.2235 19.8377C21.505 19.8377 20.5129 19.8377 20.9575 19.0841L21.8813 17.5938C22.001 17.4224 22.1892 17.2341 22.3089 17.1654C22.3089 17.1314 22.3773 17.1141 22.7709 17.1141C23.0273 17.1141 23.4381 17.0456 23.0786 17.5938L22.2576 18.9642C22.1551 19.1014 22.1892 19.2384 22.326 19.2384H22.9079C23.0276 19.2384 23.2499 19.1016 23.3355 18.9642L24.1908 17.5936C24.2762 17.4224 24.4816 17.2341 24.6186 17.1654C24.6186 17.1654 24.6872 17.1141 25.0802 17.1141C25.3029 17.1141 25.7476 17.0456 25.3884 17.5936L24.6699 18.7417C24.6357 18.793 24.6186 18.8102 24.5844 18.8957L23.6779 20.3689C23.5753 20.5232 23.3871 20.7117 23.2502 20.7974C23.2502 20.7974 22.6515 21.1399 21.4541 21.1399C21.4028 21.1399 21.3342 21.1399 21.2829 21.1399C20.1708 21.1057 20.2222 21.037 20.1538 20.9856C20.0853 20.9685 20.0169 20.8658 20.0339 20.7286C20.0508 20.6085 20.2047 20.523 20.393 20.523Z"
        fill={hex}
      />
      <path
        d="M9.10107 1.31836C9.0668 1.31836 9.0668 1.33576 9.04966 1.35268C7.56137 3.44258 6.68896 4.83021 5.76502 6.54323L5.66246 6.73161L5.6452 6.80024C5.55977 6.97171 5.57678 7.15996 5.69661 7.31414C6.24394 8.17059 9.3062 9.18135 16.1319 9.18135C21.2298 9.18135 26.6527 8.35909 26.6527 6.81752C26.6527 5.36126 21.3153 4.48777 16.1319 4.48777C14.4039 4.48777 12.7104 4.71047 12.2314 4.79614C12.6933 4.07658 14.6435 1.45538 14.6435 1.43847C14.6776 1.40428 14.6776 1.40428 14.6776 1.38712V1.35292C14.6435 1.33576 14.6263 1.31861 14.5922 1.31861L9.10107 1.31836ZM11.0854 6.8174C11.0854 6.50904 13.0186 6.06365 16.132 6.06365C19.2454 6.06365 21.1616 6.50904 21.1616 6.8174C21.1616 7.15983 19.2455 7.58818 16.132 7.58818C13.0185 7.58831 11.0854 7.16008 11.0854 6.8174Z"
        fill={hex}
      />
      <path
        d="M12.9687 14.5094C13.8412 14.5266 38.39 15.3829 38.9375 6.66346C39.3821 -0.548737 21.317 0.136621 21.317 0.136621C21.283 0.136621 21.1631 0.136621 21.1631 0.222167C21.1631 0.324996 21.2144 0.342278 21.283 0.342278C26.2952 0.342278 34.4384 2.34639 34.1818 6.66346C33.9764 10.1581 27.6126 14.2693 12.9689 14.2693C12.9004 14.2693 12.832 14.3379 12.832 14.3893C12.8319 14.4409 12.8832 14.4922 12.9687 14.5094Z"
        fill={hex}
      />
      <path d="M35.4808 13.8742H35.002V13.7715H36.0966V13.8742H35.652V14.7306H35.4808V13.8742Z" fill={hex} />
      <path
        d="M36.3359 13.7715H36.5411L36.9005 14.5766L37.2769 13.7715H37.482V14.7306H37.3452V13.9427H37.3282L36.9689 14.7306H36.8319L36.4726 13.9427V14.7306H36.3359V13.7715Z"
        fill={hex}
      />
    </svg>
  )
}
