import React from 'react'
import { useTranslate } from '@/utils/translate/translate-client'
import { getHexColor, scaleDimensions } from '../utils'
import { OneColorLogoProps } from './LogoTypes'

export const PrimeVideoLogo: React.FC<OneColorLogoProps> = ({ height = 25, color = 'white', className }) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(height, 47, 14)
  const hex = getHexColor(color)
  const { t } = useTranslate('common')

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={scaledWidth}
      height={scaledHeight}
      viewBox="0 0 49 16"
      fill="none"
      aria-label={t('amazonPrimeVideo', 'Amazon Prime Video')}
    >
      <g clipPath="url(#clip0_807_2535)">
        <path
          d="M24.5176 15.4125V15.3884C24.5414 15.3583 24.5831 15.3403 24.6188 15.3463C24.7914 15.3403 24.9581 15.3403 25.1308 15.3463C25.1665 15.3463 25.2082 15.3583 25.232 15.3884V15.4125H24.5176Z"
          fill="#D1EFFA"
        />
        <path
          d="M25.2335 15.39C24.9953 15.384 24.7572 15.384 24.519 15.39C24.1916 15.372 23.8641 15.3599 23.5367 15.3359C22.6674 15.2697 21.8042 15.1373 20.9587 14.9386C18.0355 14.2525 15.4695 12.8743 13.2309 10.8702C13.0225 10.6836 12.8261 10.491 12.6236 10.2984C12.576 10.2563 12.5343 10.1961 12.5105 10.1359C12.4748 10.0516 12.4927 9.96136 12.5522 9.89516C12.6117 9.82896 12.707 9.80488 12.7903 9.84099C12.8439 9.86507 12.8975 9.88914 12.9451 9.91923C15.0825 11.2553 17.4163 12.2303 19.8633 12.8081C20.6849 13.0007 21.5124 13.1512 22.3459 13.2595C23.5426 13.41 24.7512 13.4641 25.9539 13.422C26.6028 13.4039 27.2458 13.3438 27.8888 13.2595C29.3891 13.0669 30.8716 12.7238 32.3064 12.2424C33.0625 11.9896 33.8008 11.7007 34.5212 11.3637C34.6283 11.3035 34.7593 11.2854 34.8784 11.3155C35.0749 11.3637 35.1939 11.5683 35.1463 11.7669C35.1403 11.791 35.1284 11.8211 35.1165 11.8451C35.0689 11.9354 35.0034 12.0137 34.9201 12.0739C34.2354 12.6155 33.4971 13.091 32.7172 13.4882C31.2467 14.2405 29.6749 14.7762 28.0555 15.0831C27.1208 15.2516 26.1801 15.3539 25.2335 15.39Z"
          fill={hex}
        />
        <path
          d="M16.4155 3.19029C16.5643 3.10001 16.7191 3.00372 16.8799 2.91946C17.2966 2.70279 17.761 2.59446 18.2313 2.61854C18.5707 2.63659 18.8803 2.73289 19.1184 2.98566C19.3447 3.22038 19.428 3.50927 19.4518 3.82223C19.4578 3.88844 19.4578 3.95464 19.4578 4.02686V7.14444C19.4578 7.41527 19.4221 7.45138 19.1542 7.45138H18.4278C18.3802 7.45138 18.3326 7.45138 18.2849 7.44536C18.2135 7.43934 18.1539 7.37916 18.142 7.30694C18.1301 7.24073 18.1301 7.17453 18.1301 7.10833V4.32177C18.1361 4.20742 18.1242 4.09908 18.0944 3.99075C18.0468 3.80418 17.8801 3.67177 17.6896 3.65973C17.3383 3.63566 16.987 3.70788 16.6655 3.85834C16.6179 3.87038 16.5881 3.91853 16.5941 3.96668V7.1324C16.5941 7.19258 16.5941 7.24675 16.5822 7.30694C16.5822 7.39119 16.5167 7.45138 16.4333 7.45138C16.344 7.4574 16.2547 7.4574 16.1595 7.4574H15.5284C15.3081 7.4574 15.2605 7.40323 15.2605 7.18055V4.3338C15.2605 4.23149 15.2545 4.12316 15.2307 4.02084C15.189 3.81621 15.0164 3.67177 14.8139 3.65973C14.4567 3.63566 14.0936 3.70788 13.7721 3.86436C13.7244 3.8764 13.6947 3.93057 13.7066 3.97871V7.18657C13.7066 7.40925 13.6589 7.4574 13.4387 7.4574H12.6409C12.4325 7.4574 12.3789 7.39721 12.3789 7.19258V3.01576C12.3789 2.96761 12.3849 2.91946 12.3968 2.87131C12.4206 2.79909 12.492 2.75696 12.5635 2.75696H13.3077C13.4148 2.75696 13.4803 2.82316 13.5161 2.92548C13.5458 3.00974 13.5637 3.08798 13.5935 3.17825C13.653 3.17825 13.6887 3.13613 13.7304 3.11205C14.0578 2.90742 14.4031 2.73289 14.7901 2.66066C15.0878 2.60048 15.3855 2.60048 15.6832 2.66066C15.963 2.72085 16.2131 2.88937 16.3738 3.13011C16.3857 3.14816 16.3976 3.1602 16.4095 3.17224C16.4036 3.17825 16.4095 3.17825 16.4155 3.19029Z"
          fill={hex}
        />
        <path
          d="M28.7578 6.18532C28.7935 6.06495 28.8293 5.9506 28.865 5.83023C29.1388 4.89737 29.4127 3.97052 29.6866 3.03766L29.7223 2.92932C29.7521 2.82099 29.8533 2.75479 29.9604 2.75479H30.8654C31.0916 2.75479 31.1393 2.82099 31.0619 3.03766L30.7047 3.99459C30.3058 5.04181 29.9069 6.09505 29.508 7.14226C29.4961 7.17837 29.4782 7.21448 29.4663 7.25059C29.4246 7.37698 29.2996 7.46124 29.1686 7.4492C28.9066 7.44319 28.6447 7.44319 28.3827 7.4492C28.1982 7.45522 28.091 7.37096 28.0255 7.20245C27.8767 6.80523 27.7219 6.40199 27.573 6.00477C27.2158 5.05987 26.8526 4.11496 26.4954 3.16404C26.4597 3.09182 26.4359 3.00756 26.418 2.92932C26.4002 2.80895 26.4418 2.74877 26.5609 2.74877C26.9003 2.74275 27.2396 2.74877 27.573 2.74877C27.7159 2.74877 27.7814 2.84506 27.8171 2.97145C27.8826 3.20015 27.9481 3.43488 28.0196 3.66358C28.2637 4.50015 28.5018 5.34274 28.7459 6.17931C28.7399 6.18532 28.7459 6.18532 28.7578 6.18532Z"
          fill={hex}
        />
        <path
          d="M7.6156 3.4159C7.65727 3.40386 7.69299 3.37979 7.71681 3.34368C7.82397 3.23534 7.93709 3.13303 8.05617 3.03673C8.36576 2.79599 8.75274 2.67562 9.13973 2.70572C9.29452 2.71173 9.34811 2.75988 9.36001 2.91034C9.37192 3.11497 9.36597 3.32562 9.36597 3.53025C9.37192 3.61451 9.36597 3.69275 9.35406 3.77701C9.33025 3.88534 9.28857 3.92747 9.1814 3.93951C9.09805 3.94552 9.02066 3.93951 8.93731 3.93349C8.53841 3.89738 8.15142 3.97562 7.77634 4.102C7.69299 4.1321 7.69299 4.19228 7.69299 4.25849V7.14736C7.69299 7.20152 7.69299 7.24967 7.68704 7.30384C7.68109 7.38208 7.62155 7.44226 7.54415 7.44226C7.50248 7.44828 7.45485 7.44828 7.41317 7.44828H6.6392C6.59752 7.44828 6.54989 7.44828 6.50822 7.44226C6.43082 7.43625 6.37128 7.37004 6.36533 7.2918C6.35937 7.24365 6.35938 7.19551 6.35938 7.14736V3.05479C6.35938 2.77794 6.38914 2.74785 6.66301 2.74785H7.23456C7.38936 2.74785 7.4608 2.80201 7.50248 2.95247C7.54415 3.10294 7.57987 3.2534 7.6156 3.4159Z"
          fill={hex}
        />
        <path
          d="M35.4679 9.52148C35.8608 9.53352 36.2478 9.5576 36.6288 9.65991C36.736 9.69 36.8372 9.72611 36.9384 9.77426C37.0754 9.82843 37.1647 9.96083 37.1825 10.1053C37.2063 10.2738 37.2123 10.4483 37.2004 10.6229C37.123 11.652 36.8074 12.6451 36.2835 13.5298C36.093 13.8488 35.8608 14.1377 35.5929 14.3904C35.5393 14.4446 35.4738 14.4867 35.4024 14.5108C35.2893 14.5409 35.2178 14.4807 35.2119 14.3664C35.2178 14.3062 35.2297 14.246 35.2535 14.1858C35.4619 13.6201 35.6644 13.0604 35.8251 12.4766C35.9204 12.1576 35.9858 11.8326 36.0275 11.5016C36.0394 11.3812 36.0454 11.2608 36.0335 11.1405C36.0275 10.9358 35.8965 10.7613 35.7001 10.7011C35.5155 10.6409 35.325 10.6048 35.1285 10.5928C34.5808 10.5687 34.0331 10.5928 33.4913 10.665L32.7709 10.7553C32.6935 10.7613 32.622 10.7553 32.5804 10.6831C32.5387 10.6108 32.5565 10.5386 32.5982 10.4664C32.6459 10.4002 32.7054 10.34 32.7768 10.2979C33.2174 9.97889 33.7116 9.7863 34.2355 9.65991C34.6403 9.57565 35.0511 9.53352 35.4679 9.52148Z"
          fill={hex}
        />
        <path
          d="M32.9723 5.10307V7.26972C32.9604 7.39009 32.9068 7.44426 32.7877 7.45028C32.4662 7.4563 32.1507 7.4563 31.8292 7.45028C31.7101 7.45028 31.6565 7.39009 31.6446 7.27574C31.6387 7.23963 31.6387 7.1975 31.6387 7.16139V2.9966C31.6446 2.81003 31.6923 2.75586 31.8768 2.75586H32.7341C32.9187 2.75586 32.9723 2.81003 32.9723 2.9966V5.10307Z"
          fill={hex}
        />
        <path
          d="M9.93759 5.0918V2.95524C9.94355 2.81079 9.99713 2.75663 10.14 2.75061C10.4496 2.74459 10.7592 2.74459 11.0688 2.75061C11.2057 2.75061 11.2474 2.79274 11.2593 2.93116C11.2653 2.98533 11.2653 3.03348 11.2653 3.08764V7.09595C11.2653 7.16216 11.2593 7.22836 11.2534 7.29456C11.2474 7.3728 11.1879 7.42697 11.1105 7.43299C11.0747 7.43901 11.045 7.43901 11.0093 7.43901H10.1817C10.1519 7.43901 10.1281 7.43901 10.0983 7.43299C10.015 7.42697 9.94355 7.36077 9.93759 7.27651C9.93164 7.22836 9.93164 7.18021 9.93164 7.13206C9.93759 6.46401 9.93759 5.77791 9.93759 5.0918Z"
          fill={hex}
        />
        <path
          d="M10.6293 0.59295C10.7246 0.586932 10.8199 0.604987 10.9092 0.635079C11.2307 0.743412 11.3974 1.02628 11.3676 1.39341C11.3438 1.70637 11.1116 1.95915 10.802 2.00729C10.671 2.03137 10.5341 2.03137 10.4031 2.00729C10.0637 1.94109 9.81369 1.68831 9.83751 1.25498C9.87323 0.827671 10.153 0.59295 10.6293 0.59295Z"
          fill={hex}
        />
        <path
          d="M32.3112 0.594864C32.4303 0.582827 32.5494 0.606901 32.6625 0.655049C32.8947 0.745326 33.0554 0.961992 33.0674 1.21477C33.115 1.76245 32.7518 2.0393 32.2696 2.02726C32.2041 2.02726 32.1386 2.01523 32.0731 2.00319C31.704 1.91291 31.5135 1.62403 31.5492 1.20875C31.5789 0.877733 31.835 0.630975 32.1862 0.600883C32.2279 0.594864 32.2696 0.588846 32.3112 0.594864Z"
          fill={hex}
        />
        <path
          d="M5.47856 4.59595C5.45474 4.28299 5.37139 3.97605 5.24637 3.69318C5.00227 3.17559 4.62719 2.79643 4.05564 2.66402C3.40074 2.51958 2.81132 2.66402 2.2755 3.06726C2.23977 3.10337 2.1981 3.13346 2.15047 3.15754C2.13856 3.15152 2.12665 3.1455 2.12665 3.13948C2.10879 3.0793 2.09689 3.01911 2.07902 2.95893C2.0314 2.80846 1.97186 2.7543 1.81111 2.7543C1.6325 2.7543 1.44794 2.76032 1.26933 2.7543C1.13239 2.74828 1.00737 2.76634 0.912109 2.87467C0.912109 4.98114 0.912109 7.09363 0.918063 9.19408C0.99546 9.32047 1.11453 9.34454 1.25147 9.33852C1.4658 9.3325 1.68013 9.33852 1.89446 9.33852C2.26954 9.33852 2.26954 9.33852 2.26954 8.96537V7.25011C2.26954 7.20798 2.25168 7.15983 2.29336 7.12372C2.59104 7.35844 2.95421 7.50288 3.32929 7.53899C3.85321 7.59316 4.3295 7.46075 4.7284 7.09964C5.02013 6.82881 5.23446 6.47974 5.34758 6.09456C5.50833 5.60104 5.52023 5.10151 5.47856 4.59595ZM4.05564 5.84178C4.01396 6.02835 3.9187 6.19687 3.78177 6.32326C3.62697 6.45567 3.43646 6.53391 3.23403 6.53391C2.9304 6.55196 2.63271 6.48576 2.3648 6.34132C2.29931 6.31122 2.25763 6.24502 2.26359 6.1728V5.08345C2.26359 4.72234 2.26954 4.36123 2.26359 4.00012C2.25763 3.91587 2.30526 3.84364 2.38266 3.81355C2.71011 3.65707 3.04947 3.58485 3.40669 3.65707C3.65674 3.69318 3.87107 3.85568 3.97229 4.0904C4.06159 4.28299 4.11517 4.49364 4.12708 4.70429C4.1628 5.08947 4.1628 5.47465 4.05564 5.84178Z"
          fill={hex}
        />
        <path
          d="M48.5534 4.72928V4.75335C48.5296 4.72928 48.5176 4.69317 48.5296 4.66308V4.61493C48.5296 4.61493 48.5296 4.60891 48.5355 4.60891H48.5296V4.54873H48.5415C48.5415 4.54271 48.5355 4.54271 48.5355 4.53669C48.5236 4.42234 48.4998 4.30799 48.47 4.19965C48.2497 3.40521 47.7556 2.88161 46.9518 2.67698C46.5768 2.5867 46.1957 2.57466 45.8147 2.63485C45.0109 2.75522 44.4334 3.18855 44.1536 3.95891C43.8798 4.69317 43.8857 5.49965 44.1596 6.2339C44.3977 6.90195 44.874 7.32325 45.5587 7.49778C45.9219 7.58806 46.3029 7.61214 46.678 7.55797C47.9282 7.40751 48.4462 6.45057 48.5296 5.62002H48.5236V5.53576C48.5176 5.49965 48.5117 5.46955 48.5474 5.44548V5.45752C48.5474 5.4515 48.5534 5.43946 48.5593 5.43344V4.74132C48.5593 4.7353 48.5534 4.7353 48.5534 4.72928ZM47.1245 5.87279C47.0888 5.99918 47.0352 6.11353 46.9578 6.22186C46.8268 6.40844 46.6184 6.52881 46.3922 6.54686C46.2791 6.5589 46.166 6.5589 46.0528 6.53483C45.8028 6.48668 45.5944 6.31816 45.4932 6.08344C45.4039 5.89687 45.3503 5.69224 45.3324 5.48761C45.3027 5.13252 45.2967 4.77743 45.3801 4.42836C45.4098 4.28993 45.4694 4.15151 45.5408 4.03114C45.6718 3.81447 45.898 3.67605 46.1481 3.65799C46.2612 3.64595 46.3743 3.64595 46.4875 3.67003C46.7256 3.71817 46.9221 3.87466 47.0292 4.09734C47.1304 4.30799 47.19 4.54271 47.2019 4.77743C47.2078 4.88576 47.2138 4.99409 47.2078 5.10243C47.2257 5.36724 47.1959 5.62603 47.1245 5.87279Z"
          fill={hex}
        />
        <path
          d="M38.1167 0.636719H37.2892C37.0629 0.636719 37.0213 0.678848 37.0213 0.907551V2.85754C37.0213 2.89967 37.0391 2.9418 37.0094 2.98393C36.9558 2.97791 36.926 2.9418 36.8843 2.91772C36.2651 2.5506 35.6162 2.48439 34.9613 2.79134C34.5029 3.008 34.2171 3.3992 34.0325 3.86263C33.8539 4.30799 33.8122 4.77744 33.8241 5.2529C33.8241 5.69826 33.9254 6.13761 34.1218 6.53483C34.3481 6.97418 34.6755 7.31122 35.1518 7.45566C35.8008 7.66029 36.408 7.55798 36.9617 7.1427C37.0034 7.11863 37.0272 7.0765 37.0808 7.06446C37.1106 7.13066 37.1344 7.20289 37.1463 7.27511C37.1701 7.3714 37.2535 7.43761 37.3547 7.43761H37.4976C37.7119 7.43761 37.9203 7.44363 38.1286 7.43761C38.2953 7.43761 38.343 7.38344 38.3489 7.2089V0.865421C38.343 0.678848 38.2953 0.636719 38.1167 0.636719ZM37.0272 5.06632V6.16169C37.0391 6.23391 36.9974 6.30011 36.932 6.33021C36.6462 6.4927 36.3187 6.55891 35.9972 6.51076C35.7234 6.48067 35.4852 6.31215 35.3602 6.06539C35.2649 5.8728 35.2113 5.66817 35.1935 5.45753C35.1459 5.07836 35.1756 4.69318 35.2649 4.32605C35.2947 4.22374 35.3304 4.12744 35.384 4.03114C35.509 3.79642 35.7472 3.64596 36.0091 3.62791C36.3247 3.59781 36.6402 3.658 36.926 3.79041C36.9974 3.81448 37.0391 3.8867 37.0332 3.96494C37.0213 4.33809 37.0272 4.6992 37.0272 5.06632Z"
          fill={hex}
        />
        <path
          d="M21.6307 5.48236C22.0773 5.56661 22.5357 5.57263 22.9822 5.50041C23.2442 5.4643 23.4942 5.38606 23.7264 5.25967C23.9943 5.10319 24.1908 4.88653 24.2742 4.5856C24.4825 3.82727 24.161 3.06292 23.3811 2.78006C23.0001 2.65367 22.5952 2.61154 22.1963 2.6657C21.2557 2.77404 20.6424 3.29765 20.3626 4.20644C20.1661 4.82634 20.1899 5.45828 20.3507 6.0842C20.5591 6.88466 21.083 7.36012 21.8748 7.52864C22.3273 7.63096 22.7857 7.6129 23.2382 7.54068C23.4764 7.49855 23.7145 7.43836 23.9407 7.34809C24.0777 7.29392 24.1491 7.20966 24.1432 7.05318C24.1372 6.90874 24.1432 6.75827 24.1432 6.60781C24.1432 6.42726 24.0717 6.37309 23.8991 6.41522C23.7264 6.45735 23.5597 6.49346 23.3871 6.52957C23.0179 6.60781 22.6369 6.60781 22.2678 6.54161C21.7617 6.43929 21.4343 5.99995 21.464 5.45828C21.5176 5.4643 21.5771 5.47032 21.6307 5.48236ZM21.4819 4.56153C21.4998 4.41708 21.5414 4.27866 21.595 4.14625C21.7736 3.7069 22.1487 3.55644 22.5297 3.58051C22.6369 3.58653 22.7441 3.61061 22.8453 3.65274C23.0001 3.71894 23.1013 3.86338 23.1191 4.0319C23.137 4.13422 23.1311 4.24255 23.1013 4.34486C23.0298 4.56153 22.8572 4.6518 22.6488 4.69393C22.5238 4.72403 22.3928 4.73606 22.2618 4.72403C22.0296 4.72403 21.7915 4.70597 21.5593 4.66986C21.47 4.65782 21.47 4.65782 21.4819 4.56153Z"
          fill={hex}
        />
        <path
          d="M41.713 5.54346C42.0584 5.51939 42.4037 5.45318 42.7133 5.28467C43.0288 5.12819 43.2491 4.83328 43.3146 4.48421C43.3563 4.26754 43.3563 4.03884 43.3086 3.82218C43.1836 3.28051 42.8442 2.94348 42.3322 2.76894C42.0465 2.67866 41.7428 2.64255 41.4451 2.65459C40.4449 2.67866 39.6829 3.19023 39.3733 4.20134C39.1649 4.86939 39.1947 5.54948 39.3971 6.21753C39.6055 6.90364 40.0817 7.31892 40.7605 7.50549C41.0522 7.57771 41.3558 7.6078 41.6535 7.59577C42.0881 7.58975 42.5227 7.50549 42.9335 7.34901C43.1062 7.2828 43.1479 7.22262 43.1479 7.03605V6.60272C43.1419 6.42818 43.0705 6.36799 42.8978 6.41012C42.7668 6.44623 42.6418 6.47633 42.5108 6.50642C42.1119 6.60272 41.6952 6.62077 41.2903 6.54855C40.8855 6.47031 40.6116 6.23559 40.5045 5.82633C40.4747 5.70596 40.4509 5.58559 40.439 5.4592C40.4687 5.4592 40.4985 5.4592 40.5223 5.47124C40.9153 5.54346 41.3142 5.57355 41.713 5.54346ZM40.4687 4.5805C40.5104 4.34578 40.564 4.11708 40.7069 3.92449C40.9272 3.62958 41.2308 3.54532 41.5761 3.58144C41.6059 3.58144 41.6297 3.59347 41.6595 3.59347C42.0762 3.65968 42.1774 3.99671 42.1 4.32171C42.0405 4.56245 41.844 4.65273 41.6237 4.69486C41.5047 4.71893 41.3796 4.73097 41.2546 4.72495C41.0105 4.71893 40.7724 4.70087 40.5342 4.66476C40.4806 4.65874 40.4568 4.63467 40.4687 4.5805Z"
          fill={hex}
        />
      </g>
      <defs>
        <clipPath id="clip0_807_2535">
          <rect width="47.6471" height="14.8235" fill={hex} transform="translate(0.912109 0.587891)" />
        </clipPath>
      </defs>
    </svg>
  )
}
