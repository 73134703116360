import { LinkButton, LinkButtonProps } from '@/atoms/Button'
import { AsSpan, TitleXS } from '@/atoms/Text'
import { paths } from '@/constants'
import { GetTheatricalPreReleasePageDataResult } from '@/services/TheatricalPreReleaseService/TheatricalPreReleaseService'
import { Project } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'
import { chooseCtas, ChooseCtaInput } from './rules'

export type HeroCta = GetTheatricalPreReleasePageDataResult['heroCta']
export type CtasProps = ChooseCtaInput &
  Pick<LinkButtonProps, 'className' | 'aria-hidden'> & {
    heroCta?: HeroCta
    isGuildMember?: boolean
    numberAvailable?: number
    canShowTickets?: boolean
  }
export function Ctas(props: CtasProps) {
  if (props?.isGuildMember && props.canShowTickets && (props?.numberAvailable ?? 0) > 0)
    return <GuildClaimCta {...props} isSecondary={false} />
  if (props.heroCta) return <HeroCta {...props} isSecondary={false} isGuildMember={props.isGuildMember} />
  if (!isReadyToRender(props)) return <Placeholder {...props} />

  return (
    <div className="inline-flex flex-col gap-4 whitespace-nowrap text-center sm:flex-row">
      {chooseCtas(props).map((code, i) => {
        const ApplicableCta = CTAS[code]
        return <ApplicableCta {...props} key={code} isSecondary={i > 0} />
      })}
    </div>
  )
}

/**
 * Placeholder avoids content shift after data is ready to show real deal.
 */
function Placeholder(props: CtasProps) {
  return (
    <CtaLinkButton
      {...props}
      isSecondary={false}
      href="#"
      className={`invisible ${props.className ? props.className : ''}`}
      aria-hidden
    >
      Loading...
    </CtaLinkButton>
  )
}

/**
 * In practice, at runtime, the userRegionCode is populated with an
 * async call. Before it's available, we're flashing different Cta states.
 * This avoids that.
 */
function isReadyToRender(props: CtasProps) {
  return !!props.userRegionCode
}

const CTAS: { [code: string]: (props: ApplicableCtasProps) => React.ReactElement } = {
  theater: TheaterCta,
  stream: StreamCta,
  pif: PifCta,
  updates: UpdatesCta,
  joinGuild: JoinGuildCta,
}

type ApplicableCtasProps = CtasProps & {
  /**
   * In the case of 2 CTAs, this is the second, non-primary. Controls styling.
   */
  isSecondary: boolean
}
function TheaterCta(props: ApplicableCtasProps): React.ReactElement {
  const { t } = useTranslate('theatrical-presales')
  const href = formatUtm(`${paths.tickets.index}${props.project ? '/' + props.project.slug : ''}`, props.project)
  return (
    <CtaLinkButton {...props} href={href}>
      {t('getShowtimes', 'Get Showtimes')}
    </CtaLinkButton>
  )
}

function StreamCta(props: ApplicableCtasProps): React.ReactElement {
  const { t } = useTranslate('theatrical-presales')
  const href = formatUtm(`${paths.watch.index}${props.project ? '/' + props.project.slug : ''}`, props.project)
  return (
    <CtaLinkButton {...props} href={href}>
      {t('watchOnAngel', 'Watch on Angel')}
    </CtaLinkButton>
  )
}

function JoinGuildCta(props: ApplicableCtasProps): React.ReactElement {
  const { t } = useTranslate('theatrical-presales')
  const href = formatUtm(`${paths.guild.join}${props.project ? '/' + props.project.slug : ''}`, props.project)
  return (
    <CtaLinkButton {...props} href={href}>
      {t('joinTheGuildToWatch', 'Join the Guild to Watch')}
    </CtaLinkButton>
  )
}

function PifCta(props: ApplicableCtasProps): React.ReactElement {
  const { t } = useTranslate('theatrical-presales')
  const href = formatUtm(`${paths.payItForward.index}${props.project ? '/' + props.project.slug : ''}`, props.project)
  return (
    <CtaLinkButton {...props} href={href}>
      {t('payItForward', 'Pay it Forward')}
    </CtaLinkButton>
  )
}

function UpdatesCta(props: ApplicableCtasProps): React.ReactElement {
  const { t } = useTranslate('theatrical-presales')
  return (
    <CtaLinkButton {...props} href="#signup-cta">
      {t('getUpdates', 'Get Updates')}
    </CtaLinkButton>
  )
}

function HeroCta(props: ApplicableCtasProps): React.ReactElement {
  const href =
    props.isGuildMember && props.heroCta?.guildButtonHref ? props.heroCta.guildButtonHref : props.heroCta?.buttonHref
  const buttonText =
    props.isGuildMember && props.heroCta?.guildButtonText ? props.heroCta.guildButtonText : props.heroCta?.buttonText

  return (
    <CtaLinkButton {...props} href={href as string}>
      {buttonText}
    </CtaLinkButton>
  )
}

function GuildClaimCta(props: ApplicableCtasProps): React.ReactElement {
  const { t } = useTranslate('theatrical-presales')
  const href = formatUtm(`${paths.tickets.index}${props.project ? '/' + props.project.slug : ''}`, props.project)
  const numberAvailable = props.numberAvailable ?? 0
  const withPluralization = t('claimGuildTickets', {
    count: props.numberAvailable,
    numberAvailable,
    defaultValue_one: 'Claim 1 Guild Ticket',
    defaultValue_many: 'Claim {{numberAvailable}} Guild Tickets',
    defaultValue_other: 'Claim {{numberAvailable}} Guild Tickets',
  })

  return (
    <div className="flex flex-col gap-4 lg:flex-row">
      <CtaLinkButton {...props} href={href} className="px-6 py-[14px] lg:min-w-[235px] lg:px-7 lg:py-[17px] ">
        {withPluralization}
      </CtaLinkButton>
      <CtaLinkButton
        {...props}
        href={'#video-gallery'}
        className="px-6 py-[14px] lg:min-w-[235px] lg:px-7 lg:py-[17px]"
      >
        {t('watchTrailerTheatrical', 'Watch Trailer')}
      </CtaLinkButton>
    </div>
  )
}

// TODO: will these UTM values be true for all projects? Verify in contentful project.heroCta
function formatUtm(url: string, project?: Pick<Project, 'slug'>) {
  const hasQuery = /\?/.test(url)
  const querySeparator = hasQuery ? '&' : '?'
  const COMMON_UTM = 'utm_source=hub-hero&utm_medium=web'

  return project
    ? `${url}${querySeparator}utm_campaign=${project.slug}&${COMMON_UTM}`
    : `${url}${querySeparator}${COMMON_UTM}`
}

function CtaLinkButton(props: ApplicableCtasProps & { href: string; children: React.ReactNode }) {
  return (
    <LinkButton
      href={props.href}
      variant={props.isSecondary ? 'gray-900' : 'white'}
      className={props.className ? props.className : 'px-16 py-3'}
      aria-hidden={props['aria-hidden']}
    >
      <TitleXS as={AsSpan} weight="bold">
        {props.children}
      </TitleXS>
    </LinkButton>
  )
}
