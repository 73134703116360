import React, { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { When } from 'react-if'
import { Image } from '@/atoms/Image'
import { AmazonPrimeVideoLogo } from '@/atoms/Logos/AmazonPrimeVideoLogo'
import { AngelOnlyLogo } from '@/atoms/Logos/AngelOnlyLogo'
import { AppleStoreLogo } from '@/atoms/Logos/AppleStoreLogo'
import { AppleTvLogo } from '@/atoms/Logos/AppleTvLogo'
import { BluRayDiscLogo } from '@/atoms/Logos/BluRayDiscLogo'
import { DVDVideoLogo } from '@/atoms/Logos/DVDVideoLogo'
import { FandangoAtHomeLogo } from '@/atoms/Logos/FandangoAtHomeLogo'
import { FireTVLogo } from '@/atoms/Logos/FireTVLogo'
import { GoogleStoreDownloadBWLogo } from '@/atoms/Logos/GoogleStoreDownloadBWLogo'
import { MicrosoftLogo } from '@/atoms/Logos/MicrosoftLogo'
import { PrimeVideoLogo } from '@/atoms/Logos/PrimeVideoLogo'
import { RedboxLogo } from '@/atoms/Logos/RedboxLogo'
import { RokuLogo } from '@/atoms/Logos/RokuLogo'
import { SamsungLogo } from '@/atoms/Logos/SamsungLogo'
import { VuduLogo } from '@/atoms/Logos/VuduLogo'
import { YouTubeLogo } from '@/atoms/Logos/YouTubeLogo'
import { AsH2, AsH3, HeadingSM, TitleSM } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { WhereToWatch } from '@/services/WhereToWatchService'
import { ScreenType, useScreenType } from '@/utils/ScreenUtils'
import { useSafeTrack } from '@/utils/analytics'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface Props {
  projectName: string
  projectSlug: string
  whereToWatch: WhereToWatch
}

const UTM_PARAMS = '?utm_campaign=dvd-sales&utm_source=hub-where-to-watch&utm_medium=web'
export const WhereToWatchSection: FC<Props> = ({ projectName, projectSlug, whereToWatch }) => {
  const { t } = useTranslate('theatrical-presales')
  const router = useRouter()
  const hash = (router.asPath as string).split('#')[1]
  const hashLink = 'where-to-watch'

  const screenType = useScreenType()

  const track = useSafeTrack()

  const trackTVOD = (partner: string) => {
    track('TVOD Add To Cart', {
      eventName: 'TVOD Add To Cart',
      projectSlug,
      partner,
      category: 'where-to-watch',
    })
  }

  const trackDVDBluRay = (category: string) => {
    track('TVOD Add To Cart', {
      eventName: 'TVOD Add To Cart',
      projectSlug,
      partner: 'shop.angel.com',
      category: `where-to-watch-${category}`,
    })
  }

  let streamLogoHeight: number
  if (screenType === ScreenType.mobile || screenType === ScreenType.mobileplus) {
    streamLogoHeight = 18
  } else {
    streamLogoHeight = screenType === ScreenType.tablet ? 25 : 34
  }

  const deviceLogoHeight = screenType === ScreenType.mobile || screenType === ScreenType.mobileplus ? 24 : 40
  const mediaLogoHeight = screenType === ScreenType.mobile || screenType === ScreenType.mobileplus ? 24 : 50

  useEffect(() => {
    if (hash === hashLink) {
      const destination = document.getElementById(hashLink)
      if (destination) {
        setTimeout(() => destination.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }), 500)
      }
    }
  }, [hash, hashLink])

  return (
    <section
      id={hashLink}
      aria-labelledby="where-to-watch-title"
      className="flex w-full scroll-mt-24 flex-col items-center gap-y-16 overflow-x-hidden bg-core-gray-950 px-6 py-16"
    >
      <div className="flex flex-col items-center gap-y-8">
        <div className="flex max-w-[300px] flex-col items-center gap-x-2 md:max-w-[460px] lg:max-w-max lg:flex-row">
          <HeadingSM
            id="where-to-watch-title"
            as={AsH2}
            className="md:photon-heading-md text-center"
            weight="bold"
            color="white"
          >
            <Translate t={t} i18nKey="whereToWatchTheShowv2" values={{ projectName }}>
              Where to Watch {{ projectName }}
            </Translate>
          </HeadingSM>
        </div>

        <TitleSM
          as={AsH3}
          weight="semibold"
          className="md:photon-title-md max-w-[262px] text-center md:max-w-max"
          color="white"
        >
          {whereToWatch?.enableStreamOnAmazonPrime
            ? t('watchOnYourFavoriteService', 'Watch on your favorite streaming service')
            : t('joinGuildToWatch', 'Join the Guild to Watch')}
        </TitleSM>
        <div className="flex items-center justify-center gap-x-4 md:gap-x-16">
          <a
            href={whereToWatch?.internalLinkOverride ?? `${paths.watch.index}/${projectSlug}`}
            onClick={() => trackTVOD('angel-studios')}
            className="transition-all duration-300 ease-in-out hover:scale-110"
          >
            <AngelOnlyLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
          </a>
          <When condition={whereToWatch?.enableStreamOnAmazonPrime}>
            <a
              target="_blank"
              href={whereToWatch?.streamOnAmazonPrimeLink || ''}
              onClick={() => trackTVOD('prime-video')}
              className="transition-all duration-300 ease-in-out hover:scale-110"
            >
              <PrimeVideoLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
            </a>
          </When>
        </div>
      </div>
      <When
        condition={
          whereToWatch?.enableAmazonPrime ||
          whereToWatch?.enableAppleTv ||
          whereToWatch?.enableGooglePlay ||
          whereToWatch?.enableRedbox ||
          whereToWatch?.enableVudu ||
          whereToWatch?.enableYoutube ||
          whereToWatch?.enableFandango
        }
      >
        <div className="flex flex-col items-center gap-y-8">
          <TitleSM as={AsH3} className="max-w-[262px] text-center md:max-w-max" weight="semibold" color="white">
            {t('purchaseOrRentFromThePlatforms', 'Purchase or rent from these platforms')}
          </TitleSM>
          <div className="flex flex-col items-center justify-center gap-x-4 gap-y-8 md:gap-x-16 xl:flex-row">
            <div className="flex items-center justify-center gap-x-2 sm:gap-x-4 md:gap-x-16">
              <When condition={whereToWatch?.enableAmazonPrime}>
                <a
                  target="_blank"
                  href={whereToWatch?.amazonPrimeLink || ''}
                  onClick={() => trackTVOD('amazon-prime')}
                  className="transition-all duration-300 ease-in-out hover:scale-110"
                >
                  <AmazonPrimeVideoLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
                </a>
              </When>
              <When condition={whereToWatch?.enableAppleTv}>
                <a
                  target="_blank"
                  href={whereToWatch?.appleTvLink || ''}
                  onClick={() => trackTVOD('apple-tv')}
                  className="transition-all duration-300 ease-in-out hover:scale-110"
                >
                  <AppleTvLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
                </a>
              </When>
              <When condition={whereToWatch?.enableRedbox}>
                <a
                  target="_blank"
                  href={whereToWatch?.redboxLink || ''}
                  onClick={() => trackTVOD('redbox')}
                  className="transition-all duration-300 ease-in-out hover:scale-110"
                >
                  <RedboxLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
                </a>
              </When>
              <When condition={whereToWatch?.enableGooglePlay}>
                <a
                  target="_blank"
                  href={whereToWatch?.googlePlayLink || ''}
                  onClick={() => trackTVOD('google-play')}
                  className="transition-all duration-300 ease-in-out hover:scale-110"
                >
                  <Image
                    alt="Google Play"
                    src="https://images.angelstudios.com/image/upload/v1707168136/google-play-logo-white_4x.webp"
                    className="min-w-[84px] shrink-0"
                    height={streamLogoHeight}
                    width={streamLogoHeight * 3.5}
                    aria-label={t('googlePlay', 'Google Play')}
                  />
                </a>
              </When>
            </div>
            <div className="flex items-center justify-center gap-x-2 sm:gap-x-4 md:gap-x-16">
              <When condition={whereToWatch?.enableYoutube}>
                <a
                  target="_blank"
                  href={whereToWatch?.youtubeLink || ''}
                  onClick={() => trackTVOD('youtube')}
                  className="transition-all duration-300 ease-in-out hover:scale-110"
                >
                  <YouTubeLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
                </a>
              </When>
              <When condition={whereToWatch?.enableVudu}>
                <a
                  target="_blank"
                  href={whereToWatch?.vuduLink || ''}
                  onClick={() => trackTVOD('vudu')}
                  className="transition-all duration-300 ease-in-out hover:scale-110"
                >
                  <VuduLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
                </a>
              </When>
              <When condition={whereToWatch?.enableMicrosoft}>
                <a
                  target="_blank"
                  href={whereToWatch?.microsoftLink || ''}
                  onClick={() => trackTVOD('microsoft')}
                  className="transition-all duration-300 ease-in-out hover:scale-110"
                >
                  <MicrosoftLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
                </a>
              </When>
              <When condition={whereToWatch?.enableFandango}>
                <a
                  target="_blank"
                  href={whereToWatch?.fandangoLink || ''}
                  onClick={() => trackTVOD('fandango')}
                  className="transition-all duration-300 ease-in-out hover:scale-110"
                >
                  <FandangoAtHomeLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
                </a>
              </When>
            </div>
          </div>
        </div>
      </When>
      <div className="flex flex-col items-center gap-y-8">
        <TitleSM as={AsH3} className="max-w-[262px] text-center md:max-w-max" weight="semibold" color="white">
          {t('downloadAngelStudiosApps', 'Download Angel Studios on your phone or TV')}
        </TitleSM>
        <div className="flex flex-col items-center justify-center gap-x-4 gap-y-8 md:gap-x-16 lg:flex-row">
          <div className="flex items-center justify-center gap-x-4 md:gap-x-16">
            <a
              target="_blank"
              className="min-w-[84px] shrink-0 transition-all duration-300 ease-in-out hover:scale-110"
              href={`https://apps.apple.com/us/app/angel-studios/id1473663873?utm_campaign=sound-of-freedom&utm_source=hub-where-to-watch&utm_medium=web`}
            >
              <AppleStoreLogo className="min-w-[84px]" height={deviceLogoHeight} />
            </a>
            <a
              target="_blank"
              className="min-w-[84px] shrink-0 transition-all duration-300 ease-in-out hover:scale-110"
              href={`https://play.google.com/store/apps/details?id=com.vidangel.thechosen&hl=en_US&gl=US&utm_medium=angel-web&utm_source=hub-chosen-s4`}
            >
              <GoogleStoreDownloadBWLogo className="min-w-[84px] shrink-0" height={deviceLogoHeight} />
            </a>
            <a
              target="_blank"
              className="min-w-[84px] shrink-0 transition-all duration-300 ease-in-out hover:scale-110"
              href={`https://channelstore.roku.com/details/c0742f20c7f71d5344c6cfe5a5c13e15/angel-studios-stream-the-chosen-the-wingfeather-saga-dry-bar-comedy-and-more?utm_campaign=sound-of-freedom&utm_source=hub-where-to-watch&utm_medium=web`}
            >
              <RokuLogo className="min-w-[84px]" height={deviceLogoHeight} />
            </a>
          </div>
          <div className="flex items-center justify-center gap-x-4 md:gap-x-16">
            <a
              target="_blank"
              className="min-w-[84px] shrink-0 transition-all duration-300 ease-in-out hover:scale-110"
              href={`https://www.amazon.com/Angel-Studios-Stream-crowdfunded-shows/dp/B09RQWSR88?utm_campaign=sound-of-freedom&utm_source=hub-where-to-watch&utm_medium=web`}
            >
              <FireTVLogo className="min-w-[84px]" height={deviceLogoHeight} />
            </a>
            <a
              target="_blank"
              className="min-w-[84px] shrink-0 transition-all duration-300 ease-in-out hover:scale-110"
              href={`https://www.samsung.com/us/tvs/smart-tv/samsung-tv-plus-and-smart-hub?utm_campaign=sound-of-freedom&utm_source=hub-where-to-watch&utm_medium=web`}
            >
              <SamsungLogo className="min-w-[84px]" height={deviceLogoHeight} />
            </a>
          </div>
        </div>
      </div>
      <When condition={whereToWatch?.enableBluray || whereToWatch?.enableDvd}>
        <div className="flex flex-col items-center gap-y-8">
          <TitleSM as={AsH3} weight="semibold" className="max-w-[262px] text-center" color="white">
            {t('buyNowOnBlurayAndDvd', 'Buy now on Blu-ray and DVD')}
          </TitleSM>
          <div className="flex items-center justify-center gap-x-16">
            <When condition={whereToWatch?.enableDvd}>
              <a
                target="_blank"
                className="shrink-0 transition-all duration-300 ease-in-out hover:scale-110 md:min-w-[138px]"
                onClick={() => trackDVDBluRay('dvd')}
                href={
                  whereToWatch?.dvdLink
                    ? `${whereToWatch?.dvdLink}${UTM_PARAMS}`
                    : `https://shop.angel.com/collections/dvd-blu-ray${UTM_PARAMS}`
                }
              >
                <DVDVideoLogo height={mediaLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableBluray}>
              <a
                target="_blank"
                className="shrink-0 transition-all duration-300 ease-in-out hover:scale-110 md:min-w-[138px]"
                onClick={() => trackDVDBluRay('bluray')}
                href={
                  whereToWatch?.blurayLink
                    ? `${whereToWatch?.blurayLink}${UTM_PARAMS}`
                    : `https://shop.angel.com/collections/dvd-blu-ray${UTM_PARAMS}`
                }
              >
                <BluRayDiscLogo height={mediaLogoHeight} />
              </a>
            </When>
          </div>
        </div>
      </When>
    </section>
  )
}
