import { Property } from 'csstype'
import { AsH1, TitleLG } from '@/atoms/Text'
import { Translate, TranslateFunction, useTranslate } from '@/utils/translate/translate-client'
import { ChooseCtaInput, chooseCtas, CtaCode } from '.'
import { useWatchTheatricalContext } from '../WatchTheatricalContext'

type CtaStatusLineProps = {
  friendlyTheatricalReleaseDate: ReturnType<typeof useWatchTheatricalContext>['friendlyTheatricalReleaseDate']
  heroCta?: ReturnType<typeof useWatchTheatricalContext>['heroCta']
  isInTheaters: boolean
  projectName: string
} & ChooseCtaInput
export function CtaStatusLine(props: CtaStatusLineProps) {
  const { t } = useTranslate('theatrical-presales')

  const declaration = mapDeclaration(t, props, chooseCtas(props))

  if (!declaration) return null

  return (
    <TitleLG id="cta-status-line" as={AsH1} className="min-h-[26px] px-2 text-center" color="white" weight="bold">
      <span className="sr-only">{props.projectName} </span>
      {declaration}
    </TitleLG>
  )
}

export function mapDeclaration(t: TranslateFunction, props: CtaStatusLineProps, codes: CtaCode[]) {
  if (props.heroCta?.description) return props.heroCta.description
  if (codes.includes('stream')) return t('nowStreaming', 'Now streaming')
  if (codes.includes('theater')) {
    return <InTheatersDeclaration {...props} />
  }
}

function InTheatersDeclaration(props: CtaStatusLineProps) {
  const { t } = useTranslate('theatrical-presales')
  const isPastReleaseDate = new Date() > new Date(props.filmDetails?.theatricalReleaseDate as string)
  return isPastReleaseDate ? (
    <Translate
      i18nKey="inTheatersNow"
      t={t}
      values={{ friendlyTheatricalRelease: props.friendlyTheatricalReleaseDate }}
    >
      IN THEATERS{' '}
      <span className="uppercase" style={{ color: props.filmDetails?.brandColor as Property.Color }}>
        NOW
      </span>
    </Translate>
  ) : (
    <Translate
      i18nKey="inTheatersOnDate"
      t={t}
      values={{ friendlyTheatricalRelease: props.friendlyTheatricalReleaseDate }}
    >
      IN THEATERS{' '}
      <span className="uppercase" style={{ color: props.filmDetails?.brandColor as Property.Color }}>
        {{ friendlyTheatricalReleaseDate: props.friendlyTheatricalReleaseDate }}
      </span>
    </Translate>
  )
}
