import { useState } from 'react'
import { LivestreamThumbnail } from '@/molecules/LivestreamThumbnail'
import { SimpleThumbnail } from '@/molecules/Thumbnail'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { AngelPlayer } from '../AngelPlayer/AngelPlayer'
import { createAsset } from '../AngelPlayer/AssetFactory'

interface Video {
  guid: string
  name: string
  url: string
  bannerCloudinaryPath: string
  description: string
  startAt: string
}

export interface AngelPlayerPreloadedThumbnailProps {
  video: Video | null | undefined
}

export const AngelPlayerPreloadedThumbnail: React.FC<AngelPlayerPreloadedThumbnailProps> = ({ video }) => {
  const [loadPlayer, setLoadPlayer] = useState(false)

  const handleLoadPlayer = () => {
    setLoadPlayer(!loadPlayer)
  }

  return (
    <div className="overflow-hidden rounded-2xl">
      {video?.url ? (
        <>
          {!loadPlayer ? (
            <SimpleThumbnail
              imageUrl={video?.bannerCloudinaryPath || ''}
              onClick={handleLoadPlayer}
              className={'rounded-2xl'}
            />
          ) : (
            <AngelPlayer
              asset={createAsset({
                guid: video?.guid,
                publisherName: 'Angel Studios',
                name: video?.name,
                url: video?.url as string,
                poster: getCloudinaryImageUrl({
                  path: `${video?.bannerCloudinaryPath}`,
                }),
                title: video?.name,
                subtitle: video?.description,
                slug: video?.name,
                duration: 0,
                videoType: 'video',
              })}
              autoplay={true}
            >
              <source src={video.url} />
            </AngelPlayer>
          )}
        </>
      ) : (
        <LivestreamThumbnail
          guid={video?.guid || ''}
          bannerCloudinaryPath={video?.bannerCloudinaryPath || ''}
          startAt={video?.startAt || ''}
          isEnded={false}
          {...video}
          animationClassName="ease-in-out duration-[1000ms] md:hover:scale-[1.02]"
        />
      )}
    </div>
  )
}
