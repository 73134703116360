import React from 'react'
import { Image } from '@/atoms/Image'
import { useTranslate } from '@/utils/translate/translate-client'
import { scaleDimensions } from '../utils'
import { BaseLogoProps } from './LogoTypes'

export const GoogleStoreDownloadBWLogo: React.FC<BaseLogoProps> = ({ height = 24, className }) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(height, 83, 24)
  const { t } = useTranslate('common')

  return (
    <Image
      width={scaledWidth}
      height={scaledHeight}
      src={`/v1701885546/angel-studios/misc-icons/google_store_logo.png`}
      className={className}
      alt="Google Play Store Logo"
      aria-label={t('downloadOnGooglePlay', 'Download on Google Play')}
    />
  )
}
