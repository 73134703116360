import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useCaptureEmailAndSubscribe } from '@/services/EmailCaptureService'
import { useUser } from '@/services/UserService'
import { ReactFCC } from '@/types/react'

interface EmailCaptureInputProps {
  destination?: string
  formClassName?: string
  inputClassName?: string
  inputWrapperClassName?: string
  onSubmit?: () => void
  placeholder: string
  position: string
  projectSlug: string
  subscriptionGroupId?: string
  inputValue?: string
  setInputValue?: React.Dispatch<React.SetStateAction<string>>
  customTrackPayload?: Record<string, unknown>
  formAriaLabel?: string
  disableSubscribe?: boolean
}

export const EmailCaptureFormInput: ReactFCC<EmailCaptureInputProps> = ({
  children,
  destination,
  formClassName,
  inputClassName,
  onSubmit,
  placeholder,
  position,
  projectSlug,
  subscriptionGroupId,
  inputWrapperClassName,
  inputValue,
  setInputValue,
  customTrackPayload,
  formAriaLabel,
  disableSubscribe,
}) => {
  const { user } = useUser()
  const [value, setValue] = useState('')
  const { push } = useRouter()
  const captureEmailAndSubscribe = useCaptureEmailAndSubscribe(disableSubscribe)

  useEffect(() => {
    if (user?.email) {
      setValue(user.email)
    }
  }, [user])

  return (
    <form
      className={formClassName}
      onSubmit={(e) => {
        e.preventDefault()
        captureEmailAndSubscribe({
          position,
          subscriptionGroupId,
          email: inputValue ?? value,
          destination,
          projectSlug,
          ...customTrackPayload,
        }).finally(() => {
          onSubmit?.()
          if (destination) {
            push(destination)
          }
        })
      }}
      aria-label={formAriaLabel}
    >
      <div className={classNames('md:flex', inputWrapperClassName)}>
        <input
          autoComplete="off"
          className={inputClassName}
          onChange={(e) => {
            if (setInputValue) return setInputValue(e.target.value)
            setValue(e.target.value)
          }}
          placeholder={placeholder}
          required
          type="email"
          value={inputValue ?? value}
        />
        {children}
      </div>
    </form>
  )
}
