import React from 'react'
import { getHexColor, OneColorIconProps } from '@/atoms/utils'

export const LivestreamIcon: React.FC<OneColorIconProps> = ({ size = 24, className, color = 'black' }) => {
  const c = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.79699 16.5287C2.21699 17.6583 2.79449 18.6783 3.52949 19.5888C3.72199 19.8164 3.95824 19.9347 4.23824 19.9439C4.51824 19.9523 4.76324 19.8514 4.97324 19.6413C5.18324 19.4312 5.29279 19.1819 5.30189 18.8933C5.31029 18.6041 5.21824 18.3369 5.02574 18.0918C4.46574 17.374 4.03279 16.5815 3.72689 15.7145C3.42029 14.8482 3.26699 13.9248 3.26699 12.9443C3.26699 11.9639 3.42449 11.0405 3.73949 10.1742C4.05449 9.30714 4.49199 8.51471 5.05199 7.79686C5.24449 7.55174 5.33199 7.28491 5.31449 6.99638C5.29699 6.70714 5.19199 6.46622 4.99949 6.27363C4.78949 6.06353 4.54029 5.95848 4.25189 5.95848C3.96279 5.95848 3.72199 6.07228 3.52949 6.29989C2.79449 7.21033 2.21699 8.22582 1.79699 9.34636C1.37699 10.4669 1.16699 11.6662 1.16699 12.9443C1.16699 14.2049 1.37699 15.3997 1.79699 16.5287Z"
        fill={c}
      />
      <path
        d="M5.66834 14.8878C5.86994 15.5006 6.15449 16.0696 6.52199 16.5948C6.67949 16.8225 6.90279 16.945 7.19189 16.9625C7.48029 16.98 7.72949 16.8837 7.93949 16.6736C8.13199 16.481 8.23699 16.2359 8.25449 15.9383C8.27199 15.6406 8.20199 15.3605 8.04449 15.0979C7.85199 14.7827 7.70744 14.4455 7.61084 14.0862C7.51494 13.7277 7.46699 13.347 7.46699 12.9443C7.46699 12.5416 7.51494 12.1607 7.61084 11.8014C7.70744 11.4428 7.85199 11.106 8.04449 10.7908C8.20199 10.5282 8.27619 10.2522 8.26709 9.963C8.25869 9.67446 8.15824 9.4339 7.96574 9.24131C7.73824 9.0137 7.48029 8.90409 7.19189 8.9125C6.90279 8.9216 6.67949 9.03996 6.52199 9.26757C6.15449 9.77531 5.86994 10.3443 5.66834 10.9746C5.46744 11.6049 5.36699 12.2615 5.36699 12.9443C5.36699 13.6272 5.46744 14.275 5.66834 14.8878Z"
        fill={c}
      />
      <path
        d="M17.0669 15.9246C17.0753 16.2139 17.1757 16.4548 17.3682 16.6474C17.5782 16.8575 17.832 16.958 18.1295 16.9489C18.427 16.9405 18.6545 16.8225 18.812 16.5948C19.1795 16.0696 19.4637 15.5006 19.6646 14.8878C19.8662 14.275 19.967 13.6272 19.967 12.9443C19.967 12.2615 19.8662 11.6049 19.6646 10.9746C19.4637 10.3443 19.1795 9.77531 18.812 9.26757C18.6545 9.03996 18.4315 8.9216 18.1431 8.9125C17.854 8.90409 17.6045 9.00494 17.3945 9.21504C17.202 9.40764 17.097 9.65275 17.0795 9.9504C17.062 10.248 17.132 10.5282 17.2895 10.7908C17.482 11.106 17.6265 11.4428 17.7231 11.8014C17.819 12.1607 17.867 12.5416 17.867 12.9443C17.867 13.347 17.819 13.7277 17.7231 14.0862C17.6265 14.4455 17.482 14.7827 17.2895 15.0979C17.132 15.3605 17.0578 15.6361 17.0669 15.9246Z"
        fill={c}
      />
      <path
        d="M20.0321 18.906C20.0587 19.2036 20.1682 19.4487 20.3607 19.6413C20.5707 19.8514 20.8157 19.9523 21.0957 19.9439C21.3757 19.9347 21.612 19.8164 21.8045 19.5888C22.5395 18.6783 23.117 17.6583 23.537 16.5287C23.957 15.3997 24.167 14.2049 24.167 12.9443C24.167 11.6662 23.957 10.4669 23.537 9.34636C23.117 8.22582 22.5395 7.21033 21.8045 6.29989C21.612 6.07228 21.3757 5.95393 21.0957 5.94482C20.8157 5.93642 20.5707 6.03727 20.3607 6.24737C20.1507 6.45747 20.0412 6.70714 20.0321 6.99638C20.0237 7.28491 20.1157 7.55174 20.3082 7.79686C20.8682 8.51471 21.3012 9.30714 21.6071 10.1742C21.9137 11.0405 22.067 11.9639 22.067 12.9443C22.067 13.9248 21.9095 14.8482 21.5945 15.7145C21.2795 16.5815 20.842 17.374 20.282 18.0918C20.0895 18.3369 20.0062 18.6083 20.0321 18.906Z"
        fill={c}
      />
      <path
        d="M11.6079 9.1232L15.867 12.2594C16.0564 12.4278 16.167 12.6662 16.167 12.9434C16.167 13.2206 16.0564 13.459 15.867 13.6274L11.6079 16.7636C11.3786 16.9679 11.0893 17.0011 10.8299 16.8525C10.5706 16.704 10.417 16.4154 10.417 16.0805V9.80815C10.417 9.4723 10.5706 9.18375 10.8299 9.03615C11.0893 8.88762 11.3786 8.92073 11.6079 9.12508V9.1232Z"
        fill={c}
      />
      <defs>
        <clipPath>
          <rect width="24" height="24" fill={c} transform="translate(0.666992 0.944336)" />
        </clipPath>
      </defs>
    </svg>
  )
}
