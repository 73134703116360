export {
  formatFilmName,
  getCurrentYear,
  getTheatricalPreReleasePageData,
  isProjectThatsBothSeriesAndMovie,
  isTheChosen,
  useDigitalTitle,
  useMoviesTitle,
  useSeriesTitle,
} from './TheatricalPreReleaseService'
export type { GetTheatricalPreReleasePageDataResult } from './TheatricalPreReleaseService'
