import React, { useState } from 'react'
import { Button } from '@/atoms/Button'
import { HeadingLG, ParagraphLG } from '@/atoms/Text'
import { AngelDialog } from '@/molecules/AngelDialog'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { EmailCaptureFormInput } from '@/views/SignUpView/EmailCapture/EmailCaptureFormInput/EmailCaptureFormInput'

interface EmailCaptureSignupModalProps {
  isOpen: boolean
  onClose: () => void
  filmName: string
  emailListId: string
  projectSlug: string
  destination?: string
  headline?: string
  bodyText?: string
}

export const EmailCaptureSignupModal: React.FC<EmailCaptureSignupModalProps> = ({
  filmName,
  emailListId,
  projectSlug,
  destination,
  isOpen,
  onClose,
  headline,
  bodyText,
}) => {
  const { t } = useTranslate('common')
  const [isSubmitted, setSubmitted] = useState<boolean>(false)

  return (
    <AngelDialog isDarkMode={false} open={isOpen} onClose={onClose} className="max-w-[550px] text-center">
      <div className="flex flex-col gap-6">
        {!isSubmitted && (
          <>
            <HeadingLG weight="bold">
              {headline ? (
                headline
              ) : (
                <Translate i18nKey="beTheFirstToKnowAboutFilm" t={t} values={{ filmName }}>
                  Be the first to know about {{ filmName }}
                </Translate>
              )}
            </HeadingLG>
            <ParagraphLG>
              {bodyText ? (
                bodyText
              ) : (
                <Translate i18nKey="signUpToReceiveExclusiveUpdates" t={t} values={{ filmName }}>
                  Sign up to receive exclusive updates, behind-the-scenes content, and exciting news about{' '}
                  {{ filmName }}.
                </Translate>
              )}
            </ParagraphLG>
            <EmailCaptureFormInput
              position="hub-cta-modal"
              subscriptionGroupId={emailListId}
              destination={destination}
              projectSlug={projectSlug}
              placeholder={t('enterEmail', 'Enter Email')}
              formClassName="w-full relative"
              inputClassName="rounded-lg w-full p-4 text-gray-950 border"
              onSubmit={() => {
                if (!destination) {
                  setSubmitted(true)
                }
              }}
            >
              <Button variant="black" className="ml-4 rounded-lg bg-black px-6">
                <Translate i18nKey="signup" t={t}>
                  Signup
                </Translate>
              </Button>
            </EmailCaptureFormInput>
          </>
        )}
        {isSubmitted && (
          <>
            <HeadingLG weight="bold">
              <Translate i18nKey="thanksForYourInterest" t={t} values={{ filmName }}>
                Thank you for your interest in {{ filmName }}
              </Translate>
            </HeadingLG>
            <ParagraphLG>
              <Translate i18nKey="youHaveBeenAdded" t={t} values={{ filmName }}>
                You have been successfully added to the mailing list for {{ filmName }}. Check your email for future
                updates!
              </Translate>
            </ParagraphLG>
            <Button variant="black" onClick={onClose}>
              <Translate i18nKey="close" t={t}>
                Close
              </Translate>
            </Button>
          </>
        )}
      </div>
    </AngelDialog>
  )
}
