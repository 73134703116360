import React from 'react'
import classNames from 'classnames'
import { Case, Switch } from 'react-if'
import Sticky from 'react-stickynode'
import { Image } from '@/atoms/Image'
import { CatalogImage, formatDimensions } from '@/services/ContentCatalog'
import { ProjectMeta } from '@/services/ProjectsService'
import { FilmDetails as FilmDetailsType, TheatricalRegion } from '@/types/codegen-contentful'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useWatchTheatricalContext } from '@/views/WatchTheatricalView/WatchTheatricalContext'
import { Ctas } from '@/views/WatchTheatricalView/ctas'

interface StickyCtaProps {
  titleLogo: CatalogImage | undefined
  projectName: string
  heroCta: ReturnType<typeof useWatchTheatricalContext>['heroCta']
  userCountry: string
  projectMeta: ProjectMeta
  filmDetails: FilmDetailsType
  allTheatricalReleaseRegions: TheatricalRegion[]
  experimentOn: boolean
  hasEarlyAccess: boolean
  logo?: {
    src: string
    alt: string
    className?: string
  }
}

export const StickyCta: React.FC<StickyCtaProps> = ({
  titleLogo,
  projectName,
  projectMeta,
  heroCta,
  userCountry,
  filmDetails,
  allTheatricalReleaseRegions,
  experimentOn,
  hasEarlyAccess,
  logo,
}) => {
  const canShowTitleLogo = Boolean(titleLogo)

  return (
    <>
      <Sticky className="hidden max-h-0 overflow-hidden md:block" activeClass="relative z-[98] !max-h-[100px]">
        <div
          className={classNames(
            'bg-black/80 backdrop-blur-md w-full flex justify-between align-center z-[99] relative overflow-hidden p-6 items-center',
          )}
        >
          <Switch>
            <Case condition={canShowTitleLogo}>
              {titleLogo && (
                <div className="max-w-[110px] lg:max-w-[140px]">
                  <Image
                    alt={projectName}
                    src={getCloudinaryImageUrl({
                      path: titleLogo.cloudinaryPath,
                      transforms: 'e_trim,q_auto:best',
                      width: 140,
                      ...formatDimensions([140, 'auto'], titleLogo),
                    })}
                    {...formatDimensions([140, 'auto'], titleLogo)}
                    className="max-h-[46px] w-auto"
                  />
                </div>
              )}{' '}
            </Case>
            <Case condition={!canShowTitleLogo && Array.isArray(logo)}>
              <div className="max-w-[110px] lg:max-w-[140px]">
                {Array.isArray(logo) && (
                  <Image
                    alt={projectName}
                    src={getCloudinaryImageUrl({
                      path: logo[0]?.url ?? '',
                      transforms: 'e_trim',
                    })}
                    width={logo[0]?.width}
                    height={logo[0]?.height}
                    className="max-h-[46px] w-auto"
                  />
                )}
              </div>
            </Case>
          </Switch>
          <Ctas
            heroCta={heroCta}
            userRegionCode={userCountry}
            project={projectMeta}
            filmDetails={filmDetails as FilmDetailsType}
            allTheatricalReleaseRegions={allTheatricalReleaseRegions}
            experimentOn={experimentOn}
            hasEarlyAccess={hasEarlyAccess}
          />
        </div>
      </Sticky>
      <Sticky className="max-h-0 overflow-hidden md:hidden" activeClass="relative z-[98] !max-h-[100px]">
        <div
          className={classNames(
            'bg-black/80 backdrop-blur-md gap-4 w-full flex justify-between align-center z-[99] relative overflow-hidden p-6 items-center',
          )}
        >
          <Switch>
            <Case condition={canShowTitleLogo}>
              {titleLogo && (
                <div className="block max-w-[120px] md:hidden">
                  <Image
                    alt={projectName}
                    src={getCloudinaryImageUrl({
                      path: titleLogo.cloudinaryPath,
                      transforms: 'e_trim',
                      ...formatDimensions([120, 'auto'], titleLogo),
                    })}
                    {...formatDimensions([120, 'auto'], titleLogo)}
                  />
                </div>
              )}
            </Case>
            <Case condition={!canShowTitleLogo && Array.isArray(logo)}>
              <div className="max-w-[110px] lg:max-w-[140px]">
                {Array.isArray(logo) && (
                  <Image
                    alt={projectName}
                    src={getCloudinaryImageUrl({
                      path: logo[0]?.url ?? '',
                      transforms: 'e_trim',
                    })}
                    width={logo[0]?.width}
                    height={logo[0]?.height}
                    className="max-h-[50px] w-auto"
                  />
                )}
              </div>
            </Case>
          </Switch>

          <Ctas
            heroCta={heroCta}
            userRegionCode={userCountry}
            project={projectMeta}
            filmDetails={filmDetails as FilmDetailsType}
            allTheatricalReleaseRegions={allTheatricalReleaseRegions}
            className="min-h-[46px] py-1 text-center sm:px-16 sm:py-3"
            experimentOn={experimentOn}
            hasEarlyAccess={hasEarlyAccess}
          />
        </div>
      </Sticky>
    </>
  )
}
