import React from 'react'
import { AsH3, HeadingMD } from '@/atoms/Text'
import {
  formatTitleCrewMap,
  CatalogTitle,
  CatalogMovie,
  CatalogSeries,
  formatTitleFriendlyReleaseDate,
  formatTitleMoviePosterPath,
} from '@/services/ContentCatalog'
import { FilmDetails as FilmDetailsType } from '@/types/codegen-contentful'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { CastRail } from '@/views/WatchTheatricalView/FilmDetails/CastRail'
import { DescriptorSection } from '@/views/WatchTheatricalView/FilmDetails/DescriptorSection'
import { CrewCredits } from './CrewCredits'

interface DigitalFilmDetailsComponentProps {
  catalogTitle: CatalogMovie | CatalogSeries
  filmDetails: FilmDetailsType
}
export function DigitalFilmDetails(props: DigitalFilmDetailsComponentProps) {
  const { t } = useTranslate('theatrical-presales')
  const { locale } = useLocale()

  const moviePosterPath = formatTitleMoviePosterPath(props.catalogTitle)
  const releaseDate = formatReleaseDate(locale, props.catalogTitle, props.filmDetails)

  return (
    <div className="grid-col-1 grid gap-8 text-left lg:grid-cols-8 lg:gap-16">
      {moviePosterPath && (
        <div className="hidden lg:col-span-3 lg:block">
          <img
            src={getCloudinaryImageUrl({ path: moviePosterPath, width: 638, height: 930 })}
            className="ml-auto"
            alt={t('moviePosterAlt', '{{name}} Movie Poster', { name: props.catalogTitle.title })}
            height={930}
            width={638}
          />
        </div>
      )}
      <div key="details" className="py-12 lg:col-span-5 lg:py-0">
        <div className="hidden lg:block">
          <HeadingMD weight="bold" color="white" as={AsH3} className="lg:photon-heading-lg mb-4">
            {props.catalogTitle.title}
          </HeadingMD>
        </div>
        <div className="flex flex-col lg:flex-col-reverse">
          <div className="mb-6 w-full max-w-[95vw]">
            <CastRail catalogTitle={props.catalogTitle} />
          </div>
          <div className="mb-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">
            {props.catalogTitle && releaseDate && (
              <DescriptorSection title={t('releaseDate', 'Release Date')} description={releaseDate} />
            )}
            <CrewSections catalogTitle={props.catalogTitle} />
          </div>
        </div>
      </div>
    </div>
  )
}

export function formatReleaseDate(
  locale: string,
  catalogTitle: CatalogTitle,
  filmDetails: FilmDetailsType,
): string | undefined {
  if (filmDetails?.theatricalReleaseDateOverrideText) return filmDetails.theatricalReleaseDateOverrideText
  return formatTitleFriendlyReleaseDate(locale, catalogTitle)
}

export function CrewSections(props: { catalogTitle?: CatalogTitle }) {
  const { t } = useTranslate('theatrical-presales')
  if (!props.catalogTitle) return null

  const crew = formatTitleCrewMap(props.catalogTitle)

  const sections = [
    {
      title: t('filmDirectorV2', {
        count: crew['Director'].length,
        defaultValue: 'Director',
        defaultValue_other: 'Directors',
      }),
      names: crew['Director'],
    },
    {
      title: t('executiveProducerV2', {
        count: crew['Executive Producer'].length,
        defaultValue: 'Executive Producer',
        defaultValue_other: 'Executive Producers',
      }),
      names: crew['Executive Producer'],
    },
    {
      title: t('producerV2', {
        count: crew['Producer'].length,
        defaultValue: 'Producer',
        defaultValue_other: 'Producers',
      }),
      names: crew['Producer'],
    },
    {
      title: t('writer', {
        count: crew['Writer'].length,
        defaultValue: 'Writer',
        defaultValue_other: 'Writers',
      }),
      names: crew['Writer'],
    },
    {
      title: t('directorOfPhotography', {
        count: crew['Director of Photography'].length,
        defaultValue: 'Director of Photography',
        defaultValue_other: 'Directors of Photography',
      }),
      names: crew['Director of Photography'],
    },
    {
      title: t('distributor', {
        count: crew['Distributor'].length,
        defaultValue: 'Distributor',
        defaultValue_other: 'Distributors',
      }),
      names: crew['Distributor'],
    },
  ]

  return sections.map((section) => <CrewCredits key={section.title} names={section.names} title={section.title} />)
}
