import React from 'react'
import { useTranslate } from '@/utils/translate/translate-client'
import { scaleDimensions, getHexColor } from '../utils'
import { OneColorLogoProps } from './LogoTypes'

export const YouTubeLogo: React.FC<OneColorLogoProps> = ({ height = 32, color = 'white', className }) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(height, 70, 15)
  const hex = getHexColor(color)
  const { t } = useTranslate('common')

  return (
    <svg
      className={className}
      width={scaledWidth}
      height={scaledHeight}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 16"
      fill="none"
      aria-label={t('youtube', 'YouTube')}
    >
      <g clipPath="url(#clip0_807_2556)">
        <path
          d="M22.9558 2.52709C22.6971 1.55032 21.9373 0.782721 20.9706 0.521253C19.2202 0.0468751 12.198 0.046875 12.198 0.046875C12.198 0.046875 5.17586 0.0468751 3.4254 0.521253C2.45869 0.782721 1.69898 1.55032 1.4402 2.52709C0.970703 4.29573 0.970703 7.98803 0.970703 7.98803C0.970703 7.98803 0.970703 11.6804 1.4402 13.449C1.69898 14.4258 2.45869 15.1934 3.4254 15.4548C5.17586 15.9292 12.198 15.9292 12.198 15.9292C12.198 15.9292 19.2202 15.9292 20.9706 15.4548C21.9373 15.1934 22.6971 14.4258 22.9558 13.449C23.4253 11.6804 23.4253 7.98803 23.4253 7.98803C23.4253 7.98803 23.4235 4.29573 22.9558 2.52709Z"
          fill={hex}
        />
        <path d="M9.95117 11.3896L15.7848 7.98683L9.95117 4.58398V11.3896Z" fill="#141414" />
        <path
          d="M28.1658 10.3855L25.6445 1.18555H27.8442L28.7277 5.35596C28.9532 6.38316 29.1177 7.25907 29.2249 7.98371H29.2896C29.3636 7.46451 29.5299 6.5942 29.7869 5.3709L30.7018 1.18555H32.9014L30.3488 10.3855V14.7987H28.164V10.3855H28.1658Z"
          fill={hex}
        />
        <path
          d="M33.5631 14.5043C33.1195 14.2017 32.8034 13.731 32.6149 13.0923C32.4282 12.4536 32.334 11.6057 32.334 10.5449V9.10117C32.334 8.03102 32.4412 7.17008 32.6556 6.52201C32.87 5.87395 33.2046 5.39957 33.6593 5.10261C34.114 4.80566 34.711 4.65625 35.4504 4.65625C36.1787 4.65625 36.7609 4.80753 37.2008 5.11009C37.6389 5.41264 37.9605 5.88702 38.1638 6.52948C38.3672 7.17381 38.4689 8.03102 38.4689 9.10117V10.5449C38.4689 11.6057 38.3691 12.4573 38.1713 13.0998C37.9735 13.7441 37.6519 14.2148 37.2082 14.5117C36.7646 14.8087 36.162 14.9581 35.4023 14.9581C34.6186 14.9599 34.0068 14.8068 33.5631 14.5043ZM36.0511 12.9467C36.1731 12.6217 36.236 12.0932 36.236 11.3573V8.25893C36.236 7.54547 36.175 7.02253 36.0511 6.69383C35.9273 6.36327 35.7111 6.19892 35.4004 6.19892C35.1011 6.19892 34.8885 6.36327 34.7665 6.69383C34.6426 7.02441 34.5816 7.54547 34.5816 8.25893V11.3573C34.5816 12.0932 34.6407 12.6236 34.7591 12.9467C34.8774 13.2716 35.0899 13.4341 35.4004 13.4341C35.7111 13.4341 35.9273 13.2716 36.0511 12.9467Z"
          fill={hex}
        />
        <path
          d="M45.6247 14.8004H43.8909L43.6986 13.5827H43.6506C43.1792 14.5016 42.4731 14.961 41.5304 14.961C40.8779 14.961 40.3955 14.7444 40.085 14.313C39.7744 13.8797 39.6191 13.2036 39.6191 12.2848V4.85343H41.8354V12.154C41.8354 12.5985 41.8835 12.9141 41.9796 13.1028C42.0757 13.2914 42.2365 13.3866 42.462 13.3866C42.6542 13.3866 42.8391 13.3269 43.0166 13.2074C43.194 13.0878 43.3234 12.9365 43.4102 12.7535V4.85156H45.6247V14.8004Z"
          fill={hex}
        />
        <path d="M51.6456 2.98594H49.446V14.7987H47.2778V2.98594H45.0781V1.18555H51.6456V2.98594Z" fill={hex} />
        <path
          d="M56.9899 14.8004H55.2561L55.0638 13.5827H55.0158C54.5445 14.5016 53.8384 14.961 52.8956 14.961C52.2431 14.961 51.7607 14.7444 51.4502 14.313C51.1397 13.8797 50.9844 13.2036 50.9844 12.2848V4.85343H53.2007V12.154C53.2007 12.5985 53.2487 12.9141 53.3448 13.1028C53.4409 13.2914 53.6017 13.3866 53.8273 13.3866C54.0195 13.3866 54.2043 13.3269 54.3818 13.2074C54.5593 13.0878 54.6886 12.9365 54.7755 12.7535V4.85156H56.9899V14.8004Z"
          fill={hex}
        />
        <path
          d="M64.3243 6.44141C64.1894 5.81389 63.9731 5.36005 63.6737 5.07804C63.3742 4.79603 62.962 4.65596 62.4371 4.65596C62.0304 4.65596 61.6496 4.77175 61.2966 5.0052C60.9435 5.23866 60.6699 5.54308 60.4777 5.92221H60.4611V0.681641H58.3262V14.7972H60.1561L60.3816 13.8559H60.4297C60.6016 14.1921 60.8585 14.4554 61.2005 14.6515C61.5424 14.8458 61.9232 14.9429 62.341 14.9429C63.0896 14.9429 63.6423 14.5936 63.9953 13.897C64.3483 13.1985 64.5257 12.1096 64.5257 10.6268V9.05238C64.5257 7.94111 64.4574 7.06893 64.3243 6.44141ZM62.2929 10.4997C62.2929 11.2244 62.2633 11.7922 62.2041 12.203C62.145 12.6139 62.047 12.9072 61.9066 13.0789C61.7679 13.2526 61.5794 13.3385 61.3446 13.3385C61.1616 13.3385 60.9934 13.2956 60.8381 13.2078C60.6829 13.1219 60.5572 12.9912 60.4611 12.8194V7.17352C60.535 6.90271 60.6644 6.68233 60.8474 6.50865C61.0286 6.33496 61.2282 6.24904 61.4407 6.24904C61.6663 6.24904 61.84 6.33869 61.962 6.51612C62.0858 6.69541 62.1709 6.99423 62.219 7.41631C62.267 7.83839 62.291 8.43789 62.291 9.21668V10.4997H62.2929Z"
          fill={hex}
        />
        <path
          d="M67.6702 11.0717C67.6702 11.7104 67.6887 12.1886 67.7257 12.508C67.7626 12.8273 67.8403 13.0589 67.9586 13.2065C68.0769 13.3521 68.258 13.4249 68.5039 13.4249C68.8348 13.4249 69.0639 13.2942 69.1859 13.0346C69.3098 12.775 69.3764 12.3418 69.3874 11.7366L71.2987 11.8505C71.3098 11.9364 71.3153 12.0559 71.3153 12.2072C71.3153 13.1261 71.0658 13.8134 70.5686 14.2672C70.0714 14.7211 69.3671 14.9489 68.4577 14.9489C67.3653 14.9489 66.6 14.6034 66.1619 13.9105C65.722 13.2177 65.5039 12.1475 65.5039 10.6982V8.96128C65.5039 7.46907 65.7313 6.37838 66.186 5.69109C66.6407 5.0038 67.4189 4.66016 68.5223 4.66016C69.282 4.66016 69.8662 4.80023 70.2728 5.08224C70.6795 5.36425 70.9659 5.80128 71.1323 6.39705C71.2987 6.99282 71.3819 7.81458 71.3819 8.86416V10.5675H67.6702V11.0717ZM67.9512 6.38398C67.8384 6.52405 67.7645 6.75377 67.7257 7.07313C67.6887 7.3925 67.6702 7.87621 67.6702 8.52618V9.23962H69.2913V8.52618C69.2913 7.88742 69.2692 7.40371 69.2266 7.07313C69.1841 6.74257 69.1065 6.51098 68.9937 6.37464C68.881 6.24018 68.7072 6.17107 68.4724 6.17107C68.2359 6.17294 68.0621 6.24391 67.9512 6.38398Z"
          fill={hex}
        />
      </g>
      <defs>
        <clipPath id="clip0_807_2556">
          <rect width="70.4118" height="15.8824" fill={hex} transform="translate(0.970703 0.0585938)" />
        </clipPath>
      </defs>
    </svg>
  )
}
