import React from 'react'
import { getHexColor, OneColorIconProps } from '@/atoms/utils'

export const UpArrowIcon: React.FC<OneColorIconProps> = ({ className = '', size = 40, color = 'black' }) => {
  const strokeColor = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21 28L21 15.83L26.59 21.42L28 20L20 12L12 20L13.41 21.41L19 15.83L19 28L21 28Z" fill={strokeColor} />
    </svg>
  )
}
