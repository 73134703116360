import { ProjectMeta } from '@/services/ProjectsService'
import { FilmDetails, TheatricalRegion } from '@/types/codegen-contentful'

export interface ChooseCtaInput {
  // TODO: is this enumerated anywhere for a tighter type?
  userRegionCode: string
  allTheatricalReleaseRegions?: TheatricalRegion[]
  project: Pick<ProjectMeta, 'slug' | 'public' | 'pifEnabled' | 'seasons'>
  filmDetails: FilmDetails | null | undefined
  experimentOn: boolean
  hasEarlyAccess: boolean
}

const CODES = ['theater', 'stream', 'pif', 'updates', 'joinGuild'] as const
export type CtaCode = typeof CODES[number]
const CHOOSERS: { [code: string]: (input: ChooseCtaInput) => CtaCode | undefined } = {
  joinGuild: chooseGuild,
  theater: chooseTheater,
  stream: chooseStream,
  pif: choosePif,
  updates: chooseUpdates,
}

/**
 * Chooses what CTAs to show on a movies hub page, in priority order of most impactful
 * possible CTA.
 */
export function chooseCtas(input: ChooseCtaInput) {
  return head(CODES.filter((code) => CHOOSERS[code](input)))
}

/**
 * Potentially all CTAs are at once applicable to a project. This limits to a sub list in
 * the combos and orders we want.
 */
function head(codes: CtaCode[]): CtaCode[] {
  if (codes.includes('joinGuild') && codes.includes('theater')) return ['joinGuild', 'theater']
  if (codes.includes('joinGuild')) return ['joinGuild']
  if (codes.includes('theater') && codes.includes('stream')) return ['stream', 'theater']
  if (codes.includes('theater')) return ['theater']
  if (codes.includes('stream')) return ['stream']
  return codes.slice(0, 1)
}

// TODO: move into a common module; maybe create a boolean wrapper api?
export function chooseTheater(input: ChooseCtaInput): 'theater' | undefined {
  if (
    input.allTheatricalReleaseRegions?.find((theatricalRegion) => {
      const isRegionOfUser = theatricalRegion.region === input.userRegionCode
      const isStillShowing = !theatricalRegion.hasTheatricalEnded
      const willHaveTheatricalRelease = input.filmDetails?.willHaveTheatricalRelease
      const hasTheatricalDate = !!input.filmDetails?.theatricalReleaseDate
      const isTicketPageEnabled = theatricalRegion.ticketPageEnabled
      return isRegionOfUser && isStillShowing && willHaveTheatricalRelease && hasTheatricalDate && isTicketPageEnabled
    })
  )
    return 'theater'
}

/**
 * This implementation only works for movies, not shows.
 */
function chooseStream(input: ChooseCtaInput): 'stream' | undefined {
  if (
    input.project.seasons?.at(0)?.episodes?.find((episode) => {
      const episodeExists = !!episode
      const episodeIsPubliclyReleased = episode?.unavailableReason === null || episode.unavailableReason === undefined
      const episodeIsEarlyOrGuildAccess = episode?.unavailableReason === 'PRERELEASE'
      const episodeIsTrailer = episode?.isTrailer
      return episodeExists && (episodeIsPubliclyReleased || episodeIsEarlyOrGuildAccess) && !episodeIsTrailer
    })
  )
    return 'stream'
}

function choosePif(input: ChooseCtaInput): 'pif' | undefined {
  if (input.project.pifEnabled) return 'pif'
}

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
function chooseUpdates(_input: ChooseCtaInput): 'updates' {
  return 'updates'
}

function chooseGuild(input: ChooseCtaInput): 'joinGuild' | undefined {
  const episodeIsEarlyOrGuildAccess = input.project.seasons?.at(0)?.episodes?.find((episode) => {
    return episode?.unavailableReason === 'PRERELEASE'
  })

  if (episodeIsEarlyOrGuildAccess && !input.hasEarlyAccess) return 'joinGuild'
}
