import React from 'react'
import { useTranslate } from '@/utils/translate/translate-client'
import { scaleDimensions, getHexColor } from '../utils'
import { OneColorLogoProps } from './LogoTypes'

export const VuduLogo: React.FC<OneColorLogoProps> = ({ height = 32, color = 'white', className }) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(height, 47, 18)
  const hex = getHexColor(color)
  const { t } = useTranslate('common')

  return (
    <svg
      className={className}
      fill={hex}
      height={scaledHeight}
      viewBox="0 0 264.58333 105.30417"
      width={scaledWidth}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={t('vudu', 'Vudu')}
    >
      <g transform="translate(0,-191.69582)">
        <g transform="matrix(0.67857237,0,0,0.67857237,-73.223562,-41.834191)">
          <path d="m 207.977,344.031 h -17.213 c -2.886,0.037 -5.455,1.84 -6.468,4.543 -0.017,0.16 -0.017,0.321 0,0.481 -6.468,18.496 -12.134,34.373 -18.656,52.869 -1.71,4.918 -3.368,9.783 -5.346,14.647 -0.132,0.429 -0.444,0.78 -0.855,0.962 -0.749,0 -0.749,-0.535 -0.909,-1.016 -3.992,-11.546 -8.054,-23.075 -12.188,-34.586 L 134.528,348.36 c 0.035,-0.122 0.035,-0.251 0,-0.374 -1.197,-2.419 -3.662,-3.952 -6.361,-3.956 H 110.74 c -1.41,-0.146 -2.671,0.877 -2.818,2.287 -0.05,0.481 0.037,0.968 0.252,1.402 10.014,27.976 20.028,55.898 30.042,83.767 l 2.031,5.772 c 2.932,8.463 10.93,14.118 19.886,14.06 h 2.299 c 3.865,-0.182 7.709,-0.683 11.493,-1.497 l 7.111,-20.26 29.561,-81.682 c 0.131,-0.312 0.238,-0.634 0.321,-0.962 0.187,-1.404 -0.801,-2.694 -2.207,-2.88 -0.207,-0.028 -0.417,-0.03 -0.626,-0.006" />
          <path d="m 303.932,351.354 v 0 c 0.011,-3.667 -2.811,-6.723 -6.468,-7.002 H 279.61 v 9.782 c 0,18.496 0,34.908 0,53.457 0.045,1.889 -0.153,3.774 -0.587,5.613 -1.301,6.628 -6.22,11.962 -12.723,13.792 -9.934,3.246 -20.62,-2.173 -23.868,-12.107 -0.629,-1.924 -0.945,-3.938 -0.937,-5.962 0,-12.776 0,-40.092 0,-54.9 v -9.89 c 0,0 -17.748,0 -17.908,0 -3.667,0.279 -6.504,3.326 -6.522,7.003 v 3.528 c 0,19.245 0,35.709 0,55.328 0.143,23.618 19.405,42.648 43.024,42.506 2.786,-0.017 5.564,-0.307 8.295,-0.864 10.609,-1.792 20.154,-7.52 26.729,-16.036 5.643,-7.001 8.752,-15.706 8.82,-24.697 0,-10.264 0,-20.527 0,-30.792 v -28.759 z" />
          <path d="m 497.82,351.354 v 0 c -0.016,-3.658 -2.823,-6.698 -6.469,-7.002 h -17.908 v 9.782 c 0,18.496 0,34.908 0,53.457 0.02,1.887 -0.178,3.771 -0.587,5.613 -1.324,6.617 -6.233,11.939 -12.723,13.792 -9.893,3.276 -20.57,-2.086 -23.848,-11.979 -0.65,-1.963 -0.974,-4.021 -0.957,-6.09 0,-12.776 0,-40.092 0,-54.9 v -9.89 c 0,0 -17.748,0 -17.961,0 -3.646,0.305 -6.454,3.345 -6.468,7.003 v 3.528 c 0,19.245 0,35.709 0,55.328 0.143,23.618 19.403,42.648 43.022,42.506 2.787,-0.017 5.565,-0.307 8.295,-0.864 10.61,-1.792 20.153,-7.52 26.729,-16.036 5.667,-6.988 8.778,-15.701 8.82,-24.697 0,-10.264 0,-20.527 0,-30.792 v -28.76 0 h 0.055 z" />
          <path d="m 372.784,418.282 c -4.705,5.679 -11.475,9.256 -18.817,9.942 -1.46,0.081 -2.923,0.081 -4.384,0 h -12.615 c -1.497,0 -1.551,0 -1.551,-1.55 v -58.695 c 0,-1.39 0,-1.39 1.391,-1.39 5.346,0 10.264,0 15.396,0 9.137,0.29 17.557,5.018 22.559,12.669 3.24,4.743 5.092,10.297 5.345,16.037 0.683,8.328 -1.949,16.589 -7.324,22.987 m 7.163,-66.126 c -7.703,-4.901 -16.586,-7.634 -25.712,-7.912 -4.276,0 -8.5,0 -12.776,0 h -22.611 c -3.897,0 -7.057,3.159 -7.057,7.056 v 92.747 c 0,3.897 3.159,7.057 7.057,7.057 h 33.891 c 2.583,-0.034 5.157,-0.284 7.698,-0.748 10.212,-1.497 19.733,-6.043 27.316,-13.044 14.054,-12.26 20.459,-31.11 16.785,-49.394 -2.561,-14.849 -11.485,-27.836 -24.431,-35.549" />
          <path d="m 420.361,465.538 c -9.321,0.384 -16.566,8.412 -16.183,17.733 0.385,9.322 8.252,16.566 17.573,16.183 9.108,-0.375 16.275,-7.909 16.197,-17.024 0.18,-9.071 -6.969,-16.601 -16.038,-16.892 h -1.549 m 9.568,17.587 c -0.183,2.09 -1.046,4.063 -2.459,5.612 l -0.534,0.481 c -3.868,3.645 -9.957,3.463 -13.602,-0.404 -3.645,-3.868 -3.464,-9.959 0.405,-13.603 3.868,-3.645 9.958,-3.463 13.602,0.405 0.008,0.008 0.016,0.016 0.021,0.023 1.554,1.619 2.466,3.747 2.566,5.987 v 1.499 z" />
          <path d="m 384.01,480.078 v 7.377 h 7.324 v 2.565 c -1.752,1.285 -3.868,1.979 -6.041,1.979 -4.839,0.18 -8.907,-3.596 -9.087,-8.435 -0.001,-0.004 -0.001,-0.008 -0.001,-0.012 -0.054,-0.337 -0.054,-0.679 0,-1.016 -0.368,-4.856 3.27,-9.092 8.125,-9.462 h 0.963 c 2.863,0.062 5.483,1.625 6.896,4.115 l 7.057,-3.635 c -2.773,-5.069 -8.178,-8.134 -13.952,-7.911 -9.326,-0.245 -17.085,7.117 -17.329,16.443 -0.236,9.006 6.635,16.612 15.618,17.288 h 1.711 c 4.937,0.041 9.687,-1.883 13.203,-5.346 v 0 c 0.587,-0.604 0.93,-1.403 0.963,-2.245 v -11.707 h -15.45 z" />
          <path d="m 359.42,466.394 h -0.695 c -1.812,0.162 -3.214,1.656 -3.261,3.475 v 14.434 L 342.26,466.395 h -8.767 v 32.555 h 8.393 v -18.71 l 13.792,18.71 h 8.071 v -32.555 h -4.329 z" />
          <path d="m 318.258,466.394 h -10.691 l -12.294,32.608 h 9.569 l 1.603,-4.705 h 12.83 l 0.855,2.459 v 0 c 0.537,1.281 1.767,2.135 3.153,2.192 h 7.164 z m -9.408,20.581 4.116,-12.296 4.116,12.296 z" />
          <path d="m 278.54,466.394 h -13.739 v 29.187 c 0.11,1.883 1.643,3.37 3.529,3.422 h 10.21 c 8.99,0.515 16.695,-6.354 17.21,-15.345 0.515,-8.991 -6.354,-16.695 -15.344,-17.211 -0.622,-0.035 -1.245,-0.035 -1.866,0 m 0,25.284 h -5.346 v -18.176 h 5.346 c 4.618,-0.243 8.561,3.296 8.82,7.912 v 0.962 c 0,4.842 -3.924,9.302 -8.767,9.302 -0.017,0 0.044,-0.027 0.027,-0.027 l 0.08,-0.08" />
          <path d="m 254.11,466.394 v 0 c -1.835,0.079 -3.313,1.534 -3.42,3.367 v 14.541 l -13.418,-17.908 h -8.82 v 32.555 h 8.393 v -18.71 l 13.792,18.871 h 8.125 v -32.716 z" />
          <path d="m 213.484,466.394 h -10.691 l -12.295,32.555 h 9.782 l 1.604,-4.704 h 12.937 l 0.855,2.459 v 0 c 0.542,1.284 1.763,2.153 3.154,2.245 h 7.164 z m -9.463,20.581 4.223,-12.296 4.063,12.296 z" />
          <path d="m 193.651,473.343 v -7.323 h -25.072 v 32.448 h 8.82 v -11.28 h 8.821 c 1.545,-0.179 2.739,-1.44 2.833,-2.993 v -4.384 H 177.56 v -6.468 z" />
          <rect height="2.566" width="46.775002" y="481.254" x="114.427" />
          <rect height="2.566" width="46.775002" y="481.254" x="445.37799" />
        </g>
      </g>
    </svg>
  )
}
