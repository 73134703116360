import React, { CSSProperties, useRef } from 'react'
import classNames from 'classnames'
import Slider, { Settings } from 'react-slick'
import { UpArrowCircleIcon } from '@/atoms/Icons/UpArrowCircleIcon'
import { TitleLG } from '@/atoms/Text'

interface ReactSlickArrowProps {
  currentSlide?: number
  slideCount?: number
  onClick?: () => void
  style?: Record<string, string | number>
}

const getChevronClassNames = (visible: boolean, className?: string) =>
  classNames('cursor-pointer min-w-10 w-10 z-10 !flex items-center justify-center', className)

export interface HubpageSingleItemSliderProps extends Settings {
  title: string
  isPlayer?: boolean
  className?: string
  showChevron?: boolean
  sliderStyles?: string | CSSProperties
  id: string
}

export const HubpageSingleItemSlider: React.FC<HubpageSingleItemSliderProps> = ({
  title,
  isPlayer,
  children,
  className,
  sliderStyles,
  showChevron = true,
  id,
  ...props
}) => {
  const slider = useRef<Slider>(null)

  const pauseVideo = () => {
    const videos = document.querySelectorAll('iframe, video')
    Array.prototype.forEach.call(videos, function (video) {
      if (video.tagName.toLowerCase() === 'video') {
        video.pause()
      } else {
        const src = video.src
        video.src = src
      }
    })
  }

  const next = () => {
    slider?.current?.slickNext()
    if (isPlayer) {
      pauseVideo()
    }
  }
  const previous = () => {
    slider?.current?.slickPrev()
    if (isPlayer) {
      pauseVideo()
    }
  }

  const ReactSlickChevronRight: React.FC<ReactSlickArrowProps> = (props) => {
    const { onClick, style } = props

    return (
      <div style={style} className={getChevronClassNames(true, 'group-image-gallery-handle')} onClick={onClick}>
        <UpArrowCircleIcon
          className="rotate-90 !stroke-white group-image-gallery-handle-hover:!stroke-white lg:!stroke-core-gray-300"
          color="inherit"
        />
      </div>
    )
  }

  const ReactSlickChevronLeft: React.FC<ReactSlickArrowProps> = (props) => {
    const { onClick, style } = props
    return (
      <div style={style} className={getChevronClassNames(true, 'group-image-gallery-handle')} onClick={onClick}>
        <UpArrowCircleIcon
          className="-rotate-90 !stroke-white group-image-gallery-handle-hover:!stroke-white lg:!stroke-core-gray-300"
          color="inherit"
        />
      </div>
    )
  }

  return (
    <>
      <div className="relative col-span-full mb-10  flex place-items-center justify-between">
        <TitleLG id={id} className="sm:photon-heading-sm" weight="bold" color="white">
          {title}
        </TitleLG>
        {showChevron && (
          <div className="flex gap-1 justify-self-end">
            <ReactSlickChevronLeft onClick={previous} />
            <ReactSlickChevronRight onClick={next} />
          </div>
        )}
      </div>
      <div
        className={classNames(className, 'md:w-4/5 max-w-7xl mx-auto bg-black text-white relative overflow-x-hidden')}
      >
        <div className={classNames(sliderStyles)}>
          <Slider
            ref={slider}
            swipe
            touchMove
            useCSS
            useTransform
            waitForAnimate
            infinite={true}
            initialSlide={0}
            swipeToSlide={false}
            touchThreshold={5}
            cssEase="ease"
            easing="easeQuadOut"
            lazyLoad="progressive"
            speed={300}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
            {...props}
          >
            {children}
          </Slider>
        </div>
      </div>
    </>
  )
}
