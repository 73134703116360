import React from 'react'
import { AsH4, ParagraphSM, TitleMD } from '@/atoms/Text'

interface CrewCreditsProps {
  title: string
  names: string[]
}

export const CrewCredits: React.FC<CrewCreditsProps> = ({ title, names }) => {
  if (!Array.isArray(names) || names.length <= 0) return null

  return (
    <div>
      <TitleMD as={AsH4} color="white" weight="semibold" className="mb-2 uppercase">
        {title}
      </TitleMD>
      {names.map((name, index) => {
        return (
          <ParagraphSM key={index} color="core-gray-200">
            {name}
          </ParagraphSM>
        )
      })}
    </div>
  )
}
