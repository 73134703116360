import React from 'react'
import { useTranslate } from '@/utils/translate/translate-client'
import { getHexColor, scaleDimensions } from '../utils'
import { OneColorLogoProps } from './LogoTypes'

export const SamsungLogo: React.FC<OneColorLogoProps> = ({ height = 24, color = 'white', className }) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(height, 74, 24)
  const hex = getHexColor(color)
  const { t } = useTranslate('common')

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={scaledWidth}
      height={scaledHeight}
      viewBox="0 0 75 26"
      fill="none"
      aria-label={t('samsung', 'Samsung')}
    >
      <g clipPath="url(#clip0_807_2635)">
        <path
          d="M74.8071 6.72173C75.8214 12.5573 59.9972 20.1996 39.4589 23.7907C18.9231 27.3819 1.45315 25.5597 0.440465 19.7216C-0.571398 13.8852 15.2569 6.24542 35.7927 2.65505C56.3293 -0.937775 73.796 0.884508 74.8071 6.72173Z"
          fill={hex}
        />
        <path
          d="M55.4794 15.3922L55.3738 9.32422H57.2798V16.9722H54.5395L52.6376 10.6957H52.5967L52.7023 16.9722H50.8086V9.32422H53.6675L55.4368 15.3922H55.4794Z"
          fill="black"
        />
        <path
          d="M20.8341 10.0403L19.7797 17.0527H17.7012L19.1286 9.32422H22.5527L23.9736 17.0527H21.9032L20.8775 10.0403H20.8341Z"
          fill="black"
        />
        <path
          d="M28.8133 17.0527L27.5258 10.1044H27.4841L27.4358 17.0527H25.5078L25.678 9.32422H28.8191L29.7696 15.2321H29.8105L30.7618 9.32422H33.9013L34.0682 17.0527H32.1442L32.0935 10.1044H32.0534L30.7667 17.0527H28.8133Z"
          fill="black"
        />
        <path
          d="M14.4594 14.8679C14.5347 15.0552 14.5118 15.2958 14.4766 15.4411C14.412 15.6982 14.2386 15.9601 13.7265 15.9601C13.2439 15.9601 12.951 15.6826 12.951 15.258V14.5099H10.8864L10.8848 15.1085C10.8848 16.8314 12.2361 17.3513 13.684 17.3513C15.0762 17.3513 16.223 16.8733 16.4054 15.5857C16.4987 14.918 16.4292 14.4811 16.3973 14.3161C16.0725 12.6983 13.1506 12.2154 12.933 11.3104C12.8962 11.1552 12.9069 10.9909 12.9249 10.9031C12.978 10.6567 13.1465 10.3849 13.6283 10.3849C14.0782 10.3849 14.3441 10.6641 14.3441 11.0862C14.3441 11.2283 14.3441 11.5633 14.3441 11.5633H16.2639V11.0205C16.2639 9.33701 14.7588 9.07422 13.6684 9.07422C12.2991 9.07422 11.1801 9.52835 10.9756 10.7865C10.9199 11.1338 10.9118 11.4434 10.9927 11.831C11.3289 13.4103 14.0635 13.8677 14.4594 14.8679Z"
          fill="black"
        />
        <path
          d="M39.4915 14.8581C39.5675 15.0437 39.543 15.2778 39.5086 15.4231C39.4457 15.6793 39.2739 15.9372 38.7651 15.9372C38.289 15.9372 37.9986 15.6596 37.9986 15.2449L37.997 14.5034H35.9536L35.9512 15.0938C35.9512 16.7995 37.2902 17.3144 38.7234 17.3144C40.1009 17.3144 41.2371 16.843 41.4162 15.5668C41.5095 14.9041 41.4432 14.473 41.4097 14.3112C41.0866 12.7082 38.1941 12.2302 37.979 11.3343C37.9422 11.1799 37.9528 11.0173 37.9716 10.9343C38.0264 10.6863 38.1917 10.4211 38.6686 10.4211C39.1144 10.4211 39.3745 10.6937 39.3745 11.1125C39.3745 11.253 39.3745 11.5847 39.3745 11.5847H41.2796V11.0477C41.2796 9.38224 39.7868 9.12109 38.707 9.12109C37.3532 9.12109 36.2432 9.56948 36.0428 10.8186C35.9872 11.161 35.9814 11.4649 36.0608 11.8508C36.3921 13.4136 39.0996 13.8677 39.4915 14.8581Z"
          fill="black"
        />
        <path
          d="M45.9578 15.8919C46.492 15.8919 46.6588 15.5207 46.6956 15.331C46.7112 15.2472 46.7153 15.1356 46.7136 15.0354V9.31641H48.6605V14.8605C48.6646 15.0025 48.6507 15.2949 48.6441 15.368C48.5075 16.8108 47.3713 17.2789 45.957 17.2789C44.541 17.2789 43.404 16.8108 43.269 15.368C43.2625 15.2949 43.2486 15.0025 43.2527 14.8605V9.31641H45.1979V15.0354C45.1979 15.1356 45.2012 15.2481 45.2159 15.331C45.2576 15.5207 45.4196 15.8919 45.9578 15.8919Z"
          fill="black"
        />
        <path
          d="M62.0171 15.8083C62.575 15.8083 62.7696 15.4543 62.8056 15.2482C62.8195 15.1595 62.8236 15.0519 62.8228 14.9542V13.8324H62.0326V12.704H64.7623V14.7784C64.7606 14.923 64.7574 15.0297 64.7345 15.2876C64.606 16.6935 63.3913 17.1953 62.0269 17.1953C60.66 17.1953 59.4469 16.6935 59.3169 15.2876C59.2948 15.0297 59.2915 14.923 59.2891 14.7784L59.2907 11.524C59.2907 11.3868 59.3079 11.1437 59.3226 11.0148C59.4936 9.57028 60.66 9.10547 62.0269 9.10547C63.3921 9.10547 64.5872 9.56781 64.7287 11.0148C64.7541 11.2612 64.7459 11.524 64.7459 11.524V11.7818H62.8048V11.349C62.8065 11.3499 62.8024 11.1651 62.7795 11.055C62.7467 10.8842 62.5987 10.4917 62.0122 10.4917C61.451 10.4917 61.2866 10.8629 61.2457 11.055C61.222 11.1569 61.213 11.2948 61.213 11.4197V14.9542C61.2114 15.0519 61.2171 15.1595 61.2326 15.2482C61.2662 15.4551 61.46 15.8083 62.0171 15.8083Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_807_2635">
          <rect width="74.4587" height="24.8196" fill={hex} transform="translate(0.394531 0.820312)" />
        </clipPath>
      </defs>
    </svg>
  )
}
