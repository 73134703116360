import React, { useCallback, useState } from 'react'
import { Case, Default, Switch, When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { Button, LinkButton } from '@/atoms/Button'
import { PlayIcon } from '@/atoms/Icons/PlayIcon'
import { UpArrowIcon } from '@/atoms/Icons/UpArrowIcon'
import { Image } from '@/atoms/Image'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsH2, CaptionLG, EyebrowMD, HeadingXL, ParagraphSM, TitleLG } from '@/atoms/Text'
import { scaleDimensions } from '@/atoms/utils'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { YoutubeVideoModal } from '@/molecules/YoutubeVideoModal'
import {
  CatalogMovie,
  CatalogSeries,
  formatDimensions,
  formatTitleDuration,
  formatTitleLogo,
  formatTitleRating,
  formatTitleReleaseYear,
} from '@/services/ContentCatalog'
import { DigitalPageData } from '@/services/DigitalService'
import { useViewsGoal } from '@/services/GoalService/GoalService'
import { PlayableLivestream } from '@/services/LivestreamService'
import { WhereToWatch } from '@/services/WhereToWatchService/WhereToWatchService'
import { FilmDetails as FilmDetailsType, FilmDetailsAdditionalVideosCollection } from '@/types/codegen-contentful'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useLocale } from '@/utils/LocaleUtil'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { DigitalFilmDetails } from '@/views/WatchTheatricalView/FilmDetails'
import { DigitalStickyCta } from '@/views/WatchTheatricalView/StickyCta'
import { VideoGallery } from '@/views/WatchTheatricalView/VideoGallery'
import { useWatchTheatricalContext } from '@/views/WatchTheatricalView/WatchTheatricalContext'
import { DigitalWhereToWatchSection } from '@/views/WatchTheatricalView/WhereToWatch'
import { ImageGallery } from './ImageGallery'

interface DigitalStreamingViewProps {
  livestreams: PlayableLivestream[] | null
  catalogTitle: CatalogMovie | CatalogSeries
  whereToWatch: WhereToWatch
  digitalPageData: DigitalPageData
}

export const DigitalStreamingView: React.FC<DigitalStreamingViewProps> = (props) => {
  const [showModal, setShowModal] = useState(false)
  const { digitalPageData, catalogTitle, livestreams, whereToWatch } = props
  const projectMeta = useProjectMeta()
  const { locale } = useLocale()
  const { t } = useTranslate('theatrical-presales')
  const { name, filmDetails, images } = useWatchTheatricalContext()
  const { heroDesktopBackgroundUrl, heroTabletBackgroundUrl, heroMobileBackgroundUrl, logoImageUrl } = digitalPageData
  const { trailerSourceUrl, additionalVideosCollection } = filmDetails || {}
  const { views } = useViewsGoal({ projectSlug: projectMeta.slug })

  const projectName = name as string
  const titleLogo = formatTitleLogo(catalogTitle)
  const rating = formatTitleRating(catalogTitle)
  const releaseYear = formatTitleReleaseYear(catalogTitle)
  const duration = formatTitleDuration(catalogTitle)
  const totalVideos = (livestreams?.length || 0) + (additionalVideosCollection?.items?.length || 0)
  const canShowVideoGallery = trailerSourceUrl || totalVideos > 0
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(90, 83, 24)
  const totalViews = new Intl.NumberFormat(locale).format(Number(views))

  const handleModalClick = useCallback(() => {
    setShowModal(!showModal)
  }, [showModal])

  return (
    <main>
      <section aria-labelledby="cta-status-line" id="hero" className="h-screen w-full">
        <div className="relative left-1/2 top-1/2 h-full min-w-full -translate-x-1/2 -translate-y-1/2 bg-cover object-cover">
          <ResponsiveBackgroundImage
            src={heroDesktopBackgroundUrl}
            srcUrls={{
              xs: heroMobileBackgroundUrl,
              sm: heroMobileBackgroundUrl,
              md: heroTabletBackgroundUrl,
              lg: heroDesktopBackgroundUrl,
              xl: heroDesktopBackgroundUrl,
              '2xl': heroDesktopBackgroundUrl,
              '3xl': heroDesktopBackgroundUrl,
            }}
            preBackgroundStyles="linear-gradient(180deg, rgba(20, 20, 20, 0.00) 58.06%, #141414 86.81%), "
            className="h-full w-full bg-core-gray-950 bg-cover object-cover"
          />
          <div className="absolute inset-0 -bottom-1 bg-gradient-to-t from-core-gray-950 to-transparent" />
        </div>

        <div className="absolute bottom-[32px] left-1/2 flex w-full -translate-x-1/2 flex-col items-center justify-center gap-4">
          <Switch>
            <Case condition={logoImageUrl}>
              {logoImageUrl && (
                <div className="w-full max-w-[500px]">
                  <Image
                    alt={projectName}
                    src={getCloudinaryImageUrl({
                      path: logoImageUrl,
                      transforms: 'e_trim,q_auto:best',
                    })}
                    width={scaledWidth}
                    height={scaledHeight}
                    className="mx-auto"
                  />
                </div>
              )}
            </Case>
            <Case condition={!logoImageUrl}>
              {titleLogo && (
                <div className="max-w-[500px]">
                  <Image
                    alt={projectName}
                    src={getCloudinaryImageUrl({
                      path: titleLogo.cloudinaryPath,
                      transforms: 'e_trim',
                      ...formatDimensions([300, 'auto'], titleLogo),
                    })}
                    {...formatDimensions([300, 'auto'], titleLogo)}
                  />
                </div>
              )}{' '}
            </Case>
          </Switch>
          <div>
            <div className="flex gap-[10px] lg:gap-4">
              {rating && (
                <div className="w-fit whitespace-nowrap rounded-full border-[1px] border-core-gray-800 px-3 py-1">
                  <EyebrowMD color="core-gray-400" weight="bold">
                    {formatTitleRating(props.catalogTitle)}
                  </EyebrowMD>
                </div>
              )}
              {releaseYear && (
                <div className="w-fit whitespace-nowrap rounded-full border-[1px] border-core-gray-800 px-3 py-1">
                  <EyebrowMD color="core-gray-400" weight="bold">
                    {releaseYear}
                  </EyebrowMD>
                </div>
              )}
              {duration && (
                <div className="w-fit whitespace-nowrap rounded-full border-[1px] border-core-gray-800 px-3 py-1">
                  <EyebrowMD color="core-gray-400" weight="bold">
                    {duration}
                  </EyebrowMD>
                </div>
              )}
            </div>
          </div>
          <Button
            variant="white"
            className="flex min-w-[312px] flex-row gap-1.5 text-core-gray-950"
            onClick={handleModalClick}
          >
            <PlayIcon size={24} color="core-gray-950" />
            {t('watchTrailer', 'Watch trailer')}
          </Button>
          <CaptionLG color="core-gray-400">
            <Translate t={t} i18nKey="totalViewsProject" values={{ totalViews }}>
              Total Views: <span className="text-white">{{ totalViews }}</span>
            </Translate>
          </CaptionLG>
        </div>
      </section>
      <nav id="sticky-cta" aria-labelledby="sticky-cta-title">
        <h2 className="sr-only" id="sticky-cta-title">
          <Translate i18nKey="joinTheGuildForTickets" t={t}>
            Join the Guild To Get Two Tickets
          </Translate>
        </h2>
        <DigitalStickyCta
          projectName={projectName}
          titleLogo={titleLogo}
          logoImageUrl={logoImageUrl}
          handleModalClick={handleModalClick}
        />
      </nav>
      <section
        className="flex flex-col items-center gap-2 px-4 pb-14 pt-6 text-center md:px-20 lg:px-[200px]"
        id="synopsis"
      >
        <TitleLG color="white" weight="semibold" className="hidden xl:block">
          {t('synopsis', 'Synopsis')}
        </TitleLG>
        <ParagraphSM color="core-gray-400" className="max-w-[784px] text-pretty md:max-w-[712px] 4xl:max-w-[738px]">
          {catalogTitle.description.long}
        </ParagraphSM>
      </section>
      <When condition={Boolean(whereToWatch)}>
        <div className="xl:mx-20 4xl:hidden">
          <DigitalWhereToWatchSection
            projectName={projectName}
            projectSlug={projectMeta.slug}
            whereToWatch={whereToWatch}
            digitalPageData={digitalPageData}
          />
        </div>
      </When>
      <When condition={canShowVideoGallery}>
        <section
          aria-labelledby="video-section-title"
          key="video-gallery"
          className="w-full bg-core-gray-950 py-5 md:py-8 lg:py-[80px] xl:mx-20 "
        >
          <PaddedContainer className="max-w-[1292px] xl:max-w-[1328px] xl:!px-0 2xl:mx-auto 2xl:max-w-[1695px]">
            <LazyHydrate whenVisible>
              <VideoGallery
                trailerUrl={trailerSourceUrl}
                trailerTitle={t('filmTrailer', '{{projectName}} Trailer', { projectName })}
                additionalVideos={additionalVideosCollection as FilmDetailsAdditionalVideosCollection}
                galleryClassName="bg-core-gray-950"
                livestreams={
                  livestreams && livestreams.slice().sort((a, b) => (a?.url ?? '').localeCompare(b?.url ?? ''))
                }
              />
            </LazyHydrate>
          </PaddedContainer>
        </section>
      </When>

      {catalogTitle && (
        <section aria-labelledby="about-the-show" id="film-details" key="film-details" className="xl:mx-20">
          <h2 id="about-the-show" className="sr-only">
            <Switch>
              <Case condition={projectMeta.projectType === 'movie'}>
                <Translate i18nKey="aboutTheFilm" t={t}>
                  About the Film
                </Translate>
              </Case>
              <Case condition={projectMeta.projectType === 'series'}>
                <Translate i18nKey="aboutTheSeries" t={t}>
                  About the Series
                </Translate>
              </Case>
              <Default>
                <Translate i18nKey="aboutTheShow" t={t}>
                  About the Show
                </Translate>
              </Default>
            </Switch>
          </h2>
          <PaddedContainer className="max-w-[1292px] xl:mx-20 xl:max-w-[1328px] xl:!px-0 2xl:mx-auto 2xl:max-w-[1695px]">
            <LazyHydrate whenVisible>
              <DigitalFilmDetails filmDetails={filmDetails as FilmDetailsType} catalogTitle={catalogTitle} />
            </LazyHydrate>
          </PaddedContainer>
        </section>
      )}
      <When condition={Boolean(whereToWatch)}>
        <div className="hidden pt-20 4xl:block">
          <DigitalWhereToWatchSection
            projectName={projectName}
            projectSlug={projectMeta.slug}
            whereToWatch={whereToWatch}
            digitalPageData={digitalPageData}
          />
        </div>
      </When>
      {Boolean(images?.length) && (
        <section aria-labelledby="image-gallery-title" className="mt-5 w-full bg-core-gray-950 py-5 xl:mx-20 ">
          <PaddedContainer className="max-w-[1292px] bg-core-gray-950 !px-0 xl:mx-20 xl:max-w-[1328px] xl:!px-0 2xl:mx-auto 2xl:max-w-[1695px]">
            <div key="image-gallery overflow-x-hidden">
              <div className="bg-core-gray-950 py-10 lg:pb-20">
                <div className="mb-10 text-center">
                  <HeadingXL id="image-gallery-title" as={AsH2} weight="bold" color="white">
                    {t('imageGallery', 'Image Gallery')}
                  </HeadingXL>
                </div>
                <LazyHydrate whenVisible>
                  <ImageGallery images={images} />
                </LazyHydrate>
              </div>
            </div>
          </PaddedContainer>
        </section>
      )}
      <LinkButton
        variant="gray-700"
        href="#where-to-watch"
        className="mx-auto mb-16 flex w-fit flex-row gap-1 border-0 text-white"
      >
        {t('backToTop', 'Back to top')}
        <UpArrowIcon size={32} color="white" />
      </LinkButton>
      <YoutubeVideoModal youTubeUrl={trailerSourceUrl} handleClose={handleModalClick} showModal={showModal} />
    </main>
  )
}
