import React from 'react'
import { useTranslate } from '@/utils/translate/translate-client'
import { scaleDimensions, getHexColor } from '../utils'
import { OneColorLogoProps } from './LogoTypes'

export const AppleTvLogo: React.FC<OneColorLogoProps> = ({ height = 32, color = 'white', className }) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(height, 47, 18)
  const hex = getHexColor(color)
  const { t } = useTranslate('common')

  return (
    <svg
      className={className}
      fill={hex}
      height={scaledHeight}
      viewBox="0 0 7050.8 3346.4"
      width={scaledWidth}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={t('appleTv', 'AppleTV')}
    >
      <path d="M4079.8 272.2V819h686v196h-686v1614.4c0 165.1 26.6 291.4 80 379.1 53.3 87.7 145.2 131.5 276 131.5 61.9 0 115.2-3.4 159.9-10.3 44.7-6.8 82.5-15.5 113.5-25.8l25.8 185.7c-82.5 37.8-192.6 56.8-330.1 56.8-113.5 0-207.2-19-281.1-56.8-74-37.8-132.4-90.2-175.4-157.3-43-67.1-73.1-147.8-90.3-242.4-17.3-94.5-25.8-196.8-25.8-306.9V1015h-407.5V819h407.5V365l247.5-92.8zM5163 818.9L5730.4 2356c48.1 120.4 91.1 233.9 128.9 340.4 37.8 106.6 73.9 211.5 108.3 314.6h10.3c34.3-99.7 71.3-203.8 110.9-312.1 39.5-108.3 83.4-222.6 131.5-343l572.5-1537.1h257.9l-985.2 2475.8H5854L4905.1 818.9H5163z" />
      <g>
        <path d="M1505.2 291.2C1725.1 1.4 2030.8 0 2030.8 0s45.5 272.5-173 534.9c-233.2 280.3-498.3 234.4-498.3 234.4s-49.8-220.4 145.7-478.1zM1387.4 960.2c113.1 0 323-155.5 596.3-155.5 470.4 0 655.4 334.7 655.4 334.7s-361.9 185-361.9 634c0 506.5 450.8 681 450.8 681s-315.1 887-740.8 887c-195.5 0-347.5-131.8-553.5-131.8-209.9 0-418.3 136.7-553.9 136.7C491.1 3346.4 0 2504.9 0 1828.6 0 1163.1 415.7 814 805.5 814c253.5 0 450.2 146.2 581.9 146.2z" />
      </g>
    </svg>
  )
}
