import React, { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { When } from 'react-if'
import { Image } from '@/atoms/Image'
import { AmazonPrimeVideoLogo } from '@/atoms/Logos/AmazonPrimeVideoLogo'
import { AngelOnlyLogo } from '@/atoms/Logos/AngelOnlyLogo'
import { AppleTvLogo } from '@/atoms/Logos/AppleTvLogo'
import { BluRayDiscLogo } from '@/atoms/Logos/BluRayDiscLogo'
import { DVDVideoLogo } from '@/atoms/Logos/DVDVideoLogo'
import { FandangoAtHomeLogo } from '@/atoms/Logos/FandangoAtHomeLogo'
import { MicrosoftLogo } from '@/atoms/Logos/MicrosoftLogo'
import { PrimeVideoLogo } from '@/atoms/Logos/PrimeVideoLogo'
import { RedboxLogo } from '@/atoms/Logos/RedboxLogo'
import { VuduLogo } from '@/atoms/Logos/VuduLogo'
import { YouTubeLogo } from '@/atoms/Logos/YouTubeLogo'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsH2, AsH3, HeadingMD, TitleSM } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { AppDownloadLinks } from '@/organisms/AppDownloadLinks'
import { DigitalPageData } from '@/services/DigitalService'
import { WhereToWatch } from '@/services/WhereToWatchService'
import { ScreenType, useScreenType } from '@/utils/ScreenUtils'
import { useSafeTrack } from '@/utils/analytics'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface Props {
  projectName: string
  projectSlug: string
  whereToWatch: WhereToWatch
  digitalPageData: DigitalPageData
}

const UTM_PARAMS = '?utm_campaign=dvd-sales&utm_source=hub-where-to-watch&utm_medium=web'
export const DigitalWhereToWatchSection: FC<Props> = ({ projectName, projectSlug, whereToWatch, digitalPageData }) => {
  const { watchDesktopBackgroundUrl, watchTabletBackgroundUrl, watchMobileBackgroundUrl } = digitalPageData
  const { t } = useTranslate('theatrical-presales')
  const router = useRouter()
  const hash = (router.asPath as string).split('#')[1]
  const hashLink = 'where-to-watch'

  const screenType = useScreenType()

  const track = useSafeTrack()

  const trackTVOD = (partner: string) => {
    track('TVOD Add To Cart', {
      eventName: 'TVOD Add To Cart',
      projectSlug,
      partner,
      category: 'where-to-watch',
    })
  }

  const trackDVDBluRay = (category: string) => {
    track('TVOD Add To Cart', {
      eventName: 'TVOD Add To Cart',
      projectSlug,
      partner: 'shop.angel.com',
      category: `where-to-watch-${category}`,
    })
  }

  let streamLogoHeight: number
  let mediaLogoHeight: number

  if (screenType === ScreenType.mobile || screenType === ScreenType.mobileplus) {
    streamLogoHeight = 18
  } else {
    streamLogoHeight = screenType === ScreenType.tablet ? 25 : 34
  }
  if (screenType === ScreenType.mobile || screenType === ScreenType.mobileplus) {
    mediaLogoHeight = 24
  } else {
    mediaLogoHeight = screenType === ScreenType.tablet ? 35 : 50
  }

  useEffect(() => {
    if (hash === hashLink) {
      const destination = document.getElementById(hashLink)
      if (destination) {
        setTimeout(() => destination.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }), 500)
      }
    }
  }, [hash, hashLink])

  return (
    <section
      id={hashLink}
      aria-labelledby="where-to-watch-title"
      className="relative flex w-full max-w-[1292px] scroll-mt-24 flex-col items-center gap-y-16 overflow-x-hidden bg-core-gray-950 px-6 pb-16 pt-40 md:pt-16 lg:items-end xl:max-w-[1328px] xl:rounded-3xl 2xl:mx-auto 2xl:w-auto 2xl:max-w-[1695px]"
    >
      <ResponsiveBackgroundImage
        src={watchDesktopBackgroundUrl}
        srcUrls={{
          xs: watchMobileBackgroundUrl,
          sm: watchMobileBackgroundUrl,
          md: watchTabletBackgroundUrl,
          lg: watchDesktopBackgroundUrl,
          xl: watchDesktopBackgroundUrl,
          '2xl': watchDesktopBackgroundUrl,
        }}
        className="h-full w-full bg-core-gray-950 bg-cover object-cover"
      />
      <div className="relative flex w-full flex-col items-center gap-y-8 lg:w-1/2">
        <div className="flex max-w-[300px] flex-col items-center gap-x-2 md:max-w-[460px] lg:max-w-max lg:flex-row">
          <HeadingMD id="where-to-watch-title" as={AsH2} className="text-center" weight="bold" color="white">
            <Translate t={t} i18nKey="whereToWatchTheShowv3" values={{ projectName }}>
              Where to Watch <span className="block">{{ projectName }}</span>
            </Translate>
          </HeadingMD>
        </div>
      </div>

      <div className="relative flex w-full flex-col items-center gap-y-8 lg:w-1/2">
        <When condition={whereToWatch?.pricingOption}>
          <div className="flex flex-col items-center justify-center gap-2">
            <TitleSM as={AsH3} className="max-w-[262px] text-center md:max-w-max" weight="semibold" color="white">
              {t('forPurchaseOnly', 'For Purchase Only')}
            </TitleSM>
            <TitleSM as={AsH3} className="max-w-[262px] text-center md:max-w-max" weight="semibold" color="white">
              {t('asLowAs', 'As Low As')}
            </TitleSM>
            <HeadingMD weight="bold" color="white">{`$${whereToWatch?.pricingOption}`}</HeadingMD>
          </div>
        </When>
        <When condition={!whereToWatch?.pricingOption}>
          <TitleSM as={AsH3} className="max-w-[262px] text-center md:max-w-max" weight="semibold" color="white">
            {t('watchOnYourFavoriteStreaming', 'Watch on your favorite streaming service')}
          </TitleSM>
        </When>
        <div className="lg:max-w-auto flex max-w-[350px] flex-col items-center justify-center gap-x-4 gap-y-8 md:max-w-[500px] md:gap-x-16 xl:flex-row">
          <div className="flex flex-wrap items-center justify-center gap-x-2 gap-y-8 sm:gap-x-4 md:gap-x-16">
            <a
              href={whereToWatch?.internalLinkOverride ?? `${paths.watch.index}/${projectSlug}`}
              onClick={() => trackTVOD('angel-studios')}
              className="transition-all duration-300 ease-in-out hover:scale-110"
            >
              <AngelOnlyLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
            </a>
            <When condition={whereToWatch?.enableStreamOnAmazonPrime}>
              <a
                target="_blank"
                href={whereToWatch?.streamOnAmazonPrimeLink || ''}
                onClick={() => trackTVOD('prime-video')}
                className="transition-all duration-300 ease-in-out hover:scale-110"
              >
                <PrimeVideoLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableAmazonPrime}>
              <a
                target="_blank"
                href={whereToWatch?.amazonPrimeLink || ''}
                onClick={() => trackTVOD('amazon-prime')}
                className="transition-all duration-300 ease-in-out hover:scale-110"
              >
                <AmazonPrimeVideoLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableAppleTv}>
              <a
                target="_blank"
                href={whereToWatch?.appleTvLink || ''}
                onClick={() => trackTVOD('apple-tv')}
                className="transition-all duration-300 ease-in-out hover:scale-110"
              >
                <AppleTvLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableRedbox}>
              <a
                target="_blank"
                href={whereToWatch?.redboxLink || ''}
                onClick={() => trackTVOD('redbox')}
                className="transition-all duration-300 ease-in-out hover:scale-110"
              >
                <RedboxLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableGooglePlay}>
              <a
                target="_blank"
                href={whereToWatch?.googlePlayLink || ''}
                onClick={() => trackTVOD('google-play')}
                className="transition-all duration-300 ease-in-out hover:scale-110"
              >
                <Image
                  alt="Google Play"
                  src="https://images.angelstudios.com/image/upload/v1707168136/google-play-logo-white_4x.webp"
                  className="min-w-[84px] shrink-0"
                  height={streamLogoHeight}
                  width={streamLogoHeight * 3.5}
                  aria-label={t('googlePlay', 'Google Play')}
                />
              </a>
            </When>
            <When condition={whereToWatch?.enableYoutube}>
              <a
                target="_blank"
                href={whereToWatch?.youtubeLink || ''}
                onClick={() => trackTVOD('youtube')}
                className="transition-all duration-300 ease-in-out hover:scale-110"
              >
                <YouTubeLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableVudu}>
              <a
                target="_blank"
                href={whereToWatch?.vuduLink || ''}
                onClick={() => trackTVOD('vudu')}
                className="transition-all duration-300 ease-in-out hover:scale-110"
              >
                <VuduLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableMicrosoft}>
              <a
                target="_blank"
                href={whereToWatch?.microsoftLink || ''}
                onClick={() => trackTVOD('microsoft')}
                className="transition-all duration-300 ease-in-out hover:scale-110"
              >
                <MicrosoftLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableFandango}>
              <a
                target="_blank"
                href={whereToWatch?.fandangoLink || ''}
                onClick={() => trackTVOD('fandango')}
                className="transition-all duration-300 ease-in-out hover:scale-110"
              >
                <FandangoAtHomeLogo className="min-w-[84px] shrink-0" height={streamLogoHeight} />
              </a>
            </When>
          </div>
        </div>
      </div>
      <div className="relative flex w-full flex-col items-center gap-y-8 lg:w-1/2">
        <TitleSM as={AsH3} className="max-w-[262px] text-center md:max-w-max" weight="semibold" color="white">
          {t('downloadAngelStudiosApps', 'Download Angel Studios on your phone or TV')}
        </TitleSM>
        <AppDownloadLinks darkMode />
      </div>
      <When condition={whereToWatch?.enableBluray || whereToWatch?.enableDvd}>
        <div className="relative flex w-full flex-col items-center gap-y-8 lg:w-1/2">
          <TitleSM as={AsH3} weight="semibold" className="max-w-[262px] text-center" color="white">
            {t('buyNowOnBlurayAndDvd', 'Buy now on Blu-ray and DVD')}
          </TitleSM>
          <div className="flex items-center justify-center gap-x-16">
            <When condition={whereToWatch?.enableDvd}>
              <a
                target="_blank"
                className="flex shrink-0 items-center justify-center transition-all duration-300 ease-in-out hover:scale-110 md:min-w-[138px]"
                onClick={() => trackDVDBluRay('dvd')}
                href={
                  whereToWatch?.dvdLink
                    ? `${whereToWatch?.dvdLink}${UTM_PARAMS}`
                    : `https://shop.angel.com/collections/dvd-blu-ray${UTM_PARAMS}`
                }
              >
                <DVDVideoLogo height={mediaLogoHeight} />
              </a>
            </When>
            <When condition={whereToWatch?.enableBluray}>
              <a
                target="_blank"
                className="flex shrink-0 items-center justify-center transition-all duration-300 ease-in-out hover:scale-110 md:min-w-[138px]"
                onClick={() => trackDVDBluRay('bluray')}
                href={
                  whereToWatch?.blurayLink
                    ? `${whereToWatch?.blurayLink}${UTM_PARAMS}`
                    : `https://shop.angel.com/collections/dvd-blu-ray${UTM_PARAMS}`
                }
              >
                <BluRayDiscLogo height={mediaLogoHeight} />
              </a>
            </When>
          </div>
        </div>
      </When>
    </section>
  )
}
