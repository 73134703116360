import { Button } from '@/atoms/Button'
import { ArrowRightIcon } from '@/atoms/Icons/ArrowRightIcon'
import { CircleCheckIcon } from '@/atoms/Icons/CircleCheckIcon'
import { AsH2, HeadingSM, ParagraphMD } from '@/atoms/Text'
import { useToast } from '@/molecules/Toast'
import { useTranslate } from '@/utils/translate/translate-client'
import { EmailCaptureFormInput } from '../SignUpView/EmailCapture/EmailCaptureFormInput/EmailCaptureFormInput'

// NOTE: there is a single email list id for all projects at this point. This may change in the future.
// see https://angelstudiosteam.slack.com/archives/C04500ZHHN0/p1699039591115819?thread_ts=1699028494.868369&cid=C04500ZHHN0
const EMAIL_LIST_ID = 'b9fc9a1a-62da-4537-9693-c0f7e9690128'

interface GetUpdatesCtaProps {
  slug: string
}

export function GetUpdatesCta(props: GetUpdatesCtaProps) {
  const { t } = useTranslate('theatrical-presales')

  const { showToast } = useToast()

  // TODO: update EmailCaptureFormInput to be more transparent about error possibilities
  function handleSubmit() {
    showToast(t('thankYouForSigningup', "Thank you for signing up. We'll be in touch as there's news to share"), {
      shouldHideClose: true,
      icon: <CircleCheckIcon />,
    })
  }

  return <GetUpdatesForm onSubmit={handleSubmit} {...props} />
}

interface GetUpdatesFormProps extends GetUpdatesCtaProps {
  onSubmit: () => void
}
function GetUpdatesForm(props: GetUpdatesFormProps) {
  const { t } = useTranslate('theatrical-presales')
  return (
    <div id="signup-cta" className="px-4 py-20 text-center text-white md:py-28">
      <div className="mb-8">
        <HeadingSM id="get-updates-title" as={AsH2} weight="bold" className="mb-2">
          {t('getUpdates', 'Get Updates')}
        </HeadingSM>
        <ParagraphMD>{t('getUpdatesDescription', 'Sign up to get updates on this film.')}</ParagraphMD>
      </div>
      <EmailCaptureFormInput
        onSubmit={props.onSubmit}
        position="hub-mid-page"
        subscriptionGroupId={EMAIL_LIST_ID}
        projectSlug={props.slug}
        placeholder={t('enterEmail', 'Enter Email')}
        formClassName="relative max-w-lg mx-auto"
        inputClassName="rounded-lg w-full p-4 text-gray-950"
        formAriaLabel={t('getUpdates', 'Get Updates')}
      >
        <Button
          aria-label={t('submitEmail', 'Submit Email')}
          variant="black"
          className="absolute right-2 top-1/2 -translate-y-1/2 rounded-lg bg-black px-2"
        >
          <ArrowRightIcon color="white" />
        </Button>
      </EmailCaptureFormInput>
    </div>
  )
}
